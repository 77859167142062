const gallery = {
    "global": {
        "title": "Ma galerie",
        "empty": "Il n'y a aucune photo",
        "search": {
            "placeholder": "Rechercher une photo spécifique"
        },
        "notification": {
            "add": {
                "success": "La photo a été téléchargée avec succès !",
                "error": "La photo n'a pas été téléchargée, veuillez réessayer !"
            },
            "update": {
                "success": "La photo a été mise à jour avec succès !",
                "error": "La photo n'a pas été mise à jour, veuillez réessayer !"
            },
            "delete": {
                "success": "La photo a été supprimée avec succès !",
                "error": "La photo n'a pas été supprimée, veuillez réessayer !"
            }
        },
        "modal": {
            "confirmDelete": {
                "title": "Êtes-vous sûr de vouloir supprimer ?",
                "confirm": "Supprimer",
                "cancel": "Annuler"
            }
        }
    },
    "user": {
        "photos": "photos",
        "buttons": {
            "add": "Ajouter une nouvelle photo"
        },
        "buttonCollapse": {
            "more": "+ voir plus",
            "less": "- voir moins"
        }
    },
    "creator": {},
    "guest": {},
    "form": {
        "list": {
            "searchByDate": "Rechercher par date",
            "searchByUser": "Rechercher par utilisateur"
        },
        "add": {
            "title": "Télécharger une nouvelle photo",
            "fields": {
                "title": "Titre",
                "date": "Date",
                "shareCreator": "Partager avec le créateur",
                "bodyParts": "Parties du corps",
                "photo": "Ajouter une photo",
                "photoRequired": "La photo est obligatoire"
            },
            "buttons": {
                "submit": "Télécharger ma photo",
                "edit": "Modifier ma photo"
            },
            "error": "Une erreur s'est produite, veuillez réessayer !"
        },
        "edit": {}
    }
}


export default gallery