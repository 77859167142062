const recipe = {
    global: {
        likes: 'me gusta',
        filter: {
            category: 'Filtrar por categoría',
            status: 'Filtrar por estado',
        },
        search: {
            placeholder: 'Buscar...',
        },
        notification: {
            add: {
                success: '¡La receta se agregó correctamente!',
                error: 'La receta no se agregó, ¡inténtalo de nuevo!',
            },
            update: {
                success: '¡La receta se actualizó correctamente!',
                error: 'La receta no se actualizó, ¡inténtalo de nuevo!',
            },
            delete: {
                success: '¡La receta se eliminó correctamente!',
                error: 'La receta no se eliminó, ¡inténtalo de nuevo!',
            },
            disable: {
                success: '¡La receta se desactivó correctamente!',
                error: 'La receta no se desactivó, ¡inténtalo de nuevo!',
            },
        },
        modal: {
            confirmDelete: {
                title: '¿Estás seguro?<br>No podrás revertir esto',
                confirm: 'Borrar',
                cancel: 'Cancelar',
            },
            confirmDisable: {
                title: '¿Estás seguro de que deseas desactivar la receta?',
                confirm: 'Desactivar',
                cancel: 'Cancelar',
            },
        },
    },
    user: {
        recipe: 'Receta',
        buttons: {
            addToPlanning: 'Agregar al plan',
            viewVideo: 'Ver video',
        },
    },
    superAdmin: {
        buttons: {
            add: 'Agregar receta',
            resetFilter: 'Restablecer filtros',
            back: 'Regresar',
        },
    },
    creator: {
        title: 'Mis recetas',
        subTitle: 'Nuestras recetas',
        globalTitle: 'Recetas Ikonstar',
        globalSubtitle: 'Nuestras recetas',
        privateTitle: 'Tus recetas',
        privateSubtitle: 'Tus recetas privadas',
        empty: 'Parece que no tienes ninguna receta activada',
        emptySearch: 'No hay recetas con estos filtros',
        setRecipe: 'Configura tus recetas ahora',
        search: {
            placeholder: 'Buscar una receta específica',
        },
        buttons: {
            add: 'Agregar una receta',
            activateAll: 'Activar todas las recetas',
            setConfig: 'Configurar mis recetas',
            selectRecipe: 'Seleccionar receta',
            removeSelectedRecipe: 'Eliminar seleccionadas',
            activateRecipe: 'Activar receta',
            addToPlanning: 'Agregar al plan',
            disableRecipe: 'Desactivar receta',
        },
        filter: {
            filter: 'Filtros',
            category: 'Categoría',
            physicalGoal: 'Objetivo físico',
        },
        selectRecipe: {
            title: 'Nuestras recetas',
            subtitle: 'Selecciona las recetas que deseas agregar',
            empty: 'No hay recetas',
            youSelected: 'Has seleccionado',
            recipe: 'receta',
            recipes: 'recetas',
        },
        viewRecipe: {
            title: 'Receta',
            label: 'Ver receta',
            minutes: 'minuto | minutos',
            person: 'persona | personas',
            ingredients: 'Ingredientes',
            nutrition: 'Nutrición',
            allergens: 'Alergenos',
            stepTitle: 'Sigue las instrucciones de la receta paso a paso',
        },
        private: {
            title: 'Recetas privadas',
            globalSubtitle: 'Tus recetas',
            addRecipe: 'Agregar una receta',
            edit: 'Editar',
            currentFiles: 'Tus archivos actuales',

            empty: 'Parece que aún no tienes ninguna receta',
            createFirstButton: 'Crear mi primera receta',
            createButton: 'Crear una receta',
            foundStarted: {
                title: 'Encontramos una receta empezada, ¿te gustaría continuar creándola?',
                yes: 'Sí, continuar',
                no: 'No, empezar una nueva',
            },
            delete: {
                title: '¿Estás seguro de que quieres eliminar esta receta? No podrás recuperarla después.',
                yes: 'Sí, eliminar',
                no: 'No, conservarla',
                success: '¡Tu receta se eliminó correctamente!',
                error: '¡Tu receta no se eliminó! Inténtalo de nuevo.',
            },
            'delete-video': {
                title: '¿Estás seguro de que quieres eliminar este video? No podrás recuperarlo después.',
                yes: 'Sí, eliminar',
                no: 'No, conservarlo',
            },
            'delete-photo': {
                title: '¿Estás seguro de que quieres eliminar esta imagen? No podrás recuperarla después.',
                yes: 'Sí, eliminar',
                no: 'No, conservarla',
            },
            form: {
                chooseOne: 'Debes elegir uno',
                addTitle: 'Crear una receta',
                editTitle: 'Editar una receta',
                addSucces: '¡Tu receta se creó correctamente!',
                updateSucces: '¡Tu receta se actualizó correctamente!',
                steps: {
                    1: {
                        title: 'Bienvenido al formulario de adición de recetas. Sigue los diferentes pasos, introduce la información necesaria para tu receta y prévisualízala en línea.',
                        subtitle: '',
                    },
                    2: {
                        title: 'Información de la receta',
                    },
                    3: {
                        title: 'Contenido de la receta',
                    },
                    4: {
                        title: 'Previsualizar la receta y enviar',
                    },
                },
                information: 'Información',
                title: 'Título de la receta',
                kcal: 'kCal (por porción)',
                description: 'Breve descripción de la receta',
                category: 'Elegir una categoría',
                portions: 'Porciones',
                portionsUnit: 'Personas, piezas, litros...',
                portionsHelper: 'Ejemplo: para 4 personas, para 10 piezas, para 1 litro...',
                prepTime: 'Tiempo de preparación',
                cookingTime: 'Tiempo de cocción',
                restingTime: 'Tiempo de reposo',
                cookingType: {
                    title: 'Elegir tipo de cocción',
                    oven: 'Horno',
                    hob: 'Placa de cocina',
                    'no-cooking': 'Sin cocción',
                    other: 'Otro',
                },
                difficulty: 'Elegir dificultad',
                cost: {
                    title: 'Elegir coste de la receta',
                    cheap: 'Barato',
                    average: 'Promedio',
                    expensive: 'Caro',
                },
                files: 'Agregar fotos o videos. Máximo {nbFile} archivos',
                order: 'Puedes cambiar el orden arrastrando las casillas hacia arriba y hacia abajo',
                buttons: {
                    add: 'Agregar',
                    edit: 'Guardar',
                    cancel: 'Cancelar',
                },
                ingredients: {
                    title: 'Ingredientes',
                    add: 'Agregar un ingrediente',
                    order: 'Puedes cambiar el orden arrastrando las casillas hacia arriba y hacia abajo',
                    quantity: 'Cantidad',
                    unit: 'Unidad',
                    unitHelper: "Ejemplo: 'g', 'cucharada', 'pizca'",
                    name: 'Nombre',
                    complement: 'Información adicional',
                    complementHelper: "Ejemplo: 'a temperatura ambiente', 'sin hueso', 'al gusto'",
                },
                recipeSteps: {
                    title: 'Pasos de la receta',
                    add: 'Agregar un paso',
                    step: 'Paso',
                    order: 'Puedes cambiar el orden arrastrando las casillas',
                    stepTitle: 'Título',
                    content: 'Contenido',
                },
                allergens: {
                    title: 'Alergenos conocidos',
                    add: 'Agregar un alérgeno',
                    name: 'Nombre',
                },
                nutritions: {
                    title: 'Información nutricional (por porción)',
                    add: 'Agregar una información nutricionalt',
                    quantity: 'Valor',
                    unit: 'Unidad',
                    unitHelper: "Típicamente 'g'",
                    name: 'Nombre',
                },
            },
        },
    },
    guest: {},
    form: {
        list: {
            name: 'Nombre de la receta',
            description: 'Descripción',
            status: 'Estado',
            createdAt: 'Creado en',
            edit: 'Editar',
            delete: 'Eliminar',
        },
        add: {
            title: 'Crear nueva receta',
            fields: {
                title: {
                    label: 'Título de la receta',
                    placeholder: 'Ingresa el título de la receta',
                },
                description: {
                    label: 'Descripción de la receta',
                    placeholder: 'Ingresa la descripción de la receta',
                },
                category: {
                    label: 'Categoría',
                    placeholder: 'Selecciona una categoría',
                },
                type: {
                    label: 'Tipo',
                    placeholder: 'Selecciona un tipo',
                },
                kcal: {
                    label: 'Kcal',
                    placeholder: 'Ingresa un número',
                },
                preparationTimeMinutes: {
                    label: 'Tiempo de preparación en minutos',
                    placeholder: 'Ingresa el tiempo en minutos',
                },
                totalPerson: {
                    label: 'Total de personas',
                    placeholder: 'Ingresa el total de personas',
                },
                level: {
                    label: 'Nivel',
                    placeholder: 'Selecciona un nivel',
                },
                physicalGoal: {
                    label: 'Objetivo físico',
                    placeholder: 'Selecciona un objetivo físico',
                },
                photos: {
                    label: 'Fotos',
                    cover: 'Imagen de portada',
                    placeholder: 'Haz clic para seleccionar o arrastra un archivo aquí...',
                },
                video: {
                    label: 'Video',
                    current: 'Video actual',
                },
                status: {
                    label: 'Estado',
                    placeholder: 'Selecciona un estado',
                    draft: 'Borrador',
                    active: 'Activo',
                },
                allergens: {
                    label: 'Alergenos',
                    placeholder: 'Ingresa alergenos',
                },
                ingredients: {
                    label: 'Ingredientes',
                    name: 'Nombre del ingrediente',
                    amount: 'Cantidad',
                    unit: 'Unidad',
                },
                nutrition: {
                    label: 'Nutrición',
                    name: 'Nombre de la nutrición',
                    amount: 'Cantidad',
                    unit: 'Unidad',
                },
                steps: {
                    label: 'Pasos',
                    title: 'Título del paso',
                    content: 'Contenido del paso',
                },
                videoStatus: {
                    processing: 'El video se está procesando',
                    pending: 'El video está pendiente',
                    failed: 'El video ha fallado',
                    none: 'Ningún video cargado',
                },
            },
            buttons: {
                addAllergen: 'Agregar alergeno',
                addIngredient: 'Agregar ingrediente',
                addNutrition: 'Agregar nutrición',
                loading: 'Cargando...',
                next: 'Siguiente',
                save: 'Guardar',
            },
        },
        edit: {
            title: 'Editar una receta',
            buttons: {
                saveAllergens: 'Guardar alergenos',
                saveIngredients: 'Guardar ingredientes',
                saveNutrition: 'Guardar nutrición',
                saveSteps: 'Guardar pasos',
            },
        },
    },
}

export default recipe