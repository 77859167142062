const messenger = {
    "global": {
        "title": "Mes messages",
        "plan": "Plan",
        another: 'Choisir une autre conversation',
        "sendSomething": "Envoyer quelque chose à {name} !",
        "typeSomething": "Écrire à {name} !",
        "maxCharacterCount": "Vous avez atteint le maximum de {maxCharacterCount} caractères par message.",
        "notification": {
            "add": {
                "success": "Votre message a été envoyé avec succès !",
                "error": "Votre message n'a pas été envoyé, veuillez réessayer !"
            },
            "confirm": {
                "success": "L'appel vidéo privé a été confirmé avec succès !",
                "error": "L'appel vidéo privé ne peut pas être confirmé, veuillez réessayer !"
            }
        },
        "modal": {
            "confirmSendToAll": {
                "title": "Êtes-vous sûr de vouloir envoyer un message à tous les abonnés ?",
                "confirm": "Oui, envoyez-le",
                "cancel": "Annuler"
            },
            "confirmCall": {
                "title": "Êtes-vous sûr de vouloir confirmer ?",
                "confirm": "Oui, confirmer",
                "cancel": "Annuler"
            }
        }
    },
    "user": {
        "title": "Mes messages avec {name}",
        "privateCall": {
            "title": "Relation privée",
            "bookTitle": "Réserver un appel vidéo",
            "directCall": "Appel direct",
            "yourChoice": "Sélectionnez une plage horaire et une date",
            "selectTimeSlot": "Sélectionnez une plage horaire",
            "selectDay": "Sélectionnez une journée",
            "selectHour": "Sélectionnez une plage horaire",
            "pickRange": "Choisissez une plage",
            "selected": {
                "title": "Votre plage horaire sélectionnée",
                "callWith": "Appel vidéo privé avec",
                "slot": "Durée de l'appel {number} minutes",
                "reminder": "Rappel {number} minutes avant",
                "minutes": "minutes"
            },
            "form": {
                "timeSlot": "Plage horaire",
                "day": "Jour",
                "hour": "Plage horaire",
                "from": "De",
                "to": "à",
                "editModal": {
                    "title": "Modifier l'appel vidéo privé",
                    "submit": "Enregistrer",
                    "success": "L'appel vidéo privé a été mis à jour avec succès !",
                    "error": "L'appel vidéo privé ne peut pas être mis à jour, veuillez réessayer !"
                }
            },
            "payment": {
                "breadcrumbs": "Paiement de l'appel",
                "title": "Vos informations de paiement",
                "success": "Le paiement a été accepté !",
                "error": "L'appel vidéo privé ne peut pas être acheté pour le moment !",
                "buttonBackSlot": "Retour à la sélection de plage",
                "validate": "Valider mon paiement",
                "secure": "Paiement 100 % sécurisé",
                "endingWith": "se terminant par"
            },
            "message": {
                "description": "J'ai réservé une session de {time_slot} min. avec vous aux horaires suivants, cela vous convient-il ?",
                "descriptionChanged": "Salut, j'ai mis à jour la date de l'appel vidéo privé de {time_slot} min.",
                "descriptionConfirmed": "Oui, je suis disponible. À bientôt.",
                "modify": "Modifier",
                "available": "Je suis disponible",
                "yourAppointment": "VOTRE ÉVÉNEMENT",
                "confirmed": "L'appel vidéo privé a été confirmé avec succès !",
                "errorConfirmed": "L'appel vidéo privé ne peut pas être confirmé, veuillez réessayer !",
                "confirmTitle": "Êtes-vous sûr de vouloir confirmer ?",
                "confirmButton": "Oui, confirmer",
                "cancelButton": "Annuler"
            }
        },
        "buttons": {
            "bookCall": "Réserver un appel, à partir de",
            "back": "Retour à la messagerie",
            "payment": "Cartes de crédit et de débit"
        }
    },
    "creator": {
        "fileAdd": "Ajouter une image",
        "buttons": {
            "sendToAll": "Message à tous"
        },
        "search": {
            "placeholder": "Rechercher par nom d'abonné"
        },
        "form": {
            "title": "Envoyer un message à vos abonnés"
        },
        "video": {
            "title": "Appel vidéo",
            "selectSource": "Sélectionnez une source",
            "audioSource": "Source audio",
            "videoSource": "Source vidéo",
            "waiting": "Veuillez patienter, l'hôte de la réunion vous laissera bientôt entrer",
            "errors": {
                "camera": "Vous avez besoin d'une caméra pour rejoindre un appel",
                "microphone": "Vous avez besoin d'un microphone pour rejoindre un appel",
                "speaker": "Vous avez besoin d'un haut-parleur pour rejoindre un appel"
            }
        }
    },
    "guest": {
        unlockChat: 'Débloquer le chat',
    },
    "form": {
        "list": {},
        "add": {
            "fields": {
                "message": "Message"
            },
            "buttons": {
                "submit": "Envoyer"
            }
        },
        "edit": {}
    }
}


export default messenger