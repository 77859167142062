const earlyAccess = {
    "title": "CONVIÉRTETE EN UN {lb} SOCIO DE IKONSTAR",
    "subtitle": "Crea una relación única con tu comunidad",

    "superAdmin": {
        "title": {
            "list": "Accesos Tempranos"
        },
        "form": {
            "list": {
                "searchUsername": "Buscar por nombre de usuario",
                "searchEmail": "Buscar por correo electrónico",
                "searchVipCode": "Buscar por código VIP",
                "columnUsername": "Nombre de Usuario",
                "columnEmail": "Correo Electrónico",
                "columnPhone": "Teléfono",
                "columnVipCode": "Código VIP",
                "columnRole": "Rol solicitado",
                "columnCreationDate": "Creado",
                "createUser": "Crear usuario",
                "createCreator": "Crear creador",
                "confirmCreateUserText": "¿Estás seguro de que deseas crear un usuario a partir de este acceso temprano?",
                "confirmCreateUserYesBtn": "Sí, crear usuario",
                "confirmCreateUserNoBtn": "No, cancelar",
                "createUserError": "Error al crear el usuario",
                "createUserSuccess": "Usuario creado con éxito",
                "createCreatorSuccess": "Creador creado con éxito",
                "createCreatorError": "Error al crear el creador",
                "confirmCreateCreatorText": "¿Estás seguro de que deseas crear un creador a partir de este acceso temprano?",
                "confirmCreateCreatorYesBtn": "Sí, crear creador",
                "confirmCreateCreatorNoBtn": "No, cancelar"
            }
        }
    },

    "form": {
        "types": {
            "manager": "Soy un Gerente",
            "agency": "Soy una Agencia",
            "creator": "Soy un Creador"
        },
        "username": "Nombre de Usuario",
        "link": "Enlace",
        "usernameHelper": "Tu nombre de usuario principal en las redes sociales",
        "social": "Red social",
        "addSocial": "Agregar una red social",
        "fullname": "Tu Nombre Completo",
        "email": "Tu Correo Electrónico",
        "phone": "Tu Número de Teléfono",
        "vipCode": "Código VIP",
        "vipCodeHelper": "¿Te dieron un código VIP especial?"
    }
}


export default earlyAccess