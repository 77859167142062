const calendar = {
    global: {
        title: 'My personal planning',
        calendar: 'Calendar',
        today: 'Today',
        at: 'at',
        buttons: {
            schedule: {
                privateVideoCall: 'Schedule a call',
                workout: 'Schedule a workout',
                recipe: 'Add a recipe',
            },
            event: {
                goTo: 'Go to the',
                close: 'Close',
                delete: 'Delete',
                newWorkout: 'Add a Workout',
                newRecipe: 'Add a Recipe',
                newCustom: 'Add a Custom Event',
                newUser: 'Add a User Event',
                "live-streaming": 'Add a live',
            },
            back: 'Back to the calendar',
            add: 'Add to the calendar',
            backType: {
                calendar: 'Back to the calendar',
                custom: 'Back to the calendar',
                messenger: 'Back to the messenger',
                workout: 'Back to the calendar',
                'private-call': 'Back to the messenger',
                recipe: 'Back to the calendar',
                'creator-recipe': 'Back to the calendar',
                "live-streaming": 'Back to the calendar',
            },
        },
        modal: {
            confirmDelete: {
                title: 'Are you sure you want to delete?',
                confirm: 'Delete It',
                cancel: 'Cancel',
            },
        },
        notification: {
            add: {
                success: 'Your event was added successfully!',
                error: 'Your event was not added, please try again!',
            },
            update: {
                success: 'Your event was updated successfully!',
                error: 'Your event was not updated, please try again!',
            },
            delete: {
                success: 'Your event was deleted successfully!',
                error: 'Your event was not deleted, please try again!',
            },
        },
        filter: {
            selectedDate: 'Selected date',
            selectedTime: 'Selected Time',
            search: {
                placeholder: {
                    recipe: 'Search a specific recipe',
                },
            },
        },
        event: {
            add: 'Add an event',
            from: 'From',
            to: 'to',
            great: {
                title: 'Great',
                content: 'The event has been added',
                appointment: 'Your event',
            },
            "label": {
                "creator-live-event": "Live event",
            },
            type: {
                episode: 'episode',
                workout: 'workout',
                'private-call': 'private video call',
                recipe: 'recipe',
                'creator-recipe': 'recipe',
                custom: 'custom',
                user: 'user',
                "live-streaming": "Live",
                "creator-live-event": "event",
            },
        },
    },
    user: {
        recipe: {
            add: 'Add recipe',
        },
        workout: {
            add: 'Add exercice',
            question: 'Questions?',
        },
        custom: {
            add: 'Add custom event',
        },
        user: {
            add: 'Add user event',
        },
        "live-streaming": {
            add: 'Add live event',
        },
        payment: {
            success: 'The payment was accepted!',
        },
        privateVideoCall: {
            description:
                'The creator must validate the slot or discuss with you to agree on another date. At the moment, the private call is awaiting confirmation, you will find the information in the chat',
        },
    },
    superAdmin: {},
    creator: {},
    guest: {},
    form: {
        list: {},
        add: {
            title: 'Title',
            content: 'Content',
            selectUser: 'Select user',
            button: {
                save: 'Save the event',
            },
        },
        edit: {},
    },
}

export default calendar