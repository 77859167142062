const liveEvents = {
    title: "Live Events",
    empty: "Looks like you don't have any events planned yet",
    emptyUser: "Looks like there are no events yet.",
    sold: 'Ticket sold | Tickets sold',
    status: {
        draft: 'Draft',
        active: 'Active',
        "in-progress": 'In progress',
        finished: 'Finished',
    },
    startsIn: "Event Starts in",
    countdown: "{days} @:globals.countdown.days , {hours} @:globals.countdown.hours , {minutes} @:globals.countdown.minutes , {seconds} @:globals.countdown.seconds",
    active: "The event has started",
    soon: "in a moment",
    finished: "Event is finished!",
    thanks: "Thank you for purchasing a ticket to this event!",
    backToLive: {
        title: 'Please verify that you are not streaming from another tab!',
        confirm: 'I am not, start streaming',
        cancel: 'Never mind I found it',
    },
    cantStart: "Looks like we can't start a stream right now. If the error persists, please contact us.",

    viewLive: "GO TO LIVE STREAMING",
    nextEvent: {
        helper: 'NEXT EVENT',
        start: 'START',
        inProgress: 'IN PROGRESS',
        goBackLive: 'Go bak to the live',
    },
    payment: {
        title: 'Your payment information',
        success: 'The payment was accepted!',
        error: "The event ticket can't be purchased for the moment",
        validate: 'Purchase my ticket for {price}',
        secure: 'Payment 100% secure',
        endingWith: 'ending with',
        convertedCurrency: 'Prices shown in {currencySelected} as a reference only. You will be charged in {currencyEvent} for {amount}',
    },
    actions: {
        create: "Create Live Event",
        edit: "Edit Live Event",
        delete: 'Are you sure you want to delete this event ?'
    },
    buttons: {
        back: 'Live events',
        join: 'Join event',
    },
    form: {
        cover: {
            label: 'Live Event cover image',
            error: "Please upload a cover image"
        },
        video: {
            label: 'Live Event teaser video'
        },
        title: {
            label: 'Title'
        },
        content: {
            label: 'Content'
        },
        date: {
            label: 'Event start date',
            short: 'Date',
        },
        price: {
            label: 'Event entry price',
            short: 'Entry price'
        },
        duration: {
            label: 'Event expected duration (in minutes)',
            short: 'Expected duration',
        },
    },
    user: {
        empty: "Looks like {creator} does not have any events planned yet",
        actions: {
            view: "View Event",
            buy: "Buy a ticket"
        },
    }
}

export default liveEvents