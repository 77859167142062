const media = {
    global: {
        title: 'Medias',
    },
    user: {},
    superAdmin: {},
    creator: {
        myMedias: 'My medias',
        type: {
            image: 'Image',
            video: 'Video',
            unknown: 'Unknown',
        },
        searchPlaceholder: 'Search medias',
        noMediaFound: 'It looks like you do not have any Media yet.',
    },
    guest: {},
    form: {
        add: {
            uploadNewMedia: 'Upload new media',
            upload: 'Upload medias',
            backToMedias: 'Back to medias',
            uploading: 'Uploading medias...',
            noFileUploaded: 'Please upload at least one media',
            uploadSuccess: 'Your medias have been uploaded successfully',
            uploadFailed: 'There was an error while uploading your medias',
        },
        edit: {
            editMedia: 'Edit media',
            title: 'Title',
            description: 'Description',
            save: 'Save',
            delete: 'Delete',
            editMediaSuccess: 'Your media has been updated successfully',
            editMediaError: 'There was an error while updating your media',
            deleteMediaConfirmation: 'Are you sure you want to delete this media?',
            deleteMediaCancelText: 'Cancel',
            deleteMediaConfirmText: 'Yes, delete',
            deleteMediaSuccess: 'Your media has been deleted successfully',
            deleteMediaError: 'There was an error while deleting your media',
            videoStatus: {
                processing: 'Video is processing',
                pending: 'Video is pending',
                failed: 'Video is failed',
            },
        }
    },
}

export default media