const admin = {
    "menu": {
        "dashboard": "Tableau de bord",
        "kols": "Créateurs",
        "recipes": "Recettes",
        "members": "Membres",
        "settings": "Paramètres",
        "earlyAccesses": "Accès anticipés"
    },
    creators: {
        inviteLink: "Lien d'invitation Créateur"
    }
}


export default admin