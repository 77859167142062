const _dashboard = {
    "global": {
        "title": "Tableau de bord"
    },
    "user": {
        "helperCalendar": "Organisez votre calendrier",
        "latestPostsTitle": "Derniers articles",
        "followMe": "Suivez-moi",
        latestLiveEventTitle: 'Prochain Évènement',
        "latestWorkoutTitle": "Dernier entraînement",
        "latestMasterclassTitle": "Dernière Masterclass",
        "nothingPosted": "Rien n'a été publié pour le moment",
        "episodes": "Épisode | Épisodes",
        "yourGoal": "Votre objectif",
        "ofTheDay": "du jour",
        unfollow: {
            title: 'Etes-vous sur de ne plus vouloir suivre {creator} ?',
            submit: 'Ne plus suivre',
            cancel: 'Annuler',
        },
        "shortcuts": {
            "buttons": {
                "call": 'Réserver un appel',
                "workout": 'Entraînements',
                "masterclass": 'Masterclass',
                "recipe": 'Recettes',
            },
        },
        "calendar": {
            "buttons": {
                "workout": "Planifier un entraînement",
                "recipe": "Ajouter une recette",
                "privateVideoCall": "Réserver un appel",
                "privateVideoCallSecond": "Planifier un appel"
            },
            "events": {
                "see": "Voir",
                "nothing": "Aucun événement trouvé",
                "privateVideoCall": "Appel vidéo privé avec"
            },
            "day": {
                "short": {
                    "monday": "Lun.",
                    "tuesday": "Mar.",
                    "wednesday": "Mer.",
                    "thursday": "Jeu.",
                    "friday": "Ven.",
                    "saturday": "Sam.",
                    "sunday": "Dim."
                },
                "long": {
                    "monday": "Lundi",
                    "tuesday": "Mardi",
                    "wednesday": "Mercredi",
                    "thursday": "Jeudi",
                    "friday": "Vendredi",
                    "saturday": "Samedi",
                    "sunday": "Dimanche"
                }
            }
        }
    },
    "superAdmin": {
        "statistic": {
            "creators": "Créateurs totaux",
            "recipes": "Recettes totales",
            "users": "Utilisateurs totaux",
            "lastAdded": "Dernier ajout :"
        },
        "creator": {
            "title": "Création en cours",
            "lastUpdate": "Dernière modification :",
            "continueEditing": "Cliquez pour continuer la modification"
        },
        "button": {
            "new": "+ Créer nouveau",
            "addKol": "Ajouter un créateur",
            "addMember": "Ajouter un membre"
        },
        "topFiveKols": "Top 5 des applications Kol",
        "totalDownloaded": "Total téléchargé",
        "topFiveRecipes": "Top 5 des recettes populaires",
        "thisMonth": "Ce mois-ci"
    },
    "creator": {
        "mandatory": "OBLIGATOIRE",
        "lastTitle": "Vos dernières données",
        "detailReportButton": "Voir le rapport détaillé",
        "totalWorkouts": "Total des entraînements",
        "addWorkout": "Créer un entraînement",
        "topFiveWorkouts": "Top 5 des entraînements populaires",
        "latestFiveWorkouts": "5 derniers entraînements",
        "totalMasterclass": "Total des masterclass",
        "addMasterclass": "Créer une masterclass",
        "topFiveMasterclass": "Top 5 des masterclass populaires",
        "latestFiveMasterclass": "5 dernières masterclass",
        "totalPrograms": "Total des programmes",
        "addProgram": "Ajouter un programme",
        "totalTrained": "Total formés",
        "topFivePrograms": "Top 5 des programmes populaires",
        "totalJoined": "Total d'adhésions",
        "totalPosts": "Total des articles",
        "addPost": "Créer un article",
        "viewPost": "Voir l'article",
        "latestFivePosts": "5 derniers articles",
        "totalDownloads": "Total des téléchargements",
        "markedRecipes": "Recettes marquées",
        "addRecipe": "Ajouter une collection de recettes",
        "thisMonth": "Ce mois-ci",
        "stripe": {
            "create": {
                "button": "Stripe demande de nouvelles informations"
            },
            "login": {
                "title": "Mes gains",
                "button": "Connexion à votre compte Stripe",
                "error": "La connexion à Stripe a échoué, veuillez réessayer !"
            }
        }
    },
    "guest": {},
    "form": {}
}


export default _dashboard