const media = {
    "global": {
        "title": 'Medios',
    },
    "user": {},
    "superAdmin": {},
    "creator": {
        "myMedias": 'Mis medios',
        "type": {
            "image": 'Imagen',
            "video": 'Video',
            "unknown": 'Desconocido',
        },
        "searchPlaceholder": 'Buscar medios',
        "noMediaFound": 'Parece que aún no tienes ningún medio.',
    },
    "guest": {},
    "form": {
        "add": {
            "uploadNewMedia": 'Subir nuevo medio',
            "upload": 'Subir medios',
            "backToMedias": 'Volver a los medios',
            "uploading": 'Subiendo medios...',
            "noFileUploaded": 'Por favor, sube al menos un medio',
            "uploadSuccess": 'Tus medios se han subido con éxito',
            "uploadFailed": 'Hubo un error al subir tus medios',
        },
        "edit": {
            "editMedia": 'Editar medio',
            "title": 'Título',
            "description": 'Descripción',
            "save": 'Guardar',
            "delete": 'Eliminar',
            "editMediaSuccess": 'Tu medio se ha actualizado con éxito',
            "editMediaError": 'Hubo un error al actualizar tu medio',
            "deleteMediaConfirmation": '¿Estás seguro de que quieres eliminar este medio?',
            "deleteMediaCancelText": 'Cancelar',
            "deleteMediaConfirmText": 'Sí, eliminar',
            "deleteMediaSuccess": 'Tu medio se ha eliminado con éxito',
            "deleteMediaError": 'Hubo un error al eliminar tu medio',
            "videoStatus": {
                "processing": 'El video se está procesando',
                "pending": 'El video está pendiente',
                "failed": 'El video ha fallado',
            },
        }
    }
}


export default media