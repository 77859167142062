export default {
    v2: {
        "title": "Connectar a Ikonstar",
        "navbar": {
            "creator": "Soy un Creador",
            "user": "Soy un Fan",
            "creators": "Nuestros Creadores",
            "podcasters": "Podcasters",
            "video_creators": "Creadores de Videos",
            "musicians": "Músicos",
            "artists": "Artistas",
            "game_developers": "Desarrolladores de Juegos",
            "features": "Características",
            "academy": "Academia Ikonstar",
            "login": "Iniciar sesión",
            "start": "Comenzar",
            "search": "Buscar creadores..."
        },
        "section1": {
            "logo_alt": "Ikonstar",
            "headline": "¡Conéctate con tus fans!",
            "subtitle": "Crea momentos de auténtica felicidad.",
            "description": "{0} te permite compartir momentos de alegría e inspiración con aquellos que más te apoyan. Interactúa directamente con tu audiencia, ofrece contenido exclusivo y crea recuerdos inolvidables juntos.",
            "descriptionStrong": "Ikonstar",
            "hero_banner": {
                "headline": "Ofrece contenido exclusivo a tu audiencia",
                "button_text": "Únete a nosotros ahora"
            }
        },
        "section2": {
            "headline": "Aporta tu Actitud Positiva.",
            "subtitle": "Comparte tu buen humor con el mundo.",
            "description": "En {0}, deja que tu energía positiva y tu pasión se transmitan. Comparte momentos auténticos e inspiradores con tus fans y construye una comunidad que vibra contigo todos los días.",
            "descriptionStrong": "Ikonstar",
            "hero_banner": {
                "headline": "Lives, videos, fotos, mensajería... ¡Todo está aquí!",
                "button_text": "Transmite ahora"
            }
        },
        "section3": {
            "headline": "Sé Inspirado.",
            "subtitle": "Alcanza nuevas alturas.",
            "description": "Inspírate con los testimonios y éxitos de los creadores que han encontrado su lugar en {0}. Descubre sus trayectorias y cómo utilizan nuestra plataforma para alcanzar sus sueños y llegar a miles de fans.",
            "descriptionStrong": "Ikonstar",
            "hero_banner": {
                "headline": "Masterclasses, Entrenamientos, Podcasts, Recetas...{0}¡Es tu turno!",
                "button_text": "Crea tu leyenda"
            }
        },
        "section4": {
            "headline": "Monetiza Tu Talento.",
            "subtitle": "Convierte tu pasión en ingresos.",
            "description": "Con {0}, puedes monetizar tu contenido y recibir el apoyo financiero de tus fans. Ofrece suscripciones, contenido exclusivo y más, todo mientras mantienes el control de tus creaciones.",
            "descriptionStrong": "Ikonstar",
            "hero_banner": {
                "headline": "Genera ingresos constantes,{0}Cobra tus ganancias automáticamente.",
                "button_text": "Empieza a ganar"
            }
        },
        "section5": {
            "headline": "¡Libera Tu Espíritu Creativo!",
            "subtitle": "Vive la aventura de la creación.",
            "description": "{0} te da la libertad de crear y compartir tus aventuras con tus fans. Aprovecha tu creatividad sin límites y atrae a tu audiencia con contenido único y cautivador.",
            "descriptionStrong": "Ikonstar",
            "hero_banner": {
                "headline": "Descubre una comunidad de talentos",
                "button_text": "Explora ahora"
            }
        },
        "section6": {
            "headline": "Involucra a Tu Comunidad",
            "subtitle": "Crea vínculos fuertes y duraderos",
            "description": "Usa nuestras herramientas de interacción de {0} para comunicarte directamente con tus fans. Crea publicaciones, organiza lives e involucra a tu comunidad para intercambios más ricos y personales.",
            "descriptionStrong": "Ikonstar",
            "hero_banner": {
                "headline": "¡Conviértete en una Ikonstar{0}Ahora Mismo!",
                "button_text": "¡Únete a nosotros!"
            },
            "tools_button_text": "Descubre las herramientas"
        }
    },

    user: {
        title: "Iniciar sesión en Ikonstar",
        navbar: {
            creators: "Nuestros Creadores",
            podcasters: "Podcasters",
            video_creators: "Creadores de Videos",
            musicians: "Músicos",
            artists: "Artistas",
            game_developers: "Desarrolladores de Juegos",
            features: "Características",
            academy: "Academia Ikonstar",
            login: "INICIAR SESIÓN",
            start: "EMPEZAR",
            search: "Buscar creadores ..."
        },
        section1: {
            logo_alt: "Ikonstar",
            headline: "Conviértete en un Fan Exclusivo",
            subtitle: "Apoya a tus creadores de contenido favoritos",
            description: "{0} te ofrece la oportunidad única de acercarte a los creadores que te inspiran. Disfruta de acceso exclusivo a su vida y contenido inédito, así como de interacciones personalizadas. Descubre el contenido exclusivo de tus influencers.",
            descriptionStrong: "Ikonstar",
            hero_banner: {
                headline: "Descubre el contenido{0}exclusivo de tus influencers",
                button_text: "Explorar nuestros creadores"
            }
        },
        section2: {
            headline: "Detrás de escena todos los días, 7 días a la semana",
            subtitle: "Accede a contenido exclusivo de tus estrellas y vive el detrás de cámaras con ellas.",
            description: "Solo los miembros de {0} pueden afirmar ser parte del círculo íntimo de su personalidad favorita y compartir las mejores experiencias con ellos a diario. Como fan exclusivo, tienes acceso a las historias, fotos, videos de tus estrellas y mucho más… ¡Accede a contenido exclusivo: Video, Foto, Videollamadas, y más!",
            descriptionStrong: "Ikonstar",
            hero_banner: {
                headline: "Accede a contenido exclusivo:{0}Video, Foto, Videollamadas, y más!",
                button_text: "Explorar nuestros creadores"
            }
        },
        section3: {
            headline: "¡Rompe todas las barreras!",
            subtitle: "Interactúa directamente con las personalidades que te inspiran. Sin barreras, sin filtros, sin restricciones, ellos están aquí para ti y solo para ti.",
            description: "Disfruta de interacciones directas y personalizadas con los creadores que amas. Ya sea a través de mensajes privados, chats en vivo o eventos en vivo exclusivos, {0} te permite forjar un vínculo fuerte y único con ellos. Comienza una relación única hoy.",
            descriptionStrong: "Ikonstar",
            hero_banner: {
                headline: "Comienza una relación{0}única hoy",
                button_text: "Explorar nuestros creadores"
            }
        },
        section4: {
            headline: "Vive una experiencia a medida",
            subtitle: "Videos o fotos completamente diseñados según tus deseos para ti o tus seres queridos",
            description: "Recibe contenido especialmente diseñado para ti. Comparte experiencias memorables con las estrellas de {0}. Solicita videos personalizados, mensajes exclusivos o sesiones de fotos que te acerquen a tus ídolos. Intercambia, comparte y personaliza tus momentos con tus ídolos.",
            descriptionStrong: "Ikonstar",
            hero_banner: {
                headline: "Intercambia, comparte y personaliza{0}tus momentos con tus ídolos.",
                button_text: "Explorar nuestros creadores"
            }
        },
        section5: {
            headline: "Todas tus pasiones reunidas en un solo lugar",
            subtitle: "Fitness, deportes, música, arte, cocina, estilo de vida, podcasts, todos están aquí y te esperan.",
            description: "Ya sea que te apasionen los vlogs, el fitness, la música, los podcasts o el estilo de vida, {0} reúne una amplia gama de talentos. Explora contenido diverso y conéctate con creadores que comparten tus intereses.",
            descriptionStrong: "Ikonstar",
            hero_banner: {
                headline: "Descubre todos nuestros talentos",
                button_text_2: "Explora ahora",
                button_text: "Explorar nuestros creadores"
            }
        },
        section6: {
            headline: "Donde los fans se encuentran con sus héroes",
            subtitle: "Suscríbete a tus creadores favoritos",
            description: "{0} es mucho más que una simple plataforma; es aquí donde desaparecen las barreras entre tú y tus creadores de contenido favoritos. Aquí, puedes seguir, interactuar y acercarte a tus ídolos como nunca antes. Ya sea descubriendo contenido exclusivo, participando en Lives o recibiendo mensajes personalizados, Ikonstar te coloca en el centro de la acción.",
            descriptionStrong: "Ikonstar",
            hero_banner: {
                headline: "Ikonstar te coloca{0}en el centro de la acción",
                button_text: "Explorar nuestros creadores"
            },
            tools_button_text: "ME UNO A IKONSTAR"
        }
    },

    "hero": {
        "titles": {
            "white": 'Tu talento{br}{gold}',
            "gold": 'vale dinero'
        },
        "subtitles": {
            "first": 'La mejor manera para los creadores de contenido de convertir a su audiencia en {bold}',
            "bold": 'suscriptores recurrentes de pago.'
        },
        "button": "Conviértete en un Ikonstar",
        "login": "Iniciar Sesión",
        welcome: "Bienvenido {username}",
        myProfile: "Mi cuenta"
    },
    "works": {
        title: '¿Cómo Funciona?',
        "step": 'Paso {step}',
        "titles": {
            "1": 'Personaliza tu página y suscripción',
            "2": 'Informa a tus seguidores que ahora tienes un perfil en Ikonstar',
            "3": 'Sé activo y atento con tus suscriptores'
        },
        "contents": {
            "1": {
                "main": 'Considera lo que puedes ofrecer de manera regular y qué hará feliz a tus fans.{0}{1}',
                "bold": {
                    "1": 'Sin complicaciones.'
                }
            },
            "2": {
                "main": '{1} para notificar a tus suscriptores. IKONSTAR es un lugar donde se forma una {2} - aquellos que desean algo más que seguirte en las redes sociales.',
                "bold": {
                    "1": 'Publica en todas tus redes sociales',
                    "2": 'relación especial entre tú y tus seguidores más activos'
                }
            },
            "3": {
                "main": 'Publica regularmente en tu espacio Ikonstar y {1}!',
                "bold": {
                    "1": 'promociónalo en tus redes sociales'
                }
            }
        }
    },
    "features": {
        "title": 'Características',
        "tabs": {
            "feed": {
                "title": 'Feed Privado',
                "content": 'Sumérgete en un flujo de contenido exclusivo, diseñado específicamente para suscriptores. {0}Acceso privilegiado a tu backstage, tus pensamientos y tus momentos únicos.'
            },
            "masterclass": {
                "title": 'Masterclass y Entrenamientos',
                "content": 'Transmite directamente tu historia y tu experiencia. Sesiones en profundidad que ofrecen conocimientos valiosos y consejos para mejorar tu influencia.'
            },
            "calls": {
                "title": 'Llamadas de video privadas',
                "content": 'Inicia sesión directamente. Una oportunidad inigualable para interactuar cara a cara con tus miembros, responder preguntas y transmitir consejos personalizados.'
            },
            "messages": {
                "title": 'Mensajes uno a uno',
                "content": 'Mantente cerca de tu comunidad, dedica tiempo a quienes son suscriptores pagados.'
            },
        }
    },
    "money": {
        "title": 'Muéstrame el dinero',
        "currency": '€',
        "subs": {
            "short": 'SUSC',
            "estimate": 'NÚMERO ESTIMADO DE SUSCRIPTORES',
            "price": 'PRECIO DE SUSCRIPCIÓN'
        },
        "revenue": {
            "title": "POR EL PRECIO DE SUSCRIPCIÓN{br}DE {price}, GANARÁS",
            "explain": "EUR/mes",
            "disclaimer": "Antes de impuestos y tarifas de la plataforma"
        }
    },
    "footer": {
        "white": 'Conviértete en un{br}{gold}',
        "button": "Únete ahora",
        "gold": 'Ikonstar{br}¡Ahora!'
    },
    "seo": {
        "title": "IKONSTAR: ¡Conviértete en un Ikonstar hoy y convierte tu pasión en éxito!",
        "description": "Descubre Ikonstar, la plataforma definitiva donde tu talento se convierte en una fuente de ingresos.",
        "tags": "monetizar,creatividad,compartir,exclusivo,contenido,comunidad,deportes,fitness,música,podcasts,estilo de vida,suscriptores,ingresos,Ikonstar,pasión,éxito"
    }
}