const liveEvents = {
    "title": "Événements en direct",
    "empty": "On dirait que vous n'avez encore aucun événement prévu",
    emptyUser: "On dirait qu'il n'y a pas encore d'événements de prévu",
    "sold": "Billet vendu | Billets vendus",
    "status": {
        "draft": "Brouillon",
        "active": "Actif",
        "in-progress": 'En cours',
        finished: 'Terminé',
    },
    "startsIn": "L'événement commence dans",
    "countdown": "{days} @:globals.countdown.days , {hours} @:globals.countdown.hours , {minutes} @:globals.countdown.minutes , {seconds} @:globals.countdown.seconds",
    active: "L'événement a commencé",
    soon: "quelques instants",
    "finished": "L'événement est terminé !",
    "thanks": "Merci d'avoir acheté un billet pour cet événement !",
    backToLive: {
        title: 'Veuillez vérifier que vous ne diffusez pas depuis un autre onglet !',
        confirm: 'Je ne le suis pas, commencez la diffusion',
        cancel: "Ce n'est rien, je l'ai trouvé",
    },
    cantStart: "Il semble que nous ne puissions pas démarrer un flux pour le moment. Si l'erreur persiste, veuillez nous contacter.",

    "viewLive": "ALLER AU STREAMING EN DIRECT",
    nextEvent: {
        helper: 'PROCHAIN ÉVÉNEMENT',
        start: 'DÉMARRER',
        inProgress: 'EN COURS',
        goBackLive: 'Retour au direct',
    },
    "payment": {
        "title": "Vos informations de paiement",
        "success": "Le paiement a été accepté !",
        "error": "Le billet pour l'événement ne peut pas être acheté pour le moment",
        "validate": "Acheter mon billet pour {price}",
        "secure": "Paiement 100% sécurisé",
        "endingWith": "se terminant par",
        convertedCurrency: "Les prix affichés en {currencySelected} sont donnés à titre indicatif uniquement. Vous serez facturé en {currencyEvent} pour {amount}.",
    },
    "actions": {
        "create": "Créer un événement en direct",
        "edit": "Modifier l'événement en direct",
        "delete": "Êtes-vous sûr de vouloir supprimer cet événement ?"
    },
    "buttons": {
        "back": "Événements en direct",
        "join": "Cliquez-ici pour rejoindre le live !"
    },
    "form": {
        "cover": {
            "label": "Image de couverture de l'événement en direct",
            "error": "Veuillez télécharger une image de couverture"
        },
        "video": {
            "label": "Vidéo teaser de l'événement en direct"
        },
        "title": {
            "label": "Titre"
        },
        "content": {
            "label": "Contenu"
        },
        "date": {
            "label": "Date de début de l'événement",
            short: "Date"
        },
        "price": {
            "label": "Prix d'entrée de l'événement",
            short: "Prix d'entrée"
        },
        "duration": {
            "label": "Durée prévue de l'événement (en minutes)",
            short: "Durée prévue"
        }
    },
    "user": {
        "empty": "On dirait que {creator} n'a encore aucun événement prévu",
        "actions": {
            "view": "Voir l'événement",
            "buy": "Acheter un billet"
        }
    }
}

export default liveEvents