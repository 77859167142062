const calendar = {
    "global": {
        "title": "Mon planning personnel",
        "calendar": "Calendrier",
        "today": "Aujourd'hui",
        "at": "à",
        "buttons": {
            "schedule": {
                "privateVideoCall": "Planifier un appel vidéo",
                "workout": "Planifier un entraînement",
                "recipe": "Ajouter une recette"
            },
            "event": {
                "goTo": "Aller à",
                "close": "Fermer",
                "delete": "Supprimer",
                "newWorkout": "Ajouter un entraînement",
                "newRecipe": "Ajouter une recette",
                "newCustom": "Ajouter un événement personnalisé",
                "newUser": "Ajouter un événement utilisateur",
                "live-streaming": 'Ajouter un live',
            },
            "back": "Retour au calendrier",
            "add": "Ajouter au calendrier",
            "backType": {
                "calendar": "Retour au calendrier",
                "custom": "Retour au calendrier",
                "messenger": "Retour à la messagerie",
                "workout": "Retour au calendrier",
                "private-call": "Retour à la messagerie",
                "recipe": "Retour au calendrier",
                'creator-recipe': "Retour au calendrier",
                "live-streaming": "Retour au calendrier",
            }
        },
        "modal": {
            "confirmDelete": {
                "title": "Êtes-vous sûr de vouloir supprimer ?",
                "confirm": "Supprimer",
                "cancel": "Annuler"
            }
        },
        "notification": {
            "add": {
                "success": "Votre événement a été ajouté avec succès !",
                "error": "Votre événement n'a pas été ajouté, veuillez réessayer !"
            },
            "update": {
                "success": "Votre événement a été mis à jour avec succès !",
                "error": "Votre événement n'a pas été mis à jour, veuillez réessayer !"
            },
            "delete": {
                "success": "Votre événement a été supprimé avec succès !",
                "error": "Votre événement n'a pas été supprimé, veuillez réessayer !"
            }
        },
        "filter": {
            "selectedDate": "Date sélectionnée",
            "selectedTime": "Heure sélectionnée",
            "search": {
                "placeholder": {
                    "recipe": "Rechercher une recette spécifique"
                }
            }
        },
        "event": {
            "add": "Ajouter un événement",
            "from": "De",
            "to": "à",
            "great": {
                "title": "Super",
                "content": "L'événement a été ajouté",
                "appointment": "Votre événement"
            },
            "label": {
                "creator-live-event": "Evènement",
            },
            "type": {
                "episode": "épisode",
                "workout": "entraînement",
                "private-call": "appel vidéo privé",
                "recipe": "recette",
                'creator-recipe': "recette",
                "custom": "personnalisé",
                "user": "utilisateur",
                "live-streaming": "Live",
                "creator-live-event": "l'évènement",
            }
        }
    },
    "user": {
        "recipe": {
            "add": "Ajouter une recette"
        },
        "workout": {
            "add": "Ajouter un exercice",
            "question": "Des questions ?"
        },
        "custom": {
            "add": "Ajouter un événement personnalisé"
        },
        "user": {
            "add": "Ajouter un événement utilisateur"
        },
        "live-streaming": {
            "add": 'Ajouter un événement live',
        },
        "payment": {
            "success": "Le paiement a été accepté !"
        },
        "privateVideoCall": {
            "description": "Le créateur doit valider l'emplacement ou discuter avec vous pour convenir d'une autre date. Pour l'instant, l'appel privé est en attente de confirmation, vous trouverez les informations dans le chat."
        }
    },
    "superAdmin": {},
    "creator": {},
    "guest": {},
    "form": {
        "list": {},
        "add": {
            "title": "Titre",
            "content": "Contenu",
            "selectUser": "Sélectionner un utilisateur",
            "button": {
                "save": "Enregistrer l'événement"
            }
        },
        "edit": {}
    }
}

export default calendar