const favorite = {
    user: {
        myFavorites: 'My Favorites',
        likedWorkouts: 'Workouts',
        likedMasterclasses: 'Masterclasses',
        likedPrograms: 'Programs',
        likedPosts: 'Posts',
        quicklyFindYourLikedWorkoutsHere: 'Quickly find your liked workouts here',
        quicklyFindYourLikedMasterclassesHere: 'Quickly find your liked masterclasses here',
        quicklyFindYourLikedProgramsHere: 'Quickly find your liked programs here',
        quicklyFindYourLikedPostsHere: 'Quickly find your liked posts here',
        nothingLikedYet: 'Nothing liked yet',
        browseMasterclasses: 'Browse Masterclasses',
        browseWorkouts: 'Browse Workouts',
        browsePrograms: 'Browse Programs',
        browsePosts: 'Browse Posts',
    },
}

export default favorite