const workout = {
     "global": {
        "title": "Entrenamientos",
        "likes": "me gusta",
        "kcalBurns": "Kcal quemadas",
        "workoutConsists": "El entrenamiento consta de",
        "kcal": "Kcal",
        "avgHeartRate": "Promedio de ritmo cardíaco",
        "bpm": "p.m.",
        "fatBurns": "Quema de grasa",
        "level": "Nivel",
        "equipments": "Equipamiento",
        "none": "Ninguno",
        "recommendWorkoutTime": "Tiempo de entrenamiento recomendado",
        "bodyParts": "Partes del cuerpo que se entrenarán",
        "goals": "Objetivos",
        "location": "Ubicación",
        "tips": "Consejos",
        "recommendedWorkoutTimePerWeek": {
            "label": "Tiempo recomendado de entrenamiento",
            "options": {
                "1": "1 día a la semana",
                "2": "2-3 días a la semana",
                "3": "3-4 días a la semana",
                "4": "5 días a la semana",
                "5": "Todos los días de la semana"
            }
        },
        elementsNotFound: 'No se encontraron elementos. Considere cambiar la consulta de búsqueda.',
    },
    "form": {
        "list": {
            "title": "Entrenamientos",
            "subTitle": "Mis entrenamientos",
            "empty": "Parece que aún no tienes ningún entrenamiento",
            "countSuffix": "videos",
            "minutes": "minutos",
            "button": {
                "create": {
                    "label": "Crear un nuevo entrenamiento"
                },
                "createFirst": {
                    "label": "Crear mi primer entrenamiento"
                },
                "delete": {
                    "label": "Eliminar"
                }
            }
        },
        "delete": {
            "button": {
                "confirm": {
                    "label": "Eliminar"
                },
                "cancel": {
                    "label": "Cancelar"
                }
            },
            "message": {
                "confirm": "¿Estás seguro de que deseas eliminar?",
                "success": "Tu entrenamiento se eliminó correctamente",
                "error": "Tu entrenamiento no se eliminó, inténtalo de nuevo"
            }
        },
        "create": {
            "title": "Crear un nuevo entrenamiento",
            "subTitle": {
                "information": "Información básica",
                "workoutDetail": "Detalles del entrenamiento",
                "additional": "Detalles adicionales"
            },
            "_title": {
                "label": "Título del entrenamiento"
            },
            "description": {
                "label": "Descripción breve del entrenamiento"
            },
            "cover": {
                "label": "Imagen de portada",
                "idleLabel": "Haz clic para seleccionar o arrastra un archivo aquí..."
            },
            "isScheduled": {
                "label": "Programado"
            },
            "publishedAt": {
                "label": "Publicado el"
            },
            "isPinned": {
                "label": "Anclado"
            },
            "isPaid": {
                "label": "Pagado"
            },
            "price": {
                "label": "Precio"
            },
            "category": {
                "label": "Categoría"
            },
            "level": {
                "label": "Nivel de la sesión"
            },
            "bodyPart": {
                "label": "¿Qué parte del cuerpo se entrenará?",
                "placeholder": "Selecciona una parte del cuerpo"
            },
            "caloriesSpendInKcals": {
                "label": "Promedio de quema de carbohidratos (kCal)"
            },
            "averageHeartBeatPerMinute": {
                "label": "Promedio de ritmo cardíaco"
            },
            "recommendedWorkoutTimePerWeek": {
                "label": "Tiempo recomendado de entrenamiento",
                "options": {
                    "1": "1 día a la semana",
                    "2": "2-3 días a la semana",
                    "3": "3-4 días a la semana",
                    "4": "5 días a la semana",
                    "5": "Todos los días de la semana"
                }
            },
            "tips": {
                "helper": "Consejo #",
                "label": "¿Tienes algún consejo para los usuarios?",
                "add": "Agregar consejos",
                "explain": "Puedes reorganizar tus consejos con arrastrar y soltar en las flechas"
            },
            "locations": {
                "label": "¿Dónde pueden los usuarios hacer este entrenamiento?",
                "placeholder": "Selecciona ubicaciones de entrenamiento"
            },
            "goals": {
                "label": "¿Cuál es el objetivo de este entrenamiento?",
                "placeholder": "Selecciona objetivos de entrenamiento"
            },
            "highlights": {
                "label": "¿Deseas agregar etiquetas específicas a tu entrenamiento?"
            },
            "button": {
                "submit": {
                    "label": "Crear Entrenamiento"
                },
                save: 'Guardar',
                saveAndGo: 'Guardar y gestionar los ejercicios',
            },
            "message": {
                "success": "El entrenamiento en serie se creó con éxito"
            }
        },
        "edit": {
            "title": "Editar entrenamiento",
            "button": {
                "manageEpisodes": {
                    "label": "Gestionar episodios"
                },
                "preview": {
                    "label": "Vista previa"
                },
                "submit": {
                    "label": "Actualizar Entrenamiento"
                }
            },
            "message": {
                "success": "El entrenamiento en serie se actualizó con éxito"
            }
        }
    },
    "coverImage": {
        "form": {
            "delete": {
                "button": {
                    "confirm": {
                        "label": "Eliminar"
                    },
                    "cancel": {
                        "label": "Cancelar"
                    }
                },
                "message": {
                    "confirm": "¿Estás seguro de que deseas eliminar?",
                    "success": "La imagen de portada se eliminó correctamente",
                    "error": "La imagen de portada no se eliminó, inténtalo de nuevo"
                }
            }
        }
    },
    "coverVideo": {
        "form": {
            "delete": {
                "button": {
                    "confirm": {
                        "label": "Eliminar"
                    },
                    "cancel": {
                        "label": "Cancelar"
                    }
                },
                "message": {
                    "confirm": "¿Estás seguro de que deseas eliminar?",
                    "success": "El video se eliminó correctamente",
                    "error": "El video no se eliminó, inténtalo de nuevo"
                }
            }
        }
    },

    "exercise": {
        "form": {
            "list": {
                "title": "Ejercicios de entrenamiento",
                "empty": "Parece que no tienes ningún ejercicio en este entrenamiento aún",
                "exercise": {
                    "label": "Ejercicio"
                },
                "exerciseNumber": {
                    "label": "Ejercicio #"
                },
                "_title": {
                    "label": "Título del ejercicio"
                },
                "type": {
                    "label": "Tipo de contenido"
                },
                "videoStatus": {
                    "label": "Estado de codificación de video",
                    "status": {
                        "none": {
                            "color": "text-bg-warning",
                            "text": "Sin video cargado"
                        },
                        "pending": {
                            "color": "text-bg-info text-white",
                            "text": "Esperando codificación"
                        },
                        "processing": {
                            "color": "text-bg-warning",
                            "text": "Codificando"
                        },
                        "failed": {
                            "color": "text-bg-danger",
                            "text": "Fallo en la codificación"
                        },
                        "done": {
                            "color": "text-bg-success",
                            "text": "Codificado y disponible"
                        }
                    }
                },
                "action": {
                    "label": ""
                },
                "button": {
                    "create": {
                        "label": "Crear un ejercicio de entrenamiento"
                    },
                    "createMultiple": {
                        "label": "Crear múltiples ejercicios rápidamente"
                    },
                    "viewTips": {
                        "label": "Ver consejos"
                    }
                }
            },
            "delete": {
                "button": {
                    "confirm": {
                        "label": "Eliminarlo"
                    },
                    "cancel": {
                        "label": "Cancelar"
                    }
                },
                "message": {
                    "confirm": "¿Estás seguro de que deseas eliminarlo?",
                    "success": "Tu ejercicio se eliminó con éxito",
                    "error": "Tu ejercicio no se eliminó, inténtalo de nuevo"
                }
            },
            "create": {
                "title": "Crear un ejercicio de entrenamiento",
                "subTitle": {
                    "information": "Información del ejercicio",
                    "additional": "Detalles adicionales",
                    "content": "Detalles del contenido del ejercicio"
                },
                visibility: {
                    label: "¿Quién tiene acceso?",
                    "everyone": "Disponible para todos",
                    "subscriber": "Solo suscriptores",
                },
                "_title": {
                    "label": "Título del ejercicio"
                },
                "description": {
                    "label": "Descripción"
                },
                "cover": {
                    "label": "Imagen de portada",
                    "idleLabel": "Haz clic para seleccionar o arrastra un archivo aquí...",
                    "current": "Imagen de portada actual"
                },
                "caloriesSpendInKcals": {
                    "label": "Calorías gastadas en kCals"
                },
                "repetition": {
                    "label": "Repetición"
                },
                "set": {
                    "label": "Sets"
                },
                "restingRepeatTime": {
                    "label": "Tiempo de descanso entre sets (segundos)"
                },
                "requiredEquipments": {
                    "label": "¿Se requiere algún equipo para tu ejercicio?",
                    "add": "Agregar un equipo"
                },
                "exerciseNumber": {
                    "label": "Número de ejercicio"
                },
                "type": {
                    "label": "Tipo de contenido"
                },
                "content": {
                    "label": "Instrucciones del ejercicio",
                    "htmlLabel": "Contenido HTML"
                },
                "file": {
                    "label": "Archivo",
                    "current": "Video actual"
                },
                "isPaid": {
                    "label": "Es de pago"
                },
                "isPinned": {
                    "label": "Está fijado"
                },
                "daysBeforeRelease": {
                    "label": "Días antes del lanzamiento"
                },
                "rejectedReason": {
                    "label": "Motivo del rechazo"
                },
                "button": {
                    "submit": {
                        "label": "Crear ejercicio de entrenamiento",
                        "uploading": "Subiendo..."
                    }
                },
                "message": {
                    "success": "El ejercicio de entrenamiento se creó con éxito"
                }
            },
            "edit": {
                "title": "Editar ejercicio de entrenamiento",
                "button": {
                    "submit": {
                        "label": "Actualizar ejercicio de entrenamiento",
                        "uploading": "Subiendo..."
                    }
                },
                "message": {
                    "success": "El ejercicio de entrenamiento se actualizó con éxito"
                }
            },
            "createMultiple": {
                "title": "Crear múltiples ejercicios rápidamente",
                "createMultipleEpisodes": "Crear múltiples episodios",
                "selectedMedias": {
                    "label": "Medios seleccionados",
                    "noMediaSelected": "No se ha seleccionado ningún medio. Puedes seleccionar medios de la biblioteca de medios",
                    "dragToReorder": "Puedes arrastrar para reordenar los ejercicios"
                },
                "mediaLibrary": {
                    "label": "Biblioteca de medios",
                    "noMediaFound": "No se encontraron medios"
                },
                "search": {
                    "placeholder": "Buscar medios..."
                },
                "button": {
                    "backToExercises": {
                        "label": "Volver a los ejercicios de entrenamiento"
                    },
                    "createExercisesWithSelectedMedias": {
                        "label": "Crear ejercicios con {n} medios seleccionados"
                    },
                    "uploadNewMedia": {
                        "label": "Cargar nuevo medio"
                    },
                    "confirm": {
                        "label": "Sí, crear"
                    },
                    "cancel": {
                        "label": "No, cancelar"
                    }
                },
                "message": {
                    "confirmCreateExercisesWithNSelectedMedias": "¿Estás seguro de que deseas crear ejercicio(s) con {n} medio(s) seleccionado(s)?",
                    "success": "Ejercicios creados con éxito",
                    "error": "Error al crear ejercicios"
                }
            },
            "view": {
                "repsIndication": "Sets: {current} / {total}",
                "nextEpisodeIn": "Próximo episodio en",
                "nextRepIn": "Próximo set en",
                "takeABreak": "Tomar un descanso",
                "enterYourSet": {
                    "label": "Número de sets"
                },
                "times": {
                    "label": "set"
                },
                "repetitions": {
                    "label": "Repeticiones"
                },
                "requiredEquipments": {
                    "label": "Equipos requeridos"
                },
                "closeVideoReason": {
                    "label": "Por favor, selecciona un motivo"
                },
                "button": {
                    "ready": {
                        "label": "Listo"
                    },
                    "submitCloseReason": {
                        "label": "Enviar"
                    },
                    "cancelCloseReason": {
                        "label": "Continuar con el ejercicio"
                    }
                },
                "message": {
                    "confirmExit": "¿Estás seguro de que deseas cerrar el ejercicio?"
                }
            }
        }
    },

    "program": {
        "global": {
            "title": "Programas",
            perPage: 'por página',
            copyPrevious: 'Copiar el anterior',
            copyToAll: 'Copiar a todos',
            notFound: 'No se encontraron entrenamientos...',
        },
        "form": {
            "list": {
                "title": "Mis programas de entrenamiento",
                "empty": "Parece que todavía no tienes ningún programa",
                "equipments": "Equipos",
                "none": "Ninguno",
                "minutes": "minutos",
                "bodyParts": "Partes del cuerpo que se entrenarán",
                "goals": "Objetivos",
                "search": {
                    "placeholder": "Buscar programas..."
                },
                "week": {
                    "label": "semana",
                    "plural": "semanas"
                },
                "likes": {
                    "label": "me gusta"
                },
                "button": {
                    "create": {
                        "label": "Crear un nuevo programa"
                    }
                }
            },
            "create": {
                "title": "Crear un nuevo programa",
                "subTitle": {
                    "information": "Información básica",
                    "workouts": "Seleccionar entrenamientos",
                    "otherDetails": {
                        "title": "Planificador",
                        "description": "¿Cómo sugieres organizar el ejercicio?"
                    },
                    "recommend": "Recomendado para"
                },
                "bodyPart": {
                    "label": "Partes del cuerpo",
                    "placeholder": "Selecciona partes del cuerpo"
                },
                "level": {
                    "label": "Nivel",
                    "placeholder": "Selecciona nivel"
                },
                "category": {
                    "label": "Categoría",
                    "placeholder": "Selecciona una categoría"
                },
                "cover": {
                    "label": "Imagen de portada",
                    "idleLabel": "Haz clic para seleccionar o arrastra un archivo aquí...",
                    "current": "Portada actual"
                },
                "_title": {
                    "label": "Título del programa",
                    "placeholder": "Ingresa el título del programa"
                },
                "description": {
                    "label": "Breve descripción del programa de entrenamiento",
                    "placeholder": "Ingresa una breve descripción del programa de entrenamiento"
                },
                "totalDay": {
                    "label": "Días totales",
                    "placeholder": "Ingresa los días totales"
                },
                "totalWeek": {
                    "label": "Semanas totales",
                    "placeholder": "Ingresa las semanas totales"
                },
                "features": {
                    "label": "Características del programa",
                    "explain": "Puedes reorganizar tus consejos arrastrándolos y soltándolos en las flechas",
                    "add": "Agregar característica",
                    "helper": "Característica #"
                },
                "bodyParts": {
                    "label": "Intenso en partes del cuerpo"
                },
                "highlights": {
                    "label": "Aspectos destacados",
                    "add": "Agregar aspecto destacado"
                },
                "file": {
                    "label": "Cargar video del programa",
                    "current": "Video actual"
                },
                "workouts": {
                    "label": "Seleccionar entrenamientos para incluir en la rotación",
                    "chosen": "Elegidos {number} entrenamientos"
                },
                "goals": {
                    "label": "Para personas que desean..."
                },
                "recipeCategory": {
                    "label": "Tipo de receta recomendada",
                    "placeholder": "Selecciona un tipo de receta"
                },
                "daySelect": {
                    "weekX": "Semana {number}",
                    "dayXY": "Día {from} - {to}",
                    "breakDay": "Día de descanso",
                    "dayX": "Día {number}"
                },
                "message": {
                    "noWorkoutSelected": "Por favor, selecciona al menos un entrenamiento",
                    "noTotalWeekSpecified": "Por favor, especifica el total de semanas",
                    "featureEmpty": "Por favor, completa todo el contenido de la característica o elimina los campos vacíos",
                    "success": "El programa de entrenamiento se creó con éxito"
                },
                "button": {
                    "submit": {
                        "label": "Crear programa"
                    }
                }
            },
            "edit": {
                "title": "Editar programa de entrenamiento",
                "button": {
                    "submit": {
                        "label": "Actualizar programa"
                    }
                },
                "message": {
                    "success": "El programa de entrenamiento se actualizó con éxito"
                }
            }
        }
    },
}

export default workout