const workout = {
    global: {
        "title": "Entraînements",
        "likes": "j'aime",

        "kcalBurns": "Kcal brûlées",
        "workoutConsists": "L'entraînement se compose de",
        "kcal": "Kcal",
        "avgHeartRate": "Freq. Cardiaque",
        "bpm": "bpm",
        "fatBurns": "Brûlage des graisses",
        "level": "Niveau",
        "equipments": "Équipements",
        "none": "Aucun",
        "recommendWorkoutTime": "Temps d'entraînement recommandé",
        "bodyParts": "Les parties du corps qui seront entraînées",
        "goals": "Objectifs",
        "location": "Emplacement",
        "tips": "Conseils",
        "recommendedWorkoutTimePerWeek": {
            "label": "Temps d'entraînement recommandé",
            "options": {
                "1": "1 jour par semaine",
                "2": "2-3 jours par semaine",
                "3": "3-4 jours par semaine",
                "4": "5 jours par semaine",
                "5": "Tous les jours de la semaine"
            }
        },
        elementsNotFound: 'Aucun élément trouvé. Changer la requête de recherche.',
    },

    form: {
        "list": {
            "title": "Entraînements",
            "subTitle": "Mes entraînements",
            "empty": "Il semble que vous n'ayez pas encore d'entraînement",
            "countSuffix": "vidéos",
            "minutes": "minutes",
            "button": {
                "create": {
                    "label": "Créer un nouvel entraînement"
                },
                "createFirst": {
                    "label": "Créer mon premier entraînement"
                },
                "delete": {
                    "label": "Supprimer"
                }
            }
        },
        "delete": {
            "button": {
                "confirm": {
                    "label": "Supprimer"
                },
                "cancel": {
                    "label": "Annuler"
                }
            },
            "message": {
                "confirm": "Êtes-vous sûr de vouloir supprimer ?",
                "success": "Votre entraînement a été supprimé avec succès !",
                "error": "Votre entraînement n'a pas été supprimé, réessayez !"
            }
        },
        "create": {
            "title": "Créer un nouvel entraînement",
            "subTitle": {
                "information": "Informations de base",
                "workoutDetail": "Détails de l'entraînement",
                "additional": "Détails supplémentaires"
            },
            "_title": {
                "label": "Titre de l'entraînement"
            },
            "description": {
                "label": "Brève description de l'entraînement"
            },
            "cover": {
                "label": "Image de couverture",
                "idleLabel": "Cliquez pour sélectionner ou déposez un fichier ici..."
            },
            "isScheduled": {
                "label": "Est planifié"
            },
            "publishedAt": {
                "label": "Publié le"
            },
            "isPinned": {
                "label": "Est épinglé"
            },
            "isPaid": {
                "label": "Est payant"
            },
            "price": {
                "label": "Prix"
            },
            "category": {
                "label": "Catégorie"
            },
            "level": {
                "label": "Niveau de la session"
            },
            "bodyPart": {
                "label": "Quelle partie du corps sera entraînée ?",
                "placeholder": "Choisissez la partie du corps"
            },
            "caloriesSpendInKcals": {
                "label": "Entrez la dépense moyenne en glucides (kCal)"
            },
            "averageHeartBeatPerMinute": {
                "label": "Entrez la fréquence cardiaque moyenne"
            },
            "recommendedWorkoutTimePerWeek": {
                "label": "Temps d'entraînement recommandé",
                "options": {
                    "1": "1 jour par semaine",
                    "2": "2-3 jours par semaine",
                    "3": "3-4 jours par semaine",
                    "4": "5 jours par semaine",
                    "5": "Tous les jours de la semaine"
                }
            },
            "tips": {
                "helper": "Conseil #",
                "label": "Des conseils pour les utilisateurs ?",
                "add": "Ajouter des conseils",
                "explain": "Vous pouvez réorganiser vos conseils avec un glisser-déposer sur les flèches"
            },
            "locations": {
                "label": "Où les utilisateurs peuvent-ils faire cet entraînement ?",
                "placeholder": "Choisissez les lieux d'entraînement"
            },
            "goals": {
                "label": "Quel est l'objectif de cet entraînement ?",
                "placeholder": "Choisissez les objectifs de l'entraînement"
            },
            "highlights": {
                "label": "Souhaitez-vous ajouter des tags spécifiques à votre entraînement ?"
            },
            "button": {
                "submit": {
                    "label": "Créer un entraînement"
                },
                save: 'Sauvegarder',
                saveAndGo: 'Sauvegarder et gérer les exercices',
            },
            "message": {
                "success": "L'entraînement en série a été créé avec succès !"
            }
        },
        "edit": {
            "title": "Modifier l'entraînement",
            "button": {
                "manageEpisodes": {
                    "label": "Gérer les exercises"
                },
                "preview": {
                    "label": "Aperçu"
                },
                "submit": {
                    "label": "Mettre à jour l'entraînement"
                }
            },
            "message": {
                "success": "L'entraînement en série a été mis à jour avec succès !"
            }
        }
    },

    coverImage: {
        "form": {
            "delete": {
                "button": {
                    "confirm": {
                        "label": "Supprimer"
                    },
                    "cancel": {
                        "label": "Annuler"
                    }
                },
                "message": {
                    "confirm": "Êtes-vous sûr de vouloir supprimer ?",
                    "success": "L'image de couverture a été supprimée avec succès !",
                    "error": "L'image de couverture n'a pas été supprimée, réessayez !"
                }
            }
        }
    },

    coverVideo: {
        "form": {
            "delete": {
                "button": {
                    "confirm": {
                        "label": "Supprimer"
                    },
                    "cancel": {
                        "label": "Annuler"
                    }
                },
                "message": {
                    "confirm": "Êtes-vous sûr de vouloir supprimer ?",
                    "success": "La vidéo a été supprimée avec succès !",
                    "error": "La video n'a pas été supprimée, réessayez !"
                }
            }
        }
    },

    exercise: {
        "form": {
            "list": {
                "title": "Exercices",
                "empty": "Il semble que vous n'ayez pas encore d'exercice dans cet entraînement",

                "exercise": {
                    "label": "Exercice"
                },
                "exerciseNumber": {
                    "label": "Exercice n°"
                },
                "_title": {
                    "label": "Titre de l'exercice"
                },
                "type": {
                    "label": "Type de contenu"
                },
                "videoStatus": {
                    "label": "État d'encodage de la vidéo",
                    "status": {
                        "none": {
                            "color": "text-bg-warning",
                            "text": "Aucune vidéo téléchargée"
                        },
                        "pending": {
                            "color": "text-bg-info text-white",
                            "text": "En attente d'encodage"
                        },
                        "processing": {
                            "color": "text-bg-warning",
                            "text": "Encodage en cours"
                        },
                        "failed": {
                            "color": "text-bg-danger",
                            "text": "Échec de l'encodage"
                        },
                        "done": {
                            "color": "text-bg-success",
                            "text": "Encodée et disponible"
                        }
                    }
                },
                "action": {
                    "label": ""
                },

                "button": {
                    "create": {
                        "label": "Créer un exercice"
                    },
                    "createMultiple": {
                        "label": "Créer rapidement plusieurs exercices"
                    },
                    "viewTips": {
                        "label": "Voir les conseils"
                    }
                }
            },
            "delete": {
                "button": {
                    "confirm": {
                        "label": "Supprimer"
                    },
                    "cancel": {
                        "label": "Annuler"
                    }
                },
                "message": {
                    "confirm": "Êtes-vous sûr de vouloir supprimer ?",
                    "success": "Votre exercice a été supprimé avec succès !",
                    "error": "Votre exercice n'a pas été supprimé, réessayez !"
                }
            },
            "create": {
                "title": "Créer un exercice",
                "subTitle": {
                    "information": "Informations sur l'exercice",
                    "additional": "Détails supplémentaires",
                    "content": "Détails du contenu de l'exercice"
                },
                "visibility": {
                    "label": "Qui peut accéder à la publication",
                    "everyone": 'Disponible pour tout le monde',
                    "subscriber": 'Abonnés uniquement',
                },
                "_title": {
                    "label": "Titre de l'exercice"
                },
                "description": {
                    "label": "Description"
                },
                "cover": {
                    "label": "Image de couverture",
                    "idleLabel": "Cliquez pour sélectionner ou déposez un fichier ici...",
                    "current": "Image de couverture actuelle"
                },
                "caloriesSpendInKcals": {
                    "label": "Calories brûlées en kcal"
                },
                "repetition": {
                    "label": "Répétition"
                },
                "set": {
                    "label": "Sets"
                },
                "restingRepeatTime": {
                    "label": "Temps de repos entre les Sets (secondes)"
                },
                "requiredEquipments": {
                    "label": "Équipement nécessaire pour votre exercice",
                    "add": "Ajouter un équipement"
                },
                "exerciseNumber": {
                    "label": "Numéro de l'exercice"
                },
                "type": {
                    "label": "Type de contenu"
                },
                "content": {
                    "label": "Instructions de l'exercice",
                    "htmlLabel": "Contenu HTML"
                },
                "file": {
                    "label": "Fichier",
                    "current": "Vidéo actuelle"
                },
                "isPaid": {
                    "label": "Payant"
                },
                "isPinned": {
                    "label": "Épinglé"
                },
                "daysBeforeRelease": {
                    "label": "Jours avant la publication"
                },
                "rejectedReason": {
                    "label": "Raison du rejet"
                },

                "button": {
                    "submit": {
                        "label": "Créer un exercice",
                        "uploading": "Téléchargement en cours..."
                    }
                },

                "message": {
                    "success": "L'exercice a été créé avec succès !"
                }
            },
            "edit": {
                "title": "Modifier l'exercice",

                "button": {
                    "manageEpisodes": {
                        "label": "Gérer les exercices"
                    },
                    "preview": {
                        "label": "Aperçu"
                    },
                    "submit": {
                        "label": "Mettre à jour l'exercice",
                        "uploading": "Téléchargement en cours..."
                    }
                },

                "message": {
                    "success": "L'exercice a été mis à jour avec succès !"
                }
            },
            "createMultiple": {
                "title": "Créer rapidement plusieurs exercices",
                "createMultipleEpisodes": "Créer plusieurs exercices",
                "selectedMedias": {
                    "label": "Médias sélectionnés",
                    "noMediaSelected": "Aucun média sélectionné. Vous pouvez sélectionner des médias dans la bibliothèque de médias",
                    "dragToReorder": "Vous pouvez les faire glisser pour réorganiser les exercices"
                },
                "mediaLibrary": {
                    "label": "Bibliothèque de médias",
                    "noMediaFound": "Aucun média trouvé"
                },
                "search": {
                    "placeholder": "Rechercher des médias..."
                },

                "button": {
                    "backToExercises": {
                        "label": "Retour aux exercices"
                    },
                    "createExercisesWithSelectedMedias": {
                        "label": "Créer des exercices avec {n} média(s) sélectionné(s)"
                    },
                    "uploadNewMedia": {
                        "label": "Télécharger de nouveaux médias"
                    },
                    "confirm": {
                        "label": "Oui, créer"
                    },
                    "cancel": {
                        "label": "Non, annuler"
                    }
                },

                "message": {
                    "confirmCreateExercisesWithNSelectedMedias":
                        "Êtes-vous sûr de vouloir créer {n} exercice(s) avec le(s) média(s) sélectionné(s) ?",
                    "success": "Exercices créés avec succès !",
                    "error": "Erreur lors de la création des exercices"
                }
            },
            "view": {
                "repsIndication": "Sets : {current} / {total}",
                "nextEpisodeIn": "Prochain exercice dans",
                "nextRepIn": "Prochain set dans",
                "takeABreak": "Faites une pause",

                "enterYourSet": {
                    "label": "Nombre de Sets"
                },
                "times": {
                    "label": "sets"
                },
                "repetitions": {
                    "label": "Répétitions"
                },
                "requiredEquipments": {
                    "label": "Équipements nécessaires"
                },

                "closeVideoReason": {
                    "label": "Veuillez sélectionner une raison"
                },

                "button": {
                    "ready": {
                        "label": "Prêt"
                    },
                    "submitCloseReason": {
                        "label": "Soumettre"
                    },
                    "cancelCloseReason": {
                        "label": "Continuer l'exercice"
                    }
                },

                "message": {
                    "confirmExit": "Êtes-vous sûr de vouloir quitter l'exercice ?"
                }
            }
        }
    },

    program: {
        "global": {
            "title": "Programmes",
            perPage: 'par page',
            copyPrevious: 'Copier le précédent',
            copyToAll: 'Copier à tous',
            notFound: 'Aucuns entraînements trouvés...',
        },
        "form": {
            "list": {
                "title": "Mes programmes d'entraînement",
                "empty": "On dirait que vous n'avez pas encore de programme",
                "equipments": "Équipements",
                "none": "Aucun",
                "minutes": "minutes",
                "bodyParts": "Parties du corps entraînées",
                "goals": "Objectifs",

                "search": {
                    "placeholder": "Rechercher des programmes..."
                },

                "week": {
                    "label": "semaine",
                    "plural": "semaines"
                },
                "likes": {
                    "label": "likes"
                },

                "button": {
                    "create": {
                        "label": "Créer un nouveau programme"
                    }
                }
            },

            "create": {
                "title": "Créer un nouveau programme",

                "subTitle": {
                    "information": "Informations de base",
                    "workouts": "Sélectionner des entraînements",
                    "otherDetails": {
                        "title": "Planning",
                        "description": "Comment suggérez-vous d'organiser l'exercice ?"
                    },
                    "recommend": "Recommander à"
                },

                "bodyPart": {
                    "label": "Parties du corps",
                    "placeholder": "Choisir les parties du corps"
                },
                "level": {
                    "label": "Niveau",
                    "placeholder": "Choisir le niveau"
                },
                "category": {
                    "label": "Catégorie",
                    "placeholder": "Sélectionner une catégorie"
                },
                "cover": {
                    "label": "Image de couverture",
                    "idleLabel": "Cliquez pour sélectionner ou déposer un fichier ici...",
                    "current": "Image de couverture actuelle"
                },
                "_title": {
                    "label": "Titre du programme",
                    "placeholder": "Entrez le titre du programme"
                },
                "description": {
                    "label": "Description brève du programme d'entraînement",
                    "placeholder": "Entrez une brève description du programme d'entraînement"
                },
                "totalDay": {
                    "label": "Nombre total de jours",
                    "placeholder": "Entrez le nombre total de jours"
                },
                "totalWeek": {
                    "label": "Nombre total de semaines",
                    "placeholder": "Entrez le nombre total de semaines"
                },
                "features": {
                    "label": "Caractéristiques du programme",
                    "explain": "Vous pouvez réorganiser vos conseils en les faisant glisser et en les déposant sur les flèches",
                    "add": "Ajouter une caractéristique",
                    "helper": "Caractéristique n°"
                },
                "bodyParts": {
                    "label": "Intensité sur les parties du corps"
                },
                "highlights": {
                    "label": "Points forts",
                    "add": "Ajouter un point fort"
                },
                "file": {
                    "label": "Télécharger la vidéo du programme",
                    "current": "Vidéo actuelle"
                },
                "workouts": {
                    "label": "Choisir les entraînements à inclure dans une rotation",
                    "chosen": "Entraînements choisis : {number}"
                },
                "goals": {
                    "label": "Pour les personnes qui veulent...",
                },
                "recipeCategory": {
                    "label": "Type de recette à recommander",
                    "placeholder": "Sélectionnez un type de recette"
                },

                "daySelect": {
                    "weekX": "Semaine {number}",
                    "dayXY": "Jour {from} - {to}",
                    "breakDay": "Jour de repos",
                    "dayX": "Jour {number}"
                },

                "message": {
                    "noWorkoutSelected": "Veuillez sélectionner au moins un exercice",
                    "noTotalWeekSpecified": "Veuillez spécifier le nombre total de semaines",
                    "featureEmpty": "Veuillez remplir tout le contenu de la caractéristique ou supprimer les champs vides",
                    "success": "Le programme d'entraînement a été créé avec succès !"
                },

                "button": {
                    "submit": {
                        "label": "Créer le programme"
                    }
                }
            },

            "edit": {
                "title": "Modifier le programme d'entraînement",

                "button": {
                    "submit": {
                        "label": "Mettre à jour le programme"
                    }
                },

                "message": {
                    "success": "Le programme d'entraînement a été mis à jour avec succès !"
                }
            }
        }
    },
}

export default workout