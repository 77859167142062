const masterclass = {
    "global": {
        "title": "Mes masterclass",
        "empty": "On dirait que vous n'avez pas encore de masterclass",
        "masterclass": "Masterclass",
        "card": {
            "countSuffix": "épisode | épisodes"
        },
        "likes": "j'aime",
        "delete": "Supprimer",
        "status": {
            "label": "Statut",
            "draft": "Mode brouillon - non visible pour les utilisateurs",
            "draft-label": "Brouillon",
            "active": "Publié",
            "active-label": "Publié",
        },
        "contentType": {
            "video": "Vidéo uniquement",
            "html": "Texte uniquement",
            "mixed": "Vidéo avec texte"
        }
    },
    "user": {
        "title": "Masterclasses",
        "search": {
            "placeholder": "Trouver une masterclass"
        },
        "emptyFilter": "Il n'y a pas de masterclass avec ces filtres",
        "empty": "n'a encore publié aucune masterclass"
    },
    "superAdmin": {},
    "creator": {
        "buttons": {
            "create": "Créer une nouvelle masterclass",
            "createFirst": "Créer ma première masterclass",
            "submit": "Créer une masterclass",
            "manageEpisode": "Gérer les épisodes",
            "edit": "Modifier la masterclass",
            "createEpisode": "Créer un nouvel épisode",
            "editEpisode": "Modifier l'épisode",
            "uploading": "Chargement de la vidéo en cours...",
            save: 'Sauvegarder',
        },
        "modal": {
            "confirmDelete": {
                "title": "Êtes-vous sûr de vouloir supprimer ?",
                "confirm": "Supprimer",
                "cancel": "Annuler"
            },
            "notification": {
                "delete": {
                    "success": "Votre épisode a été supprimé avec succès !",
                    "error": "Votre épisode n'a pas été supprimé, veuillez réessayer !"
                }
            }
        },
        "notification": {
            "add": {
                "success": "Votre masterclass a été ajoutée avec succès !",
                "error": "Votre masterclass n'a pas été ajoutée, veuillez réessayer !"
            },
            "update": {
                "success": "Votre masterclass a été mise à jour avec succès !",
                "error": "Votre masterclass n'a pas été mise à jour, veuillez réessayer !"
            },
            "delete": {
                "success": "Votre masterclass a été supprimée avec succès !",
                "error": "Votre masterclass n'a pas été supprimée, veuillez réessayer !"
            }
        }
    },
    "guest": {},
    "form": {
        "list": {},
        "add": {
            "title": "Créer une nouvelle masterclass",
            "steps": {
                "information": "Informations de base"
            },
            "fields": {
                "title": {
                    "label": "Titre de la masterclass"
                },
                "description": {
                    "label": "Description courte de la masterclass"
                },
                "isScheduled": {
                    "label": "Est planifiée"
                },
                "publishedAt": {
                    "label": "Publiée le"
                },
                "isPaid": {
                    "label": "Est payante"
                },
                "price": {
                    "label": "Prix"
                },
                "isPinned": {
                    "label": "Est épinglée"
                },
                "cover": {
                    "label": "Image de couverture",
                    "current": "Couverture actuelle",
                    "placeholder": "Cliquez pour sélectionner ou faites glisser un fichier ici..."
                }
            }
        },
        "edit": {
            "title": "Modifier la masterclass",
            delete: {
                titleDelete: 'Etes-vous de vouloir supprimer la cover ?',
                confirmDelete: 'Oui',
                cancelDelete: 'Annuler',
                success: 'La cover a été supprimée avec succés !',
                error: "La cover n'a pas pu être supprimée, essayer encore !",
            }
        },
        "episodes": {
            "list": {
                "title": "Épisodes",
                "empty": "On dirait que vous n'avez encore aucun épisode.",
                "table": {
                    "number": "Numéro de l'épisode",
                    "title": "Titre de l'épisode",
                    "type": "Type de contenu",
                    "videoStatus": {
                        "title": "État de l'encodage vidéo",
                        "status": {
                            "none": {
                                "color": "text-bg-warning",
                                "text": "Aucune vidéo téléchargée"
                            },
                            "pending": {
                                "color": "text-bg-info text-white",
                                "text": "En attente d'encodage"
                            },
                            "processing": {
                                "color": "text-bg-warning",
                                "text": "Encodage en cours"
                            },
                            "failed": {
                                "color": "text-bg-danger",
                                "text": "Échec de l'encodage"
                            },
                            "done": {
                                "color": "text-bg-success",
                                "text": "Encodée et disponible"
                            }
                        }
                    },
                    "actions": ""
                }
            },
            "add": {
                "title": "Créer un nouvel épisode",
                "steps": {
                    "information": "Informations sur l'épisode",
                    "content": "Détails du contenu de l'épisode"
                },
                "fields": {
                    "title": {
                        "label": "Titre de l'épisode"
                    },
                    "description": {
                        "label": "Description de la vidéo (usage interne)"
                    },
                    "content": {
                        "label": "Contenu"
                    },
                    "htmlContent": {
                        "label": "Contenu de votre masterclass"
                    },
                    "episodeNumber": {
                        "label": "Numéro de l'épisode"
                    },
                    "status": {
                        "label": "Statut"
                    },
                    "type": {
                        "label": "Type de contenu"
                    },
                    "videoStatus": {
                        "processing": "La vidéo est en cours de traitement",
                        "pending": "La vidéo est en attente",
                        "failed": "La vidéo a échoué"
                    },
                    "cover": {
                        "label": "Image de couverture",
                        "placeholder": "Cliquez pour sélectionner ou faites glisser un fichier ici..."
                    },
                    "file": {
                        "label": "Fichier",
                        "current": "Vidéo actuelle",
                        "video": "Vidéo de votre masterclass",
                    },
                    "isPinned": {
                        "label": "Est épinglée"
                    },
                    "isPaid": {
                        "label": "Est payante"
                    },
                    "daysBeforeRelease": {
                        "label": "Jours avant la publication"
                    },
                    "price": {
                        "label": "Prix"
                    },
                    "rejectedReason": {
                        "label": "Raison du rejet"
                    }
                }
            },
            "edit": {
                "title": "Modifier l'épisode"
            }
        }
    }
}

export default masterclass