const auth = {
    "global": {
        "backLogin": "Volver a iniciar sesión",
        "logout": "Cerrar sesión",
        "login": "Iniciar sesión",
        "loggedInAs": "Conectado como",
        "notYou": "¿No eres tú?"
    },
    "guest": {
        "blocked": {
            "title": {
                "in-review": "Tu cuenta está bajo revisión",
                "banned": "Tu cuenta está bajo revisión"
            },
            "content": {
                "in-review": "Por favor, contacta a tu gestor de cuenta por correo electrónico en contact{'@'}ikonstar.com o por teléfono al +44 7720 601318 de lunes a viernes, de 9:30 a.m. a 6:00 p.m.",
                "banned": "Por favor, contacta a tu gestor de cuenta por correo electrónico en contact{'@'}ikonstar.com o por teléfono al +44 7720 601318 de lunes a viernes, de 9:30 a.m. a 6:00 p.m."
            }
        }
    },
    "creator": {},
    "form": {
        "submitButton": {
            "label": "Enviar"
        }
    },
    "login": {
        switch: {
            "login": "¿Ya tienes una cuenta? Inicia sesión",
            "register": "¿Eres nuevo en Ikonstar? ¡Regístrate ahora!"
        },
        "guest": {
            "title": "Iniciar sesión en tu cuenta",
            "about": "Crea una relación única con tu comunidad",
            "become": "CONVIERTE EN UN SOCIO DE IKONSTAR",
            "lostPassword": "¿Olvidaste tu contraseña?"
        },
        "form": {
            "email": {
                "label": "Dirección de correo electrónico"
            },
            "password": {
                "label": "Contraseña"
            },
            "newPassword": {
                "label": "Nueva contraseña"
            },
            "matchPassword": {
                "label": "Confirmar la nueva contraseña"
            },
            "loginButton": {
                "label": "Iniciar sesión"
            }
        }
    },
    "forgot": {
        "guest": {
            "title": "Recuperación de contraseña"
        },
        "message": {
            "success": "¡Se ha enviado un código único a tu correo electrónico!"
        },
        "form": {}
    },
    "code": {
        "guest": {
            "title": "Ingresa tu código de confirmación",
            "newCode": "No he recibido el código"
        },
        "form": {
            "code": {
                "label": "Código",
                "placeholder": "Ingresa el código"
            }
        }
    },
    "reset": {
        "guest": {
            "title": "Restablecer tu contraseña"
        },
        "form": {
            "newPassword": {
                "label": "Nueva contraseña"
            },
            "passwordMatch": {
                "label": "Confirmar la nueva contraseña",
                "notMatchPassword": "Las contraseñas deben coincidir"
            }
        },
        "message": {
            "success": "¡Tu contraseña ha sido actualizada!"
        }
    },
    "accountVerification": {
        "guest": {
            "title": "Verificación de cuenta",
            "notVerified": "Tu correo electrónico no está verificado, haz clic para obtener el código de verificación"
        },
        "form": {
            "getTheCodeButton": {
                "label": "Obtener el código"
            }
        },
        "message": {
            "codeSent": "Se ha enviado un código, revisa tu correo electrónico!",
            "verified": "¡Tu correo electrónico está validado!",
            "error": {
                "error422": "¡El código no es válido!",
                "error425": "Demasiado pronto, ya recibiste un correo electrónico, intenta nuevamente en 5 minutos!"
            }
        }
    },
    "creatorSelect": {
        "user": {
            "title": "Selecciona tu Ikonstar",
            "subscribedToSeveralIkon": "¡Te has suscrito a varios Ikon!",
            "selectIkon": "Selecciona el Ikon que deseas ver",
            "message": {
                "changeSuccess": "Ahora estás viendo a {name}"
            }
        }
    },
    "register": {
        "guest": {
            "title": "Crear una cuenta"
        },
        "form": {
            "name": {
                "label": "Mi nombre"
            },
            "username": {
                "label": "Mi nombre de usuario",
                "matchError": "El nombre de usuario debe contener solo letras, números y guiones"
            },
            "email": {
                "label": "Mi correo electrónico"
            },
            "password": {
                "label": "Mi contraseña",
                "matchError": "La contraseña debe contener al menos 1 letra mayúscula, 1 letra minúscula, 1 número"
            },
            "confirmPassword": {
                "label": "Confirmar contraseña",
                "matchError": "Las contraseñas no coinciden"
            },
            "registerButton": {
                "label": "Registrarse"
            }
        },
        "message": {
            "success": "Tu cuenta se ha creado correctamente"
        }
    },
    "createFirstPassword": {
        "global": {
            "title": "Crea tu primera contraseña",
            "invalidLink": "¡Tu enlace ya no es válido!",
            "loading": "Cargando...",
            "incorrectLink": "Tu enlace parece incorrecto. Ponte en contacto con tu gestor de cuenta si crees que esto es un error."
        },
        "message": {
            "success": "Tu contraseña se ha creado correctamente"
        }
    }
}


export default auth