const progress = {
    "global": {},
    "user": {
        "title": "Ma progression",
        "titleRange": {
            "day": "Aujourd'hui",
            "week": "Semaine",
            "month": "Mois",
            "year": "Année",
            "total": "Total"
        },
        "yourActivity": "Votre activité en",
        "days": "jours",
        "yourToday": "Votre activité",
        "categories": {
            "title": "Vos données par catégories"
        },
        "bodyParts": {
            "title": "Vos données par parties du corps"
        },
        "buttonCollapse": {
            "more": "+ voir plus",
            "less": "- voir moins"
        },
        "progressBarChart": {
            "title": "Objectif d'exercice aujourd'hui",
            "timeSpent": "Temps passé",
            "caloriesBurnt": "Calories brûlées",
            "fatBurnt": "Graisse brûlée",
            "noEvents": "basé sur vos événements du calendrier"
        },
        "barChart": {
            "titleRange": {
                "week": "Période de la semaine",
                "month": "Période du mois",
                "year": "Période de l'année",
                "total": "Période totale"
            },
            "timeSpent": "Temps passé",
            "caloriesBurnt": "Calories brûlées",
            "fatBurnt": "Graisse brûlée"
        }
    },
    "creator": {},
    "guest": {},
    "form": {}
}


export default progress