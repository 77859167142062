const favorite = {
    "user": {
        "myFavorites": "Mes Favoris",
        "likedWorkouts": "Entraînements",
        "likedMasterclasses": "Masterclasses",
        "likedPrograms": "Programmes",
        "likedPosts": "Publications",
        "quicklyFindYourLikedWorkoutsHere": "Trouvez rapidement vos entraînements aimés ici",
        "quicklyFindYourLikedMasterclassesHere": "Trouvez rapidement vos masterclasses aimées ici",
        "quicklyFindYourLikedProgramsHere": "Trouvez rapidement vos programmes aimés ici",
        "quicklyFindYourLikedPostsHere": "Trouvez rapidement vos publications aimées ici",
        "nothingLikedYet": "Rien n'a encore été aimé",
        "browseMasterclasses": "Parcourir les Masterclasses",
        "browseWorkouts": "Parcourir les Entraînements",
        "browsePrograms": "Parcourir les Programmes",
        "browsePosts": "Parcourir les Publications"
    }
}


export default favorite