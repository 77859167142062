const auth = {
    "global": {
        "backLogin": "Retour à la connexion",
        "logout": "Déconnexion",
        "login": "Connexion",
        "loggedInAs": "Connecté en tant que",
        "notYou": "Pas vous ?"
    },
    "guest": {
        "blocked": {
            "title": {
                "in-review": "Votre compte est en cours d'examination",
                "banned": "Votre compte est en cours d'examination",
            },
            "content": {
                "in-review": "Veuillez contacter votre gestionnaire de compte par email à contact{'@'}ikonstar.com ou par téléphone au +44 7720 601318 du lundi au vendredi, de 9h30 à 18h00",
                "banned": "Veuillez contacter votre gestionnaire de compte par email à contact{'@'}ikonstar.com ou par téléphone au +44 7720 601318 du lundi au vendredi, de 9h30 à 18h00",
            },
        },
    },
    "creator": {},
    "form": {
        "submitButton": {
            "label": "Soumettre"
        }
    },
    "login": {
        switch: {
            "login": "Vous avez déjà un compte ? Connectez-vous",
            "register": "Nouveau sur Ikonstar ? Inscrivez-vous maintenant !"
        },
        "guest": {
            "title": "Connexion à votre compte",
            "about": "Créez une relation unique avec votre communauté",
            "become": "DEVENEZ PARTENAIRE IKONSTAR",
            "lostPassword": "Mot de passe oublié ?"
        },
        "form": {
            "email": {
                "label": "Adresse e-mail"
            },
            "password": {
                "label": "Mot de passe"
            },
            "newPassword": {
                "label": "Nouveau mot de passe"
            },
            "matchPassword": {
                "label": "Confirmez le nouveau mot de passe"
            },
            "loginButton": {
                "label": "Connexion"
            }
        }
    },
    "forgot": {
        "guest": {
            "title": "Récupération de mot de passe"
        },
        "message": {
            "success": "Un code à usage unique a été envoyé à votre adresse e-mail !"
        },
        "form": {}
    },
    "code": {
        "guest": {
            "title": "Saisissez votre code de confirmation",
            "newCode": "Je n'ai pas reçu le code"
        },
        "form": {
            "code": {
                "label": "Code",
                "placeholder": "Saisissez le code"
            }
        }
    },
    "reset": {
        "guest": {
            "title": "Réinitialisez votre mot de passe"
        },
        "form": {
            "newPassword": {
                "label": "Nouveau mot de passe"
            },
            "passwordMatch": {
                "label": "Confirmez le nouveau mot de passe",
                "notMatchPassword": "Les mots de passe doivent correspondre"
            }
        },
        "message": {
            "success": "Votre mot de passe a été mis à jour !"
        }
    },
    "accountVerification": {
        "guest": {
            "title": "Vérification du compte",
            "notVerified": "Votre adresse e-mail n'est pas vérifiée, cliquez pour obtenir le code de vérification"
        },
        "form": {
            "getTheCodeButton": {
                "label": "Obtenir le code"
            }
        },
        "message": {
            "codeSent": "Un code a été envoyé, vérifiez votre e-mail !",
            "verified": "Votre adresse e-mail est validée !",
            "error": {
                "error422": "Le code est invalide !",
                "error425": "Trop tôt, vous avez déjà reçu un e-mail, réessayez dans 5 minutes !"
            }
        }
    },
    "creatorSelect": {
        "user": {
            "title": "Sélectionnez votre Ikonstar",
            "subscribedToSeveralIkon": "Vous êtes abonné à plusieurs Ikon !",
            "selectIkon": "Sélectionnez l'Ikon que vous souhaitez voir",
            "message": {
                "changeSuccess": "Vous visualisez maintenant {name}"
            }
        }
    },
    "register": {
        "guest": {
            "title": "Créez un compte"
        },
        "form": {
            "name": {
                "label": "Mon nom"
            },
            "username": {
                "label": "Mon nom d'utilisateur",
                "matchError": "Le nom d'utilisateur doit contenir uniquement des lettres, des chiffres et des tirets"
            },
            "email": {
                "label": "Mon adresse e-mail"
            },
            "password": {
                "label": "Mon mot de passe",
                "matchError": "Le mot de passe doit contenir au moins 1 lettre majuscule, 1 lettre minuscule, 1 chiffre"
            },
            "confirmPassword": {
                "label": "Confirmer le mot de passe",
                "matchError": "Les mots de passe ne correspondent pas"
            },
            "registerButton": {
                "label": "S'inscrire"
            }
        },
        "message": {
            "success": "Votre compte a été créé avec succès"
        }
    },
    "createFirstPassword": {
        "global": {
            "title": "Créez votre premier mot de passe",
            "invalidLink": "Votre lien n'est plus valide !",
            "loading": "Chargement...",
            "incorrectLink": "Votre lien semble incorrect. Contactez votre gestionnaire de compte si vous pensez qu'il s'agit d'une erreur."
        },
        "message": {
            "success": "Votre mot de passe a été créé avec succès"
        }
    }
}


export default auth