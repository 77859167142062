const admin = {
    "menu": {
        "dashboard": "Panel",
        "kols": "Creadores",
        "recipes": "Recetas",
        "members": "Miembros",
        "settings": "Configuración",
        "earlyAccesses": "Accesos Anticipados"
    },
    creators: {
        inviteLink: "Enlace de invitación del creador"
    }
}

export default admin