const auth = {
    global: {
        backLogin: `Back to login`,
        logout: `Logout`,
        login: `Login`,
        loggedInAs: `Logged in as`,
        notYou: `Not you?`,
    },
    guest: {
        blocked: {
            title: {
                'in-review': 'Your account is under review',
                banned: 'Your account is under review',
            },
            content: {
                'in-review': "Please contact your account manager by email at contact{'@'}ikonstar.com or by telephone at +44 7720 601318 Monday to Friday, 9:30 a.m. to 6:00 p.m.",
                banned: "Please contact your account manager by email at contact{'@'}ikonstar.com or by telephone at +44 7720 601318 Monday to Friday, 9:30 a.m. to 6:00 p.m.",
            },
        },
    },
    creator: {},

    form: {
        submitButton: {
            label: 'Submit',
        },
    },

    login: {
        switch: {
            "login": "Already have an account ? Login instead",
            "register": "New to Ikonstar ? Register now!",
        },
        guest: {
            title: 'Login to your account',

            about: 'Create a unique relationship with your community',
            become: 'BECOME AN IKONSTAR PARTNER',
            lostPassword: `Forgot your password?`,
        },

        form: {
            email: {
                label: `Email address`,
            },
            password: {
                label: `Password`,
            },
            newPassword: {
                label: `New password`,
            },
            matchPassword: {
                label: `Confirm the new password`,
            },
            loginButton: {
                label: `Login`,
            },
        },
    },

    forgot: {
        guest: {
            title: 'Password recovery',
        },

        message: {
            success: 'A one time code has been sent to your email!',
        },

        form: {},
    },

    code: {
        guest: {
            title: 'Enter your confirmation code',

            newCode: `I didn't received the code`,
        },

        form: {
            code: {
                label: `Code`,
                placeholder: `Enter the code`,
            },
        },
    },

    reset: {
        guest: {
            title: 'Reset your password',
        },

        form: {
            newPassword: {
                label: `New password`,
            },
            passwordMatch: {
                label: `Confirm the new password`,
                notMatchPassword: `Password must match`,
            },
        },

        message: {
            success: 'Your password was updated!',
        },
    },

    accountVerification: {
        guest: {
            title: 'Account verification',

            notVerified: `Your email is not verified, click to get verification code`,
        },

        form: {
            getTheCodeButton: {
                label: `Get the code`,
            },
        },

        message: {
            codeSent: `A code was sent, check your email!`,
            verified: `Your email is validated!`,

            error: {
                error422: `The code is invalid!`,
                error425: `Too early, you already received an email, try again in 5 minutes!`,
            },
        },
    },

    creatorSelect: {
        user: {
            title: 'Select your Ikonstar',

            subscribedToSeveralIkon: `You subscribed to several Ikon!`,
            selectIkon: `Select the Ikon you want to view`,

            message: {
                changeSuccess: `You are now viewing {name}`,
            },
        },
    },

    register: {
        guest: {
            title: 'Create an account',
        },

        form: {
            name: {
                label: `My name`,
            },
            username: {
                label: `My username`,

                matchError: `Username must contain only letters, numbers and dashes`,
            },
            email: {
                label: `My email`,
            },
            password: {
                label: `My password`,

                matchError: `Password must contain at least1 uppercase letter, 1 lowercase letter, 1 number`,
            },
            confirmPassword: {
                label: `Confirm password`,

                matchError: `Password not match`,
            },
            registerButton: {
                label: `Register`,
            },
        },

        message: {
            success: `Your account has been created successfully`,
        },
    },

    createFirstPassword: {
        global: {
            title: 'Create your first password',

            invalidLink: `Your link is no longer valid!`,
            loading: `Loading...`,

            incorrectLink: `Your link looks incorrect. Contact your account manager if you think this is an error.`,
        },

        message: {
            success: `Your password has been created successfully`,
        },
    },
}

export default auth