const progress ={
    "global": {},
    "user": {
        "title": "Mi Progreso",
        "titleRange": {
            "day": "Hoy",
            "week": "Semana",
            "month": "Mes",
            "year": "Año",
            "total": "Total"
        },
        "yourActivity": "Tu actividad en",
        "days": "días",
        "yourToday": "Tu actividad de hoy",
        "categories": {
            "title": "Tus datos por categoría"
        },
        "bodyParts": {
            "title": "Tus datos por partes del cuerpo"
        },
        "buttonCollapse": {
            "more": "+ ver más",
            "less": "- ver menos"
        },
        "progressBarChart": {
            "title": "Objetivo de ejercicio de hoy",
            "timeSpent": "Tiempo invertido",
            "caloriesBurnt": "Calorías quemadas",
            "fatBurnt": "Grasa quemada",
            "noEvents": "basado en tus eventos de calendario"
        },
        "barChart": {
            "titleRange": {
                "week": "Tiempo de la semana",
                "month": "Tiempo del mes",
                "year": "Tiempo del año",
                "total": "Tiempo total"
            },
            "timeSpent": "Tiempo invertido",
            "caloriesBurnt": "Calorías quemadas",
            "fatBurnt": "Grasa quemada"
        }
    },
    "creator": {},
    "guest": {},
    "form": {}
}


export default progress