const media = {
    "global": {
        "title": "Médias"
    },
    "user": {},
    "superAdmin": {},
    "creator": {
        "myMedias": "Mes médias",
        "type": {
            "image": "Image",
            "video": "Vidéo",
            "unknown": "Inconnu"
        },
        "searchPlaceholder": "Rechercher des médias",
        "noMediaFound": "Il semble que vous n'ayez pas encore de média."
    },
    "guest": {},
    "form": {
        "add": {
            "uploadNewMedia": "Télécharger de nouveaux médias",
            "upload": "Télécharger des médias",
            "backToMedias": "Retour aux médias",
            "uploading": "Téléchargement des médias en cours...",
            "noFileUploaded": "Veuillez télécharger au moins un média",
            "uploadSuccess": "Vos médias ont été téléchargés avec succès",
            "uploadFailed": "Une erreur s'est produite lors du téléchargement de vos médias"
        },
        "edit": {
            "editMedia": "Modifier le média",
            "title": "Titre",
            "description": "Description",
            "save": "Enregistrer",
            "delete": "Supprimer",
            "editMediaSuccess": "Votre média a été mis à jour avec succès",
            "editMediaError": "Une erreur s'est produite lors de la mise à jour de votre média",
            "deleteMediaConfirmation": "Êtes-vous sûr de vouloir supprimer ce média ?",
            "deleteMediaCancelText": "Annuler",
            "deleteMediaConfirmText": "Oui, supprimer",
            "deleteMediaSuccess": "Votre média a été supprimé avec succès",
            "deleteMediaError": "Une erreur s'est produite lors de la suppression de votre média",
            "videoStatus": {
                "processing": "La vidéo est en cours de traitement",
                "pending": "La vidéo est en attente",
                "failed": "La vidéo a échoué"
            }
        }
    }
}


export default media