const page = {
    global: {
        title: 'My Page',
    },
    user: {},
    superAdmin: {},
    creator: {
        subscribersCount: 'Subscribers',
        followersCount: 'Followers',
        latestWorkout: 'Latest workout',
        myWorkouts: 'View my workouts',
        addWorkout: 'Create a workout',
        latestMasterclass: 'Latest masterclass',
        myMasterclass: 'View my masterclass',
        addMasterclass: 'Create a masterclass',
    },
    guest: {},
    form: {},
}

export default page