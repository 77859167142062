const liveEvents = {
    "title": "Eventos en Vivo",
    "empty": "Parece que aún no tienes ningún evento planeado",
    emptyUser: "Parece que aún no hay eventos planeados",
    "sold": "Boleto vendido | Boletos vendidos",
    "status": {
        "draft": "Borrador",
        "active": "Activo",
        "in-progress": 'En curso',
        finished: 'Terminado',
    },
    "startsIn": "El evento comienza en",
    "countdown": "{days} @:globals.countdown.days , {hours} @:globals.countdown.hours , {minutes} @:globals.countdown.minutes , {seconds} @:globals.countdown.seconds",
    active: "El evento ha comenzado",
    soon: "en un momento",
    "finished": "¡El evento ha terminado!",
    "thanks": "¡Gracias por comprar un boleto para este evento!",
    backToLive: {
        title: '¡Por favor, verifique que no está transmitiendo desde otra pestaña!',
        confirm: 'No lo estoy, comienza a transmitir',
        cancel: 'No importa, ya lo encontré',
    },
    cantStart: "Parece que no podemos iniciar una transmisión en este momento. Si el error persiste, por favor contáctenos.",

    "viewLive": "IR A LA TRANSMISIÓN EN VIVO",
    nextEvent: {
        helper: 'PRÓXIMO EVENTO',
        start: 'COMIENZO',
        inProgress: 'EN PROCESO',
        goBackLive: 'Volver al directo',
    },
    "payment": {
        "title": "Tu información de pago",
        "success": "¡El pago fue aceptado!",
        "error": "No se puede comprar el boleto para el evento en este momento",
        "validate": "Comprar mi boleto por {price}",
        "secure": "Pago 100% seguro",
        "endingWith": "terminando con",
        convertedCurrency: "Los precios mostrados en {currencySelected} son solo de referencia. Se le cobrará en {currencyEvent} por {amount}.",
    },
    "actions": {
        "create": "Crear Evento en Vivo",
        "edit": "Editar Evento en Vivo",
        "delete": "¿Estás seguro de que deseas eliminar este evento?"
    },
    "buttons": {
        "back": "Eventos en vivo",
        "join": "Unirse al evento"
    },
    "form": {
        "cover": {
            "label": "Imagen de portada del evento en vivo",
            "error": "Por favor, sube una imagen de portada"
        },
        "video": {
            "label": "Video teaser del evento en vivo"
        },
        "title": {
            "label": "Título"
        },
        "content": {
            "label": "Contenido"
        },
        "date": {
            "label": "Fecha de inicio del evento",
            short: "Fecha"
        },
        "price": {
            "label": "Precio de entrada del evento",
            short: "Precio de entrada"
        },
        "duration": {
            "label": "Duración prevista del evento (en minutos)",
            short: "Duración prevista"
        }
    },
    "user": {
        "empty": "Parece que {creator} aún no tiene ningún evento planeado",
        "actions": {
            "view": "Ver Evento",
            "buy": "Comprar un boleto"
        }
    }
}
export default liveEvents