const messenger = {
    "global": {
        "title": "Mis mensajes",
        "plan": "Plan",
        another: 'Elige otra conversación',
        "sendSomething": "¡Envía algo a {name}!",
        "typeSomething": "Escribe a {name}!",
        "maxCharacterCount": "Has alcanzado el máximo de {maxCharacterCount} caracteres por mensaje.",
        "notification": {
            "add": {
                "success": "Tu mensaje se envió con éxito.",
                "error": "Tu mensaje no se envió. Por favor, inténtalo de nuevo.",
            },
            "confirm": {
                "success": "La videollamada privada se confirmó con éxito.",
                "error": "La videollamada privada no pudo confirmarse. Por favor, inténtalo de nuevo.",
            },
        },
        "modal": {
            "confirmSendToAll": {
                "title": "¿Estás seguro de que quieres enviar un mensaje a todos los suscriptores?",
                "confirm": "Sí, enviarlo",
                "cancel": "Cancelar",
            },
            "confirmCall": {
                "title": "¿Estás seguro de que quieres confirmar?",
                "confirm": "Sí, confirmar",
                "cancel": "Cancelar",
            },
        },
    },
    "user": {
        "title": "Mis mensajes con {name}",
        "privateCall": {
            "title": "Relación privada",
            "bookTitle": "Reservar una videollamada",
            "directCall": "Llamada directa",
            "yourChoice": "Selecciona un intervalo de tiempo y una fecha",
            "selectTimeSlot": "Selecciona un intervalo de tiempo",
            "selectDay": "Selecciona un día",
            "selectHour": "Selecciona un rango horario",
            "pickRange": "Elige un rango",
            "selected": {
                "title": "Tu intervalo de tiempo seleccionado",
                "callWith": "Videollamada privada con",
                "slot": "Duración de la llamada {number} minutos",
                "reminder": "Recordatorio {number} minutos antes",
                "minutes": "minutos",
            },
            "form": {
                "timeSlot": "Intervalo de tiempo",
                "day": "Día",
                "hour": "Rango horario",
                "from": "Desde",
                "to": "hasta",
                "editModal": {
                    "title": "Modificar videollamada privada",
                    "submit": "Guardar",
                    "success": "La videollamada privada se actualizó con éxito.",
                    "error": "La videollamada privada no pudo actualizarse. Por favor, inténtalo de nuevo.",
                },
            },
            "payment": {
                "breadcrumbs": "Pago de llamada",
                "title": "Tu información de pago",
                "success": "El pago se ha aceptado.",
                "error": "La videollamada privada no se pudo comprar en este momento.",
                "buttonBackSlot": "Volver a la selección de intervalo",
                "validate": "Validar mi pago",
                "secure": "Pago 100% seguro",
                "endingWith": "terminando en",
            },
            "message": {
                "description": "Reservé una sesión de {time_slot} minutos contigo en los siguientes horarios. ¿Te viene bien?",
                "descriptionChanged": "Hola, actualicé la fecha de la videollamada privada de {time_slot} minutos.",
                "descriptionConfirmed": "Sí, estoy disponible. Nos vemos.",
                "modify": "Modificar",
                "available": "Estoy disponible",
                "yourAppointment": "TU CITA",
                "confirmed": "La videollamada privada se confirmó con éxito.",
                "errorConfirmed": "La videollamada privada no pudo confirmarse. Por favor, inténtalo de nuevo.",
                "confirmTitle": "¿Estás seguro de que quieres confirmar?",
                "confirmButton": "Sí, confirmar",
                "cancelButton": "Cancelar",
            },
        },
        "buttons": {
            "bookCall": "Reservar una llamada, desde",
            "back": "Volver al chat",
            "payment": "Tarjetas de Crédito y Débito",
        },
    },
    "creator": {
        "fileAdd": "Agregar una imagen",
        "buttons": {
            "sendToAll": "Mensaje a todos",
        },
        "search": {
            "placeholder": "Buscar por nombre de suscriptor",
        },
        "form": {
            "title": "Enviar un mensaje a tus suscriptores",
        },
        "video": {
            "title": "Videollamada",
            "selectSource": "Seleccionar fuente",
            "audioSource": "Fuente de audio",
            "videoSource": "Fuente de video",
            "waiting": "Por favor espera, el anfitrión de la reunión te dejará entrar pronto",
            "errors": {
                "camera": "Necesitas una cámara para unirte a una llamada",
                "microphone": "Necesitas un micrófono para unirte a una llamada",
                "speaker": "Necesitas un altavoz para unirte a una llamada",
            },
        },
    },
    "guest": {
        unlockChat: 'Desbloquear chat en vivo',
    },
    "form": {
        "list": {},
        "add": {
            "fields": {
                "message": "Mensaje",
            },
            "buttons": {
                "submit": "Enviar",
            },
        },
        "edit": {},
    }
}


export default messenger