const recipe = {
    global: {
        likes: 'likes',
        filter: {
            category: 'Filtrer par catégorie',
            status: 'Filtrer par statut',
        },
        search: {
            placeholder: 'Rechercher...',
        },
        notification: {
            add: {
                success: 'La recette a été ajoutée avec succès !',
                error: "La recette n'a pas été ajoutée, veuillez réessayer.",
            },
            update: {
                success: 'La recette a été mise à jour avec succès !',
                error: "La recette n'a pas été mise à jour, veuillez réessayer.",
            },
            delete: {
                success: 'La recette a été supprimée avec succès !',
                error: "La recette n'a pas été supprimée, veuillez réessayer.",
            },
            disable: {
                success: 'La recette a été désactivée avec succès !',
                error: "La recette n'a pas été désactivée, veuillez réessayer.",
            },
        },
        modal: {
            confirmDelete: {
                title: 'Êtes-vous sûr ?<br>Vous ne pourrez pas revenir en arrière !',
                confirm: 'Supprimer',
                cancel: 'Annuler',
            },
            confirmDisable: {
                title: 'Êtes-vous sûr de vouloir désactiver la recette ?',
                confirm: 'Désactiver',
                cancel: 'Annuler',
            },
        },
    },
    user: {
        recipe: 'Recette',
        buttons: {
            addToPlanning: 'Ajouter au planning',
            viewVideo: 'Voir la vidéo',
        },
    },
    superAdmin: {
        buttons: {
            add: 'Ajouter une recette',
            resetFilter: 'Réinitialiser les filtres',
            back: 'Retour',
        },
    },
    creator: {
        title: 'Mes recettes',
        subTitle: 'Nos recettes',
        globalTitle: 'Recettes Ikonstar',
        globalSubtitle: 'Nos recettes',
        privateTitle: 'Vos recettes',
        privateSubtitle: 'Vos recettes privées',
        empty: "Il semble que vous n'ayez aucune recette activée",
        emptySearch: "Il n'y a aucune recette avec ces filtres",
        setRecipe: 'Configurez vos recettes maintenant',
        search: {
            placeholder: 'Rechercher une recette spécifique',
        },
        buttons: {
            add: 'Ajouter une recette',
            activateAll: 'Activer toutes les recettes',
            setConfig: 'Configurer mes recettes',
            selectRecipe: 'Sélectionner une recette',
            removeSelectedRecipe: 'Supprimer la sélection',
            activateRecipe: 'Activer la recette',
            addToPlanning: 'Ajouter au planning',
            disableRecipe: 'Désactiver la recette',
        },
        filter: {
            filter: 'Filtrer',
            category: 'Catégorie',
            physicalGoal: 'Objectif physique',
        },
        selectRecipe: {
            title: 'Nos recettes',
            subtitle: 'Sélectionnez les recettes que vous souhaitez ajouter',
            empty: "Il n'y a aucune recette",
            youSelected: 'Vous avez sélectionné',
            recipe: 'recette',
            recipes: 'recettes',
        },
        viewRecipe: {
            title: 'Recette',
            label: 'Voir la recette',
            minutes: 'minute|minutes',
            person: 'personne|personnes',
            ingredients: 'Ingrédients',
            nutrition: 'Informations nutritionnelles',
            allergens: 'Allergènes',
            stepTitle: 'Suivez la recette étape par étape',
        },
        private: {
            title: 'Recettes privées',
            globalSubtitle: 'Vos recettes',
            addRecipe: 'Ajouter une recette',
            edit: 'Modifier',
            currentFiles: 'Vos fichiers actuels',

            empty: "Il semble que vous n'ayez encore aucune recette",
            createFirstButton: 'Créer ma première recette',
            createButton: 'Créer une recette',
            foundStarted: {
                title: 'Nous avons trouvé une recette en cours, souhaitez-vous continuer à la créer?',
                yes: 'Oui, continuer',
                no: 'Non, en commencer une nouvelle',
            },
            delete: {
                title: 'Êtes-vous sûr de vouloir supprimer cette recette ? Vous ne pourrez pas la récupérer par la suite.',
                yes: 'Oui, supprimer',
                no: 'Non, la conserver',
                success: 'Votre recette a été supprimée avec succès !',
                error: "Votre recette n'a pas été supprimée ! Réessayez.",
            },
            'delete-video': {
                title: 'Êtes-vous sûr de vouloir supprimer cette vidéo ? Vous ne pourrez pas la récupérer par la suite.',
                yes: 'Oui, supprimer',
                no: 'Non, la conserver',
            },
            'delete-photo': {
                title: 'Êtes-vous sûr de vouloir supprimer cette image ? Vous ne pourrez pas la récupérer par la suite.',
                yes: 'Oui, supprimer',
                no: 'Non, la conserver',
            },
            form: {
                chooseOne: 'Vous devez en choisir une',
                addTitle: 'Créer une recette',
                editTitle: 'Modifier une recette',
                addSucces: 'Votre recette a été créée avec succès !',
                updateSucces: 'Votre recette a été mise à jour avec succès !',
                steps: {
                    1: {
                        title: "Bienvenue sur le formulaire d'ajout de recette. Suivez les différentes étapes, saisissez les informations nécessaires à votre recette et prévisualisez-la en ligne.",
                        subtitle: '',
                    },
                    2: {
                        title: 'Informations sur la recette',
                    },
                    3: {
                        title: 'Contenu de la recette',
                    },
                    4: {
                        title: 'Prévisualisation de la recette et soumission',
                    },
                },
                information: 'Information',
                title: 'Titre de la recette',
                kcal: 'kCal (par portion)',
                description: 'Courte description de la recette',
                category: 'Choisir une catégorie',
                portions: 'Portions',
                portionsUnit: 'Personnes, pièces, litres...',
                portionsHelper: 'Exemple : pour 4 personnes, pour 10 pièces, pour 1 litre...',
                prepTime: 'Temps de préparation',
                cookingTime: 'Temps de cuisson',
                restingTime: 'Temps de repos',
                cookingType: {
                    title: 'Choisir le type de cuisson',
                    oven: 'Four',
                    hob: 'Plaque de cuisson',
                    'no-cooking': 'Pas de cuisson',
                    other: 'Autre',
                },
                difficulty: 'Choisir la difficulté',
                cost: {
                    title: 'Choisir le coût de la recette',
                    cheap: 'Bon marché',
                    average: 'Moyen',
                    expensive: 'Cher',
                },
                files: 'Ajouter des photos ou des vidéos. {nbFile} fichiers maximum',
                order: "Vous pouvez changer l'ordre des étapes en glissant les cases vers le haut ou vers le bas",
                buttons: {
                    add: 'Ajouter',
                    edit: 'Sauvegarder',
                    cancel: 'Annuler',
                },
                ingredients: {
                    title: 'Ingrédients',
                    add: 'Ajouter un ingrédient',
                    order: "Vous pouvez changer l'ordre en faisant glisser les cases vers le haut ou vers le bas",
                    quantity: 'Quantité',
                    unit: 'Unité',
                    unitHelper: "Exemple : 'g', 'c. à soupe', 'pincée'",
                    name: 'Nom',
                    complement: 'Informations supplémentaires',
                    complementHelper: "Exemple : 'à température ambiante', 'dénoyauté', 'au goût'",
                },
                recipeSteps: {
                    title: 'Étapes de la recette',
                    add: 'Ajouter une étape',
                    step: 'Etape',
                    order: "Vous pouvez changer l'ordre des étapes en glissant les cases",
                    stepTitle: 'Titre',
                    content: 'Contenu',
                },
                allergens: {
                    title: 'Allergènes connus',
                    add: 'Ajouter un allergène',
                    name: 'Nom',
                },
                nutritions: {
                    title: 'Informations nutritionnelles (par portion)',
                    add: 'Information nutritionnelle',
                    quantity: 'Valeur',
                    unit: 'Unité',
                    unitHelper: "Typiquement 'g'",
                    name: 'Nom',
                },
            },
        },
    },
    guest: {},
    form: {
        list: {
            name: 'Nom de la recette',
            description: 'Description',
            status: 'Statut',
            createdAt: 'Créé le',
            edit: 'Modifier',
            delete: 'Supprimer',
        },
        add: {
            title: 'Créer une nouvelle recette',
            fields: {
                title: {
                    label: 'Titre de la recette',
                    placeholder: 'Saisir le titre de la recette',
                },
                description: {
                    label: 'Description de la recette',
                    placeholder: 'Saisir la description de la recette',
                },
                category: {
                    label: 'Catégorie',
                    placeholder: 'Sélectionner une catégorie',
                },
                type: {
                    label: 'Type',
                    placeholder: 'Sélectionner un type',
                },
                kcal: {
                    label: 'Kcal',
                    placeholder: 'Saisir un nombre',
                },
                preparationTimeMinutes: {
                    label: 'Temps de préparation en minutes',
                    placeholder: 'Saisir un nombre de minutes',
                },
                totalPerson: {
                    label: 'Nombre de personnes',
                    placeholder: 'Saisir le nombre de personnes',
                },
                level: {
                    label: 'Niveau',
                    placeholder: 'Sélectionner un niveau',
                },
                physicalGoal: {
                    label: 'Objectif physique',
                    placeholder: 'Sélectionner un objectif physique',
                },
                photos: {
                    label: 'Photos',
                    cover: 'Image de couverture',
                    placeholder: 'Cliquez pour sélectionner ou glissez un fichier ici...',
                },
                video: {
                    label: 'Vidéo',
                    current: 'Vidéo actuelle',
                },
                status: {
                    label: 'Statut',
                    placeholder: 'Sélectionner un statut',
                    draft: 'Brouillon',
                    active: 'Actif',
                },
                allergens: {
                    label: 'Allergènes',
                    placeholder: 'Saisir un allergène',
                },
                ingredients: {
                    label: 'Ingrédients',
                    name: "Nom de l'ingrédient",
                    amount: 'Quantité',
                    unit: 'Unité',
                },
                nutrition: {
                    label: 'Informations nutritionnelles',
                    name: 'Nom de la nutrition',
                    amount: 'Quantité',
                    unit: 'Unité',
                },
                steps: {
                    label: 'Étapes',
                    title: "Titre de l'étape",
                    content: "Contenu de l'étape",
                },
                videoStatus: {
                    processing: 'La vidéo est en cours de traitement',
                    pending: 'La vidéo est en attente',
                    failed: 'La vidéo a échoué',
                    none: 'Aucune vidéo téléchargée',
                },
            },
            buttons: {
                addAllergen: 'Ajouter un allergène',
                addIngredient: 'Ajouter un ingrédient',
                addNutrition: 'Ajouter une information nutritionnelle',
                loading: 'Chargement...',
                next: 'Suivant',
                save: 'Enregistrer',
            },
        },
        edit: {
            title: 'Modifier une recette',
            buttons: {
                saveAllergens: 'Enregistrer les allergènes',
                saveIngredients: 'Enregistrer les ingrédients',
                saveNutrition: 'Enregistrer les informations nutritionnelles',
                saveSteps: 'Enregistrer les étapes',
            },
        },
    },
}

export default recipe