export default {
    v2: {
        "title": "Connect to Ikonstar",
        "navbar": {
            "creator": "I'm a content creator",
            "user": "I'm a Fan",
            "creators": "Our Creators",
            "podcasters": "Podcasters",
            "video_creators": "Video Creators",
            "musicians": "Musicians",
            "artists": "Artists",
            "game_developers": "Game devs",
            "sportCoaches": "Sport Coaches",
            "features": "Features",
            "academy": "Ikonstar Academy",
            "login": "Login",
            "start": "Get Started",
            "search": "Find a creator",
        },
        "section1": {
            "logo_alt": "Ikonstar",
            "headline": "Connect with your fans!",
            "subtitle": "Create moments of authentic happiness.",
            "description": "{0} allows you to share moments of joy and inspiration with those who support you the most. Engage directly with your audience, offer exclusive content, and create unforgettable memories together.",
            "descriptionStrong": "Ikonstar",
            "hero_banner": {
                "headline": "Offer exclusive content to your audience",
                "button_text": "Join us now"
            }
        },
        "section2": {
            "headline": "Bring Your Positive Attitude.",
            "subtitle": "Share your good mood with the world.",
            "description": "On {0}, let your positive energy and passion shine through. Share authentic and inspiring moments with your fans, and build a community that vibes with you every day.",
            "descriptionStrong": "Ikonstar",
            "hero_banner": {
                "headline": "Lives, videos, photos, messaging{0}...It's all here!",
                "button_text": "Broadcast now"
            }
        },
        "section3": {
            "headline": "Be Inspired.",
            "subtitle": "Reach new heights.",
            "description": "Be inspired by the testimonials and successes of creators who have found their place on {0}. Discover their journeys and how they use our platform to achieve their dreams and reach thousands of fans.",
            "descriptionStrong": "Ikonstar",
            "hero_banner": {
                "headline": "Masterclasses, Training, Podcasts, Recipes...{0}It's your turn!",
                "button_text": "Create your legend"
            }
        },
        "section4": {
            "headline": "Monetize Your Talent.",
            "subtitle": "Turn your passion into revenue.",
            "description": "With {0}, you can monetize your content and receive financial support from your fans. Offer subscriptions, exclusive content, and more, all while keeping control of your creations.",
            "descriptionStrong": "Ikonstar",
            "hero_banner": {
                "headline": "Generate consistent income,{0}Cash in your earnings automatically.",
                "button_text": "Start earning"
            }
        },
        "section5": {
            "headline": "Unleash Your Creative Spirit!",
            "subtitle": "Live the adventure of creation.",
            "description": "{0} gives you the freedom to create and share your adventures with your fans. Tap into your creativity without limits and engage your audience with unique and captivating content.",
            "descriptionStrong": "Ikonstar",
            "hero_banner": {
                "headline": "Discover a community of talents",
                "button_text": "Explore now"
            }
        },
        "section6": {
            "headline": "Engage Your Community",
            "subtitle": "Create strong and lasting bonds",
            "description": "Use our {0} interaction tools to communicate directly with your fans. Create posts, organize lives, and involve your community for richer and more personal exchanges.",
            "descriptionStrong": "Ikonstar",
            "hero_banner": {
                "headline": "Become an Ikonstar{0}Right Now!",
                "button_text": "Join us!"
            },
            "tools_button_text": "Discover the tools"
        }
    },

    user: {
        title: "Login to Ikonstar",
        navbar: {
            creators: "Our Creators",
            podcasters: "Podcasters",
            video_creators: "Video Creators",
            musicians: "Musicians",
            artists: "Artists",
            game_developers: "Game Developers",
            features: "Features",
            academy: "Ikonstar Academy",
            login: "LOGIN",
            start: "GET STARTED",
            search: "Find creators ..."
        },
        section1: {
            logo_alt: "Ikonstar",
            headline: "Become an Exclusive Fan",
            subtitle: "Support your favorite content creators",
            description: "{0} offers you the unique opportunity to get closer to the creators who inspire you. Enjoy exclusive access to their lives and unpublished content, as well as personalized interactions. Discover the exclusive content of your influencers.",
            descriptionStrong: "Ikonstar",
            hero_banner: {
                headline: "Discover the{0}exclusive content of your influencers",
                button_text: "Browse our creators"
            }
        },
        section2: {
            headline: "Backstage every day, 7 days a week",
            subtitle: "Access exclusive content from your stars and experience behind the scenes with them.",
            description: "Only {0} members can claim to be part of the inner circle of their favorite personality and share the best experiences with them daily. As an exclusive fan, you have access to your stars' stories, photos, videos, and much more... Access exclusive content: Video, Photo, Video calls, and more!",
            descriptionStrong: "Ikonstar",
            hero_banner: {
                headline: "Access exclusive content:{0}Video, Photo, Video calls, and more!",
                button_text: "Browse our creators"
            }
        },
        section3: {
            headline: "Break down all the barriers!",
            subtitle: "Engage directly with the personalities who inspire you. No barriers, no filters, no restrictions, they are there for you and you alone.",
            description: "Enjoy direct and personalized interactions with the creators you love. Whether through private messages, live chats, or exclusive LIVE events, {0} allows you to forge a strong and unique bond with them. Start a unique relationship today.",
            descriptionStrong: "Ikonstar",
            hero_banner: {
                headline: "Start a{0}unique relationship today",
                button_text: "Browse our creators"
            }
        },
        section4: {
            headline: "Experience a tailor-made experience",
            subtitle: "Videos or photos entirely designed according to your wishes for you or your loved ones",
            description: "Receive content specially designed for you. Share memorable experiences with the stars of {0}. Request personalized videos, exclusive messages, or photo sessions that bring you closer to your idols. Exchange, share, and personalize your moments with your idols.",
            descriptionStrong: "Ikonstar",
            hero_banner: {
                headline: "Exchange, share, and personalize{0}your moments with your idols.",
                button_text: "Browse our creators"
            }
        },
        section5: {
            headline: "All your passions gathered in one place",
            subtitle: "Fitness, sports, music, art, cooking, lifestyle, podcasts, they are all here and waiting for you.",
            description: "Whether you are passionate about vlogs, fitness, music, podcasts, or lifestyle, {0} brings together a wide range of talents. Explore diverse content and connect with creators who share your interests.",
            descriptionStrong: "Ikonstar",
            hero_banner: {
                headline: "Discover all our talents",
                button_text_2: "Explore now",
                button_text: "Browse our creators"
            }
        },
        section6: {
            headline: "Where fans meet their heroes",
            subtitle: "Subscribe to your favorite creators",
            description: "{0} is much more than just a platform; it's where the barriers between you and your favorite content creators disappear. Here, you can follow, interact, and get closer to your idols like never before. Whether by discovering exclusive content, participating in Lives, or receiving personalized messages, Ikonstar places you at the heart of the action.",
            descriptionStrong: "Ikonstar",
            hero_banner: {
                headline: "Ikonstar places you{0}at the heart of the action",
                button_text: "Browse our creators"
            },
            tools_button_text: "I JOIN IKONSTAR"
        }
    },

    hero: {
        titles: {
            white: 'Your talent is{br}{gold}',
            gold: 'worth money',
        },
        subtitles: {
            first: 'The best way for content creators to turn their{br}audience into {bold}',
            bold: 'recurring paying subscribers!'
        },
        button: "Become an Ikonstar",
        login: "Connection",
        welcome: "Welcome back {username}",
        myProfile: "My profile",
        user: {
            titles: {
                white: 'Connect with your{br}{gold}',
                gold: "favorite talent",
            },
            subtitles: {
                first: 'The best way to access exclusive content and {bold} with your favorite talent',
                bold: 'connect directly'
            },
        }
    },
    works: {
        title: 'How does it work?',
        step: 'Step {step}',
        titles: {
            1: 'Customise your page and subscription',
            2: 'Tell your followers that you now have a profile on Ikonstar',
            3: 'Be active and attentive to your subscribers'
        },
        contents: {
            1: {
                main: 'Consider what you can offer regularly and what will make your fans happy.{0}{1}',
                bold: {
                    1: 'No hassle!'
                }
            },
            2: {
                main: '{1} to notify your subscribers. IKONSTAR is a place where a {2} - those who want something more than just following you on social networks.',
                bold: {
                    1: 'Post in all your social networks',
                    2: 'special relationship appears between you and your most active fans'
                }
            },
            3: {
                main: 'Publish regularly on your Ikonstar space and {1}!',
                bold: {
                    1: 'promote it on your socials'
                }
            }
        }
    },
    features: {
        title: 'Features',
        tabs: {
            feed: {
                title: 'Private feed',
                content: 'Dive into a stream of exclusive content, designed specifically for subscribers.{0}Privileged access to your backstage, your thoughts and in your unique moments.'
            },
            masterclass: {
                title: 'Masterclass and workouts',
                content: 'Directly convey your story and your expertise. In-depth sessions offering valuable knowledge and tips for improving your influence game.'
            },
            calls: {
                title: 'Private video calls',
                content: 'Log in directly. An unrivaled opportunity interact face-to-face with your members, answer questions and transmit personalized advice.'
            },
            messages: {
                title: 'One-to-one messages',
                content: 'Be close to your community, give time to those who are paid subscribers'
            },
        }
    },
    money: {
        title: 'Show me the money',
        currency: '$',
        subs: {
            short: 'SUBS',
            estimate: 'ESTIMATED NUMBER OF SUBSCRIBERS',
            price: 'SUBSCRIPTION PRICE'
        },
        revenue: {
            title: "AT A SUBSCRIPTION COST{br}OF {price}, YOU'LL EARN",
            explain: "USD/month",
            disclaimer: "Before VAT and platform fees"
        }
    },
    footer: {
        white: 'Become an{br}{gold}',
        button: "Join Now",
        gold: 'Ikonstar{br}Now!',
    },
    seo: {
        title: "IKONSTAR - Become an Ikonstar today and turn your passion into success!",
        description: "Discover Ikonstar, the ultimate platform where your talent becomes a source of income.",
        tags: "monetize,creativity,sharing,exclusive,content,community,sports,fitness,music,podcasts,lifestyle,subscribers,revenue,Ikonstar,passion,success"
    },
}