const favorite = {
    "user": {
        "myFavorites": "Mis Favoritos",
        "likedWorkouts": "Entrenamientos",
        "likedMasterclasses": "Masterclasses",
        "likedPrograms": "Programas",
        "likedPosts": "Publicaciones",
        "quicklyFindYourLikedWorkoutsHere": "Encuentra rápidamente tus entrenamientos favoritos aquí",
        "quicklyFindYourLikedMasterclassesHere": "Encuentra rápidamente tus masterclasses favoritas aquí",
        "quicklyFindYourLikedProgramsHere": "Encuentra rápidamente tus programas favoritos aquí",
        "quicklyFindYourLikedPostsHere": "Encuentra rápidamente tus publicaciones favoritas aquí",
        "nothingLikedYet": "Aún no has marcado nada como favorito",
        "browseMasterclasses": "Explora Masterclasses",
        "browseWorkouts": "Explora Entrenamientos",
        "browsePrograms": "Explora Programas",
        "browsePosts": "Explora Publicaciones"
    }
}


export default favorite