const _dashboard = {
    global: {
        title: "Dashboard",
    },
    user: {
        helperCalendar: 'Manage your calendar',
        latestPostsTitle: 'Latest Posts',
        followMe: 'Follow me',
        // followHisSocials: 'Follow his socials',
        // followHerSocials: 'Follow her socials',
        // followHisHerSocials: 'Follow his / her socials',
        latestLiveEventTitle: 'Next Live Event',
        latestWorkoutTitle: 'Latest Workout',
        latestMasterclassTitle: 'Latest Masterclass',
        nothingPosted: 'Nothing posted yet',
        episodes: "Episode | Episodes",
        yourGoal: 'Your goal',
        ofTheDay: 'of the day',
        unfollow: {
            title: 'Are you sure you want to unfollow {creator}?',
            submit: 'Unfollow',
            cancel: 'Cancel',
        },
        shortcuts: {
            buttons: {
                call: 'Book a call',
                workout: 'Workouts',
                masterclass: 'Masterclasses',
                recipe: 'Recipes',
            },
        },
        calendar: {
            buttons: {
                workout: 'Schedule a workout',
                recipe: 'Add a recipe',
                privateVideoCall: 'Book a call',
                privateVideoCallSecond: 'Schedule a call',
            },
            events: {
                see: 'Show',
                nothing: 'No events found',
                privateVideoCall: 'Private video call with ',
            },
            day: {
                short: {
                    monday: "Mon.",
                    tuesday: "Tue.",
                    wednesday: "Wed.",
                    thursday: "Thu.",
                    friday: "Fri.",
                    saturday: "Sat.",
                    sunday: "Sun.",
                },
                long: {
                    monday: "Monday",
                    tuesday: "Tuesday",
                    wednesday: "Wednesday",
                    thursday: "Thursday",
                    friday: "Friday",
                    saturday: "Saturday",
                    sunday: "Sunday",
                }
            }
        },
    },
    superAdmin: {
        statistic: {
            creators: 'Total Creators',
            recipes: 'Total recipes',
            users: 'Total Users',
            lastAdded: 'Last added:',
        },
        creator: {
            title: 'Creation in progress',
            lastUpdate: 'Last modification:',
            continueEditing: 'Click to continue editing',
        },
        button: {
            new: '+ Create new',
            addKol: 'Add creator',
            addMember: 'Add member',
        },
        topFiveKols: 'Top 5 Kol\'s app',
        totalDownloaded: 'Total downloaded',
        topFiveRecipes: 'Top 5 popular recipes',
        thisMonth: 'This month',
    },
    creator: {
        mandatory: 'MANDATORY',

        lastTitle: 'Your latest data',
        detailReportButton: 'View detailed report',

        totalWorkouts: 'Total Workouts',
        addWorkout: 'Create a workout',
        topFiveWorkouts: 'Top 5 popular workouts',
        latestFiveWorkouts: '5 latest workouts',

        totalMasterclass: 'Total Masterclass',
        addMasterclass: 'Create a masterclass',
        topFiveMasterclass: 'Top 5 popular masterclass',
        latestFiveMasterclass: '5 latest masterclass',

        totalPrograms: 'Total programs',
        addProgram: 'Add program',
        totalTrained: 'total trained',
        topFivePrograms: 'Top 5 popular programs',
        totalJoined: 'Total joined',

        totalPosts: 'Total posts',
        addPost: 'Create a post',
        viewPost: 'View post',
        latestFivePosts: '5 latest posts',

        totalDownloads: 'Total downloads',

        markedRecipes: 'Marked recipes',
        addRecipe: 'Add recipe collection',
        thisMonth: 'This month',
        stripe: {
            create: {
                button: 'Stripe requires some new information from you',
            },
            login: {
                title: 'My gains',
                button: 'Login on your Stripe Account',
                error: 'Connection to stripe failed, please try again!',
            }
        }
    },
    guest: {},
    form: {},
}

export default _dashboard