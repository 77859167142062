const guest = {
    "comingSoon": {
        "title": "PRÓXIMAMENTE",
        "subtitle": "Mantente atento a Ikonstar"
    },
    "registration": {
        user: {
            title: "Registrarte",
            subtitle: "Es rápido y fácil.",
        },
        referral: {
            title: "¡{name} te invita a registrarte a Ikonstar!",
            existing: "Parece que ya estás en el equipo de {name}",
            notExisting: "{name} te invita a unirte a él en Ikonstar!",
            joined: "¡Gracias por unirte a {name} en Ikonstar!",
            notExistingButton: "Unirse a {name}",
            description: "¡Estás a punto de unirte al equipo de {name} como creador de contenido!{br}Si no esperas unirte a un equipo, usa {link}.",
            normalLink: "este formulario"
        },
        "form": {
            "validations": {
                "email": "no es un correo electrónico válido",
                "required": "es obligatorio",
                "min": "debe contener al menos {min} caracteres",
                "max": "no puede contener más de {max} caracteres",
                "recaptcha": "Por favor, verifique que es un ser humano"
            },
            "fields": {
                "email": {
                    "label": "Dirección de correo electrónico",
                    "min": "@:guest.registration.form.fields.email.label @:guest.registration.form.validations.min",
                    "max": "@:guest.registration.form.fields.email.label @:guest.registration.form.validations.max",
                    "required": "@:guest.registration.form.fields.email.label @:guest.registration.form.validations.required",
                    "valid": "@:guest.registration.form.fields.email.label @:guest.registration.form.validations.email"
                },
                "fullname": {
                    "label": "Nombre completo",
                    "min": "@:guest.registration.form.fields.fullname.label @:guest.registration.form.validations.min",
                    "max": "@:guest.registration.form.fields.fullname.label @:guest.registration.form.validations.max",
                    "required": "@:guest.registration.form.fields.fullname.label @:guest.registration.form.validations.required"
                },
                "username": {
                    "label": "Nombre de usuario",
                    "min": "@:guest.registration.form.fields.username.label @:guest.registration.form.validations.min",
                    "max": "@:guest.registration.form.fields.username.label @:guest.registration.form.validations.max",
                    "required": "@:guest.registration.form.fields.username.label @:guest.registration.form.validations.required",
                    "valid": "@:guest.registration.form.fields.username.label @:guest.registration.form.validations.email"
                },
                "password": {
                    "label": "Contraseña",
                    "min": "@:guest.registration.form.fields.password.label @:guest.registration.form.validations.min",
                    "max": "@:guest.registration.form.fields.password.label @:guest.registration.form.validations.max",
                    "required": "@:guest.registration.form.fields.password.label @:guest.registration.form.validations.required",
                    "valid": "@:guest.registration.form.fields.password.label @:guest.registration.form.validations.email"
                },
                "recaptcha": {
                    "label": "",
                    "min": "",
                    "max": "",
                    "required": "Por favor, verifique que es un Humano"
                }
            }
        },
        "step1": {
            "title": "¿Quién eres?",
            "creator": "Creador",
            "creatorHelper": "¿Quieres... Vivir de tu pasión a través de tus contenidos?",
            "user": "Usuario",
            "userHelper": "¿Quieres... Aprender, divertirte e interactuar con los mejores Creadores?",
            "button": "Continuar"
        },
        "step2": {
            "titles": {
                0: {
                    "title": "¡Es el Momento!",
                    "subtitle": "Convierte a tus seguidores en una comunidad activa real."
                },
                1: {
                    "title": "¿Listo para Brillar?",
                    "subtitle": "Presenta tu universo a tus fans más leales."
                },
                2: {
                    "title": "¡Comienza!",
                    "subtitle": "Comparte tu contenido y crea momentos inolvidables con tus fans."
                },
                3: {
                    "title": "¡Adelante, Artistas!",
                    "subtitle": "Ofrece a tus fans acceso exclusivo a tu universo."
                },
                4: {
                    "title": "¡Tu Escenario Te Espera!",
                    "subtitle": "Conoce e interactúa con tus fans sin esfuerzo."
                }
            },
            title: "¡Hola!",
            titleHelper: "Ingresa tu correo electrónico. Recibirás un correo con un código para confirmarlo.",
            button: "Crear mi cuenta",
            label: {
                email: 'Tu dirección de correo electrónico',
                fullname: 'Tu nombre completo',
                username: 'Su nombre de usuario',
            },
            helper: {
                password: 'La contraseña debe tener al menos 8 caracteres',
            }
        },
        "step3": {
            "title": "Confirmación de tu correo electrónico",
            "titleHelper": "Ingresa el código que recibiste en tu correo electrónico.",
            "button": "Continuar"
        },
        "step4": {
            "title": "¡Vamos con la contraseña!",
            "titleHelper": "La contraseña debe tener al menos 8 caracteres, incluyendo al menos 1 letra mayúscula, 1 letra minúscula y 1 número.",
            "button": "Continuar"
        }
    },
    "contactUs": {
        "title": "Contáctanos",
        "subtitle": "Utiliza el formulario de contacto a continuación para enviarnos un mensaje.",
        "time": "Generalmente respondemos en un plazo de 6 horas; por favor, realiza solo una solicitud de contacto.",
        "email": "Dirección de correo electrónico",
        "message": "Mensaje",
        "button": "Enviar Mensaje",
        "terms": {
            "link": "Política de Privacidad",
            "text": "Al hacer clic en @:guest.contactUs.button{''}, acepto que se procesen mis datos con el fin de gestionar mi solicitud. Para obtener más información, consulta nuestra {link}."
        },
        "emailTitle": "Envíanos un Correo Electrónico",
        "emailButton": "Mostrar dirección de correo electrónico"
    }
}

export default guest