const user = {
    "menu": {
        hi: 'Bonjour,',
        signOutKolSelect: "Ce n'est pas moi, me déconnecter",
        "dashboard": "Tableau de bord",
        home: 'Accueil',
        "feed": "Fil Actu",
        "messages": "Messagerie",
        "masterclasses": "Masterclasses",
        "workouts": "Entraînements",
        "myCalendar": "Mon calendrier",
        post: 'Post',
        "nutritionGuides": "Guides nutritionnels",
        "myPlan": "Mon plan",
        "myProgress": "Ma progression",
        "photoGallery": "Galerie de photos",
        "myFavorites": "Mes favoris",
        "myGallery": "Ma galerie",
        "recipes": "Recettes",
        "viewAccount": "Voir le compte",
        "changeIkon": "Changer mon Ikonstar",
        "selectAnIkon": "Changer d'Ikon en quelques clics !",
        "followedIkon": "Vous suivez actuellement",
        "myAccount": "Mon compte",
        "signOut": "Déconnexion",
        "creator": {
            "select": "Sélectionnez votre Ikonstar",
            "viewingNow": "Visionne actuellement",
            "changeSuccess": "Vous regardez maintenant {name}"
        }
    },
    "user": {        
        "account": {
            "title": "Paramètres de votre profil",
            menu: {
                account: 'Compte',
                personal: 'Informations personnelles',
                password: 'Mot de passe',
                subscription: 'Abonnements',
                settings: 'Mes paramètres',
                language: 'Langue',
                notification: 'Notification',
                privacy: 'Confidentialité',
            },
        },
        "form": {
            notificationManager: {
                title: 'Gestionnaire des notifications',
                allowNotification: 'Activer les notifications',
                selectAll: 'Sélectionner/Désélectionner tout',
                event: 'Événement',
                submit: 'Enregistrer mes préférences',
                success: 'Vos préférences de notification ont été mises à jour avec succès.',
                error: "Une erreur s'est produite lors de la mise à jour de vos préférences de notification.",
            },
            "language": {
                "title": "Votre langue préférée",
                "field": "Langue",
                "submit": "Changer la langue"
            },
            "personalInfo": {
                "title": "Vos informations personnelles",
                changeEmail: {
                    linkHelper: 'Vous pouvez changer votre email',
                    link: 'ici',
                    confirm: {
                        title: 'Etes-vous sur de vouloir changer votre email ?',
                        submit: 'Oui',
                        cancel: 'Non',
                    },
                    title: {
                        step1: 'Quelle est la nouvelle adresse email ?',
                        step2: "S'il vous plait, contrôler vos emails",
                        step3: 'Votre nouvelle adresse email a été sauvegardée',
                    },
                    helper: {
                        step1: 'Nous enverrons un code de vérification à votre nouvelle adresse e-mail',
                        step2: "Nous avons envoyé un code à",
                        step3: "Vous serez déconnecté pour appliquer les modifications",
                    },
                    sendCode: 'Envoyer le code',
                    verify: 'Verifier le code',
                    great: 'Super',
                },
                "fields": {
                    "username": {
                        "label": "Nom d'utilisateur",
                        "name": "nom d'utilisateur"
                    },
                    "name": {
                        "label": "Nom complet",
                        "name": "nom"
                    },
                    "email": {
                        "label": "Email",
                        "name": "email"
                    },
                    code: {
                        label: 'Code',
                    },
                    "slug": {
                        "label": "Slug",
                        "name": "slug",
                        "validations": {
                            "format": "Votre slug doit commencer par des lettres, ne peut contenir que des lettres minuscules, des chiffres et des tirets."
                        }
                    },
                    "gender": {
                        "label": "Genre",
                        "name": "genre",
                        "validations": {
                            "match": "Le genre sélectionné est invalide."
                        }
                    },
                    "birthdate": {
                        "label": "Date de naissance",
                        "name": "date de naissance",
                        "validations": {
                            "invalid": "La date de naissance est invalide."
                        }
                    },
                    "website": {
                        "label": "Site web",
                        "name": "site web"
                    },
                    "phone": {
                        "label": "Téléphone",
                        "name": "téléphone"
                    },
                    "about": {
                        "label": "À propos",
                        "name": "à propos"
                    },
                    "bio": {
                        "label": "Bio",
                        "name": "bio"
                    },
                    "old_password": {
                        "label": "Ancien mot de passe",
                        "name": "ancien mot de passe"
                    },
                    "password": {
                        "label": "Mot de passe",
                        "name": "mot de passe"
                    },
                    "password_confirmation": {
                        "label": "Confirmation du mot de passe",
                        "name": "confirmation du mot de passe",
                        "validations": {
                            "match": "Les mots de passe doivent correspondre."
                        }
                    }
                },
                "submit": "Enregistrer les modifications",
                "success": "Vos informations personnelles ont été mises à jour avec succès.",
                "successPassword": "Votre mot de passe a été mis à jour avec succès.",
                "error": "Une erreur s'est produite lors de la mise à jour de vos informations personnelles."
            },
            "subscription": {
                endingWith: "terminant par",
                "title": "Résumé de l'abonnement",
                "paymentMethod": "Méthode de paiement",
                "subscriptionPrice": "Prix de l'abonnement",
                isCanceled: 'Abonnement annulé',
                "nextExpiryDate": "Prochain renouvellement",
                "subscriptionType": "Type d'abonnement",
                "graceUntil": "Période de grâce",
                "save": "Enregistrer",
                "resumeButton": "Reprendre mon abonnement",
                "titleResume": "Êtes-vous sûr de vouloir reprendre ?",
                "confirmResume": "Reprendre",
                "resumeSuccess": "Votre abonnement a été repris avec succès !",
                "resumeError": "Votre abonnement n'a pas été repris, réessayez !",
                "cancelButton": "Annuler mon abonnement",
                "titleCancel": "Êtes-vous sûr de vouloir annuler votre abonnement à {creator}?",
                "confirmCancel": "Oui, annuler",
                "closeButton": "Non, je n'annule pas",
                "cancelSuccess": "Votre abonnement a été annulé avec succès !",
                "cancelError": "Votre abonnement n'a pas été annulé, réessayez !"
            }
        }
    },

    superAdmin: {
        "menu": {
            "account": "Compte",
            "personal": "Informations personnelles",
            "management": "Gestion",
            "members": "Membres",
            "creators": "Créateurs",
            "security": "Sécurité"
        },
        "title": {
            "list": "Membres",
            "add": "Ajouter un nouveau membre",
            "edit": "Modifier un membre",
            "ban": "Bannir l'utilisateur",
            "accountManager": "Gestionnaire de compte"
        },
        "form": {
            "list": {
                "search": "Rechercher...",
                "button": {
                    "add": "Ajouter un membre"
                },
                "filters": {
                    "onlyBanned": "Uniquement bannis",
                    "onlySubscribers": "Uniquement abonnés"
                },
                "columnMember": "Nom",
                "columnRole": "Rôle",
                "columnStatus": "Statut",
                "columnCreatedAt": "Créé le",
                "columnEmail": "Adresse e-mail",
                "convertToCreator": "Convertir en Créateur",
                "editMember": "Modifier le membre",
                "banMember": "Bannir le membre",
                "unbanMember": "Débannir le membre",
                "impersonate": "Impersonate",
                "confirmationBan": "Êtes-vous sûr de vouloir bannir ?",
                "confirmationUnban": "Êtes-vous sûr de vouloir débannir ?",
                "banIt": "Oui, bannir !",
                "unbanIt": "Oui, débannir !",
                "no": "Non",
                "activateSuccess": "Cette action a été effectuée avec succès !",
                "activateError": "Cette action n'a pas été effectuée avec succès !",
                "confirmationConvertToCreator": "Êtes-vous sûr de vouloir convertir cet utilisateur en créateur ? Cette action ne peut pas être annulée.",
                "convertToCreatorSuccess": "L'utilisateur a été converti en créateur avec succès !"
            },
            "add": {
                "name": "Nom complet",
                "username": "Nom d'utilisateur",
                "email": "Adresse e-mail",
                "birthdate": "Date de naissance",
                "role": "Rôle",
                "password": "Mot de passe",
                "matchPassword": "Confirmez le mot de passe",
                "verified": "Vérifié",
                "job": "Poste",
                "cancel": "Annuler",
                "submit": "Envoyer",
                "back": "Retour",
                "success": "Le membre a été créé avec succès !",
                "error": "Le membre n'a pas été créé, veuillez réessayer !"
            },
            "edit": {
                "name": "Nom complet",
                firstname: `Prénom`,
                lastname: `Nom`,
                language: `Langue`,
                "username": "Nom d'utilisateur",
                "email": "Adresse e-mail",
                "birthdate": "Date de naissance",
                "role": "Rôle",
                "password": "Mot de passe",
                "matchPassword": "Confirmez le mot de passe",
                "verified": "Vérifié",
                "job": "Poste",
                "cancel": "Annuler",
                "submit": "Envoyer",
                "back": "Retour",
                "success": "Le membre a été mis à jour avec succès !",
                "error": "Le membre n'a pas été mis à jour, veuillez réessayer !"
            },
            "ban": {
                "banReason": "Motif du bannissement",
                "placeholder": "Entrez le motif du bannissement"
            },
            "accountManager": {
                "attachKol": "Attacher un créateur",
                "detachKol": "Détacher un créateur",
                "search": "Qui cherchez-vous ?",
                "columnKol": "Nom d'utilisateur",
                "columnName": "Nom complet",
                "columnCreatedAt": "Créé le",
                "columnFollowers": "Abonnés",
                "modal": {
                    "attach": {
                        "title": "Sélectionner un créateur",
                        "submit": "Enregistrer",
                        "creator": {
                            "name": "Créateur",
                            "label": "Créateur"
                        },
                        "success": "Le créateur a été attaché avec succès !",
                        "error": "Le créateur n'a pas été attaché, veuillez réessayer !"
                    },
                    "detach": {
                        "title": "Sélectionner un créateur",
                        "submit": "Enregistrer",
                        "creator": {
                            "name": "Créateur",
                            "label": "Créateur"
                        },
                        "confirm": "Êtes-vous sûr de vouloir détacher ce créateur ?",
                        "success": "Le créateur a été détaché avec succès !",
                        "error": "Le créateur n'a pas été détaché, veuillez réessayer !"
                    }
                }
            }
        }
    },

    "myWorkoutProgram": {
        "title": "Programme d'entraînement",
        "searchPlaceholder": "Rechercher un programme d'entraînement",
        "empty": "Il n'y a pas de programme d'entraînement",
        "joinSuccess": "Programme d'entraînement rejoint avec succès",
        "week": "semaine",
        "weeks": "semaines",
        "day": "jour",
        "days": "jours",
        "features": "Fonctionnalités du programme",
        "recipeCategory": "Catégorie de recette",
        "listContent": "Liste de contenu",
        "takeBreak": "Prendre une pause",
        "followersCompleted": "abonnés ont terminé",
        "filter": {
            "bodyPart": "Partie du corps",
            "level": "Niveau",
            "category": "Catégorie"
        },
        "buttons": {
            "joinProgram": "Rejoindre le programme",
            "joinedProgram": "Programme rejoint"
        },
        "message": {
            "joinSuccess": "Rejoindre le programme avec succès",
            "joinError": "Échec de la participation au programme"
        },
        "form": {
            "title": "Rejoignez ce programme",
            "fields": {
                "date": "Commence à",
                "hour": "Sélectionnez l'heure"
            }
        }
    },
    "onboarding": {
        "title": "Configurez votre compte IKONSTAR",
        "welcome": "Bienvenue {name}",
        "thankYou": "Merci d'avoir rejoint {creatorName}",
        "letsSetupYourAccount": "Configurons votre compte",
        "form": {
            "step1": {
                "title": "Veuillez entrer vos mensurations",
                "yourAge": "Votre âge",
                "yourBirthdate": "Votre date de naissance",
                "years": "ans",
                "yourHeight": "Votre taille",
                "yourWeight": "Votre poids",
                "yourGender": "Votre genre",
                "weightUnit": "Unité de poids",
                "heightUnit": "Unité de taille"
            },
            "step2": {
                "title": "Veuillez répondre aux questions suivantes",
                "chooseYourObjective": "Choisissez votre objectif",
                "objectives": {
                    "toMaintainAHealthyBody": "Maintenir un corps en bonne santé",
                    "toBeFitOrLoseWeight": "Être en forme / perdre du poids",
                    "toGainMuscleAndCurves": "Gagner de la masse musculaire et des courbes",
                    "toDevelopStrength": "Développer la force"
                }
            },
            "step3": {
                "title": "Veuillez répondre aux questions suivantes",
                "yourActivityLevel": "Votre niveau d'activité",
                "activityLevels": {
                    "noExercise": "Aucun exercice",
                    "light": "Léger (1 jour par semaine)",
                    "moderate": "Modéré (2-3 jours par semaine)",
                    "intense": "Intense (plus de 4 jours)"
                }
            }
        },
        "skip": "Passer",
        "next": "Suivant",
        "numberTypeError": "Veuillez entrer un nombre valide",
        "invalidGender": "Veuillez sélectionner une valeur valide",
        "requiredGender": "Veuillez sélectionner votre genre",
        "invalidHeightUnit": "Veuillez sélectionner une unité de taille",
        "invalidWeightUnit": "Veuillez sélectionner une unité de poids",
        "invalidObjective": "Veuillez sélectionner au moins un objectif",
        "invalidActivityLevel": "Veuillez sélectionner un niveau d'activité valide",
        "invalidBirthdate": "Veuillez sélectionner une date de naissance valide",
        "success": "Vos informations de configuration ont été enregistrées avec succès"
    },
    "workouts": {
        "label": "Entraînements",
        "title": "Mes Entraînements",
        "subTitle": "Aujourd'hui, c'est vous qui brillez",
        "emptyFilter": {
            "workouts": "Il n'y a pas d'entraînement avec ces filtres",
            "masterclass": "Il n'y a pas d'entraînement avec ces filtres",
            "video": "Il n'y a pas de vidéo avec ces filtres"
        },
        "empty": {
            "workouts": "n'a pas encore publié d'entraînement",
            "masterclass": "n'a pas encore publié d'entraînement",
            "video": "n'a pas encore publié de vidéo"
        },
        "subMenu": {
            "allClasses": "Entraînements",
            "allVideos": "Vidéos",
            "workoutProgram": "Programmes"
        },
        "filter": {
            "bodyPart": "Toutes les parties du corps",
            "location": "Emplacement",
            "duration": "Durée"
        },
        "sort": {
            "label": "Trier par",
            "latestJoined": "Rejoins le plus récent",
            "oldestJoined": "Rejoins le plus ancien"
        },
        "search": {
            "placeholder": "Trouver un entraînement"
        },
        "likes": "j'aime",
        "episodes": {
            "label": "Exercice",
            "viewFaq": "Voir la FAQ",
            "viewTips": "Voir les Conseils",
            "tips": {
                "title": "Vous avez une question ?",
                "label": "Conseil",
                "done": "Terminé"
            },
            "repsIndication": "Séries : {current} / {total}"
        },
        "modal": {
            "confirmExit": "Êtes-vous sûr de vouloir quitter l'exercice ?",
            "continue": "Continuer l'exercice",
            "exit": "Quitter l'exercice"
        },
        "viewEpisode": {
            "enterYourSet": "Nombre de séries",
            "times": "série",
            "autoPlay": "Lecture automatique",
            "autoPlayTips": "Continuer automatiquement vers l'épisode suivant après la pause",
            "startNow": "Commencer maintenant",
            "ready": "Prêt",
            "takeABreak": "Faire une pause",
            "nextEpisodeIn": "Prochain épisode dans",
            "nextRepIn": "Prochaine série dans",
            "requiredEquipments": "Équipements nécessaires",
            "repetitions": "Répétitions"
        },
        "programs": {
            "label": "Programmes"
        }
    },
    live: {
        waiting: 'Veuillez patienter, le direct va bientôt commencer.',
        seeLast: 'Nouveaux Messages',
        finished: 'Le live est terminé, rendez-vous au prochain !',
        backToFeed: 'retour au feed',
        notStarted: "LE LIVE N'A PAS ENCORE COMMENCÉ",
        chatRateLimit: 'Vous envoyez des messages trop vite.',
        mutedTitle: 'EXCLUSION TEMPORAIRE',
        mutedHelper: 'Vous êtes temporairement banni(e) du chat.',
        mutedMessage: 'message supprimé par un modérateur.',
    }
}

export default user