const page = {
    "global": {
        "title": "Ma Page"
    },
    "user": {},
    "superAdmin": {},
    "creator": {
        "subscribersCount": "Abonnés",
        followersCount: 'Followers',
        "latestWorkout": "Dernier entraînement",
        "myWorkouts": "Voir mes entraînements",
        "addWorkout": "Créer un entraînement",
        "latestMasterclass": "Dernière masterclass",
        "myMasterclass": "Voir mes masterclasses",
        "addMasterclass": "Créer une masterclass"
    },
    "guest": {},
    "form": {}
}


export default page
