export default {
    "addTag": "Ajouter un tag",
    "modal": {
        "title": "Ajouter un tag",
        "fields": {
            "tag": {
                "label": "Tag",
                "name": "tg"
            }
        },
        "submit": "Ajouter"
    },
    "autocomplete": {
        "type": "Commencez à taper pour rechercher"
    }
}