import { createI18n } from 'vue-i18n'

import en from './en'
import fr from './fr'
import es from './es'

const messages = {
    en,
    fr,
    es,
}

const i18n = createI18n({
    legacy: false,
    locale: localStorage.getItem('user-preferred-lang') || 'en',
    fallbackLocale: 'en',
    messages,
})

export default i18n