const gallery = {
    "global": {
        "title": "Mi galería",
        "empty": "No hay fotos",
        "search": {
            "placeholder": "Buscar una foto específica"
        },
        "notification": {
            "add": {
                "success": "¡La foto se ha subido correctamente!",
                "error": "La foto no se ha subido, por favor inténtalo de nuevo."
            },
            "update": {
                "success": "¡La foto se ha actualizado correctamente!",
                "error": "La foto no se ha actualizado, por favor inténtalo de nuevo."
            },
            "delete": {
                "success": "¡La foto se ha eliminado correctamente!",
                "error": "La foto no se ha eliminado, por favor inténtalo de nuevo."
            }
        },
        "modal": {
            "confirmDelete": {
                "title": "¿Estás seguro de que quieres eliminarla?",
                "confirm": "Eliminar",
                "cancel": "Cancelar"
            }
        }
    },
    "user": {
        "photos": "fotos",
        "buttons": {
            "add": "Subir nueva foto"
        },
        "buttonCollapse": {
            "more": "+ ver más",
            "less": "- ver menos"
        }
    },
    "creator": {},
    "guest": {},
    "form": {
        "list": {
            "searchByDate": "Buscar por fecha",
            "searchByUser": "Buscar por usuario"
        },
        "add": {
            "title": "Subir una nueva foto",
            "fields": {
                "title": "Título",
                "date": "Fecha",
                "shareCreator": "Compartir con el creador",
                "bodyParts": "Partes del cuerpo",
                "photo": "Agregar una foto",
                "photoRequired": "La foto es obligatoria"
            },
            "buttons": {
                "submit": "Subir mi foto",
                "edit": "Editar mi foto"
            },
            "error": "Se produjo un error, ¡por favor inténtalo de nuevo!"
        },
        "edit": {}
    }
}


export default gallery