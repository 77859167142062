const guest = {
    comingSoon: {
        title: `COMING {lb} SOON`,
        subtitle: `Stay tuned for Ikonstar`,
    },
    registration: {
        user: {
            title: "Sign Up",
            subtitle: "It’s quick and easy.",
        },
        referral: {
            title: "{name} invites you to register on Ikonstar!",
            existing: "Looks like you already are in {name}'s team",
            notExisting: "{name} invites you to join him on Ikonstar!",
            joined: "Thanks for joining {name} on Ikonstar!",
            notExistingButton: "Join {name}",
            description: "You are about to join {name}'s team as a content creator!{br}If you are not expecting to join a team, use {link} instead.",
            normalLink: "this form",
        },
        "form": {
            "validations": {
                "email": "is not a valid email",
                "required": "is required",
                "min": "must contain at least {min} characters",
                "max": "cannot contain more than {max} characters",
                "recaptcha": "Please verify that you are a human"
            },
            "fields": {
                "email": {
                    "label": "Email Address",
                    "min": "@:guest.registration.form.fields.email.label @:guest.registration.form.validations.min",
                    "max": "@:guest.registration.form.fields.email.label @:guest.registration.form.validations.max",
                    "required": "@:guest.registration.form.fields.email.label @:guest.registration.form.validations.required",
                    "valid": "@:guest.registration.form.fields.email.label @:guest.registration.form.validations.email"
                },
                "fullname": {
                    "label": "Full Name",
                    "min": "@:guest.registration.form.fields.fullname.label @:guest.registration.form.validations.min",
                    "max": "@:guest.registration.form.fields.fullname.label @:guest.registration.form.validations.max",
                    "required": "@:guest.registration.form.fields.fullname.label @:guest.registration.form.validations.required"
                },
                "username": {
                    "label": "Username",
                    "min": "@:guest.registration.form.fields.username.label @:guest.registration.form.validations.min",
                    "max": "@:guest.registration.form.fields.username.label @:guest.registration.form.validations.max",
                    "required": "@:guest.registration.form.fields.username.label @:guest.registration.form.validations.required",
                    "valid": "@:guest.registration.form.fields.username.label @:guest.registration.form.validations.email"
                },
                "password": {
                    "label": "Password",
                    "min": "@:guest.registration.form.fields.password.label @:guest.registration.form.validations.min",
                    "max": "@:guest.registration.form.fields.password.label @:guest.registration.form.validations.max",
                    "required": "@:guest.registration.form.fields.password.label @:guest.registration.form.validations.required",
                    "valid": "@:guest.registration.form.fields.password.label @:guest.registration.form.validations.email"
                },
                "recaptcha": {
                    "label": "",
                    "min": "",
                    "max": "",
                    "required": "Please verify that you are a Human"
                }
            }
        },
        step1: {
            title: 'Who are you?',
            creator: 'Creator',
            creatorHelper: 'Do you want to... Live off your passion thanks to your content?',
            user: 'User',
            userHelper: 'You want...Learn, have fun and discuss with the best Creators',
            button: 'Continue'
        },
        step2: {
            "titles": {
                "0": {
                    "title": "It's Time!",
                    "subtitle": "Turn your fans into a real active community."
                },
                "1": {
                    "title": "Ready to Shine?",
                    "subtitle": "Introduce your universe to your most loyal fans."
                },
                "2": {
                    "title": "Get Started!",
                    "subtitle": "Share your content and create unforgettable moments with your fans."
                },
                "3": {
                    "title": "Forward, Artists!",
                    "subtitle": "Offer your fans exclusive access to your universe."
                },
                "4": {
                    "title": "Your Stage Awaits!",
                    "subtitle": "Meet and interact with your fans effortlessly."
                }
            },
            title: 'Get Ready',
            titleHelper: 'Enter your email, you will receive an email with a code to confirm it.',
            button: 'Create my account',
            label: {
                email: 'Your email address',
                fullname: 'Your fullname',
                username: 'Your username',
            },
            helper: {
                password: 'The password must contain at least 8 characters',
            }
        },
        step3: {
            title: 'Confirm your email',
            titleHelper: "Please enter the code you received on your email.",
            button: 'Continue'
        },
        step4: {
            title: 'Setup your password!',
            titleHelper: "The password must contain at least 8 characters with at least 1 uppercase letter, 1 lowercase letter and 1 number",
            passwordValidation: 'The password is not valid',
            button: 'Continue'
        },
    },
    contactUs: {
        title: 'Contact us',
        subtitle: 'Use the contact form below to send us a message.',
        time: 'We generally answer within 6 hours, please make only one contact request.',
        email: 'Email address',
        message: 'Message',
        button: 'Send Message',
        terms: {
            link: 'Privacy Policy',
            text: "By clicking @:guest.contactUs.button{''}, I agree to have my data processed for the purpose of processing my request. For more information see our {link}."
        },
        emailTitle: 'Email Us',
        emailButton: 'Show email address',
    }
}

export default guest