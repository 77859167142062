const globals = {
    "allRightsReserved": "Tous droits réservés",
    "errors": {
        "404": {
            "title": "On dirait qu'il n'y a rien ici. Êtes-vous perdu ?",
            "button": "Aller à la page d'accueil"
        }
    },

    "slug": "Votre profil sera sur ikonstar.com/{slug}",

    specialUnlock: "Réservez votre place",
    accountNotSetupLive: "Vous devez terminer le tutoriel pour utiliser le live !",

    "confirmationDelete": "Êtes-vous sûr ?<br>Vous ne pourrez pas revenir en arrière !",
    remove: 'Supprimer',
    sendComment: 'Commenter',
    tryAgain: 'Ré-essayer',
    "deleteIt": "Oui, supprimez-le !",
    language: 'Langue',
    "previous": "Précédent",
    "next": "Suivant",
    start: 'Démarrer',
    hours: "heures",
    minutes: "minutes",
    "no": "Non",
    "yes": "Oui",
    'submit': "Sauvegarder",
    "enabled": "Activé",
    "disabled": "Désactivé",
    "loading": "Chargement...",
    "noMore": "Pas de contenu supplémentaire",
    "loadMore": "Charger plus",
    "uploadingMedia": "Téléchargement des médias",
    clickToClose: "Cliquez pour fermer",
    details: "Détails",
    backToProfile: "Retour sur mon profil",

    "noActionPreviewMode": "Vous ne pouvez pas faire cela en mode aperçu :)",

    "countdown": {
        "days": "Jours",
        "hours": "Heures",
        "minutes": "Minutes",
        "seconds": "Secondes"
    },

    "menu": {
        "sidebar": {
            "newProject": "Nouveau projet...",
            "settings": "Paramètres",
            "profile": "Profil",
            "preview": "Aperçu",
            notification: 'Notifications',
            signOut: "Déconnexion",
            homepage: 'Retour Accueil',
        }
    },
    notification: {
        unreadMessages: 'Messages non lus',
        markAllAsRead: 'Marquer {number} comme lu',
        markAsRead: 'Marquer comme lu',
        delete: 'Supprimer',
        preferences: 'Voir les préférences',
        switchRead: 'Afficher uniquement les non lus',
    },
    "edit": "Modifier",
    "addMore": "Ajouter plus",
    "seeMore": 'voir plus',
    "exit": "Sortir",
    "validations": {
        "email": "L'email doit être un email valide",
        "required": "Ce champ est requis.",
        "number": "Ce champ n'est pas une valeur numérique valide.",
        "date": "Ce champ n'est pas une date valide.",
        "string": "Le champ n'est pas une chaîne de caractères valide",
        "min": "Le champ doit contenir au moins {min} caractères",
        "numberMin": "Le champ doit être supérieur à {min}",
        "max": "Le champ autorise au maximum {max} caractères",
        "numberMax": "Le champ doit être inférieur à {max}",
        "recaptcha": "Veuillez vérifier que vous êtes un être humain",
    },
    "form": {
        "validations": {
            "email": "{label} n'est pas une adresse e-mail valide",
            "required": "{label} est requis",
            requiredSelect: "Veuillez choisir un {label}",
            "number": "{label} n'est pas une valeur numérique valide",
            "date": "{label} n'est pas une valeur de date valide",
            "string": "{label} n'est pas une chaîne de caractères valide",
            "numberMin": "{label} doit être supérieur à {min}",
            "numberMax": "{label} doit être inférieur à {max}",
            "min": "{label} doit contenir au moins {min} caractères",
            "max": "{label} ne peut contenir plus de {max} caractères",
            "recaptcha": "Veuillez vérifier que vous êtes un humain"
        },
        "fields": {
            "email": {
                "label": "Adresse e-mail"
            },
            "fullname": {
                "label": "Nom complet"
            },
            "username": {
                "label": "Nom d'utilisateur"
            },
            "password": {
                "label": "Mot de passe"
            },
            "title": {
                "label": "Le titre"
            },
            "date": {
                "label": "La date"
            },
            "photo": {
                "label": "Photo"
            },
            "comment": {
                "label": "Le commentaire"
            }
        }
    },
    "date": {
        "today": "Aujourd'hui",
        "yesterday": "Hier",
        "at": "le {date} à {time}",
        minutes: "minutes",
    },
    "requiredInputGuide": "Les champs marqués d'une * sont obligatoires",
    title: {
        'user-dashboard': '',
        'user-account': 'My account',
        'user-post-view': 'Post',
        'user-messages': 'My messages',
        'user-my-calendar': 'My personnal planning',
        'user-calendar-add-event': 'Add exercices',
        'user-private-video-call-event': 'Book a video call',
        'user-calendar-congrats': 'My calendar event',
        'user-workouts': 'My Workouts',
        'user-view-workouts': 'My Workouts',
        'user-masterclass': 'Masterclasses',
        'user-view-masterclass': 'Masterclasses',
        'user-view-masterclass-episode': 'Masterclasses',
        'user-progress': 'My progress',
        'user-favorites': 'My favorites',
        'user-gallery': 'My gallery',
        'user-recipes': 'My recipes',
        'user-view-recipe': 'My recipes',
        'user-workout-program': 'Workout Program',

        'kol-admin-dashboard': '',
        'kol-admin-my-page': '',
        'kol-admin-my-feed': 'Mon Fil Actu',
        'kol-admin-my-settings': 'Your profile settings',
        'kol-admin-messages': 'My messages',
        'kol-admin-my-calendar': 'My personnal planning',
        'kol-admin-calendar-add-event': 'Add custom event',
        'kol-admin-my-workouts': 'My Workouts',
        'kol-admin-edit-workout': 'Edit a Workout',
        'kol-admin-view-workout-episodes': 'My episodes',
        'kol-admin-edit-workout-episode': 'Edit an episode',
        'kol-admin-my-masterclass': 'Masterclasses',
        'kol-admin-edit-masterclass': 'Edit a masterclass',
        'kol-admin-view-masterclass-episodes': 'My episodes',
        'kol-admin-edit-masterclass-episode': 'Edit an episode',
        'kol-admin-medias-management': 'My medias',

    },
    "tagLine": "La plateforme unique pour les créateurs de contenu",
    "product": {
        "title": "PRODUIT",
        "forIkons": "Pour les ikons",
        "forFans": "Pour les fans",
        "forCorp": "Pour les entreprises",
        "become": "Devenir un ikon",
        "download": "Télécharger ikonstar"
    },
    "blog": {
        "title": "BLOG",
        "fitness": "Fitness",
        "food": "Nourriture",
        "health": "Santé",
        "workouts": "Entraînements",
        "nutrition": "Nutrition"
    },
    "support": {
        "title": "SUPPORT",
        "helpCenter": "Centre d'aide",
        "contact": "Contact"
    },
    "about": {
        "title": "À PROPOS"
    },

    "global": {
        "videoStatus": {
            "processing": "La vidéo est en cours de traitement",
            "pending": "La vidéo est en attente",
            "failed": "La vidéo a échoué"
        },

        "form": {
            "button": {
                "submit": {
                    "label": "Soumettre"
                }
            }
        }
    },

    plans: {
        intervals: {
            monthly: 'Plan Mensuel',
            quarterly: 'Plan Trimestriel',
            yearly: 'Plan Annuel',
        },
        recurring: {
            monthly: 'mois',
            quarterly: 'trimestre',
            yearly: 'an',
        }
    },

    enums: {
        "categories": {
            "aerobics": "Aérobic",
            "aqua-fitness": "Aqua Fitness",
            "boxing": "Boxe",
            "cardio": "Cardio",
            "circuit-training": "Entraînement en circuit",
            "cross-fit": "CrossFit",
            "dance-fitness": "Fitness en danse",
            "hiit": "HIIT",
            "martial-arts": "Arts martiaux",
            "pilates": "Pilates",
            "strength-training": "Entraînement de force",
            "yoga": "Yoga"
        },
        "body-parts": {
            "arms": "Bras",
            "legs": "Jambes",
            "chest": "Poitrine",
            "back": "Dos",
            "abdomen": "Abdomen",
            "shoulders": "Épaules",
            "glutes": "Fessiers",
            "full-body": "Corps entier",
            "neck": "Cou",
            "wrist": "Poignet",
            "ankle": "Cheville"
        },
        "goals": {
            "health": "Santé",
            "burning-fat": "Brûler les graisses",
            "building-muscle": "Développer les muscles",
            "increasing-endurance": "Augmenter l'endurance",
            "improving-mobility": "Améliorer la mobilité",
            "enhancing-flexibility": "Améliorer la flexibilité",
            "stress-relief": "Soulagement du stress",
            "weight-loss": "Perte de poids",
            "posture-improvement": "Amélioration de la posture",
            "body-toning": "Tonification du corps"
        },
        "levels": {
            "hard": "Difficile",
            "easy": "Facile",
            "beginner": "Débutant",
            "intermediate": "Intermédiaire",
            "advanced": "Avancé",
            "professional": "Professionnel",
            "elderly": "Personnes âgées",
            "for-all": "Pour tous"
        },
        "locations": {
            "indoor": "Intérieur",
            "outdoor": "Extérieur",
            "gym": "Salle de sport",
            "home": "Maison",
            "office": "Bureau",
            "swimming-pool": "Piscine",
            "fitness-studio": "Studio de fitness"
        }
    },

    "superAdmin": {
        "form": {
            "search": {
                "label": "Recherche",
                "placeholder": "Rechercher..."
            },

            "button": {
                "resetFilters": {
                    "label": "Réinitialiser les filtres"
                }
            }
        }
    },
    "pwa": {
        "title": "{app} est maintenant disponible en tant qu'application !",
        "description": "Installez {0} sur votre appareil iOS ! Appuyez sur Partager, puis sur {1}.",
        "addToHome": "Ajouter à l'écran d'accueil",
        "updateInfo": "Une nouvelle version d'Ikonstar est disponible ! Si vous êtes en train de travailler sur quelque chose d'important, vous pouvez rafraîchir votre navigateur plus tard.",
        "updateNow": "Mettre à jour maintenant",
        "updateLater": "Je rafraîchis plus tard"
    },
    messengerSubscriber: "Ce module est uniquement disponible pour les abonnés.",
    noMobileModule: "Ce module est uniquement disponible sur ordinateur de bureau ou tablettes.",
    previewMode: "Vous êtes maintenant en mode de prévisualisation. Cliquez ici pour arrêter.",
    stopPreviewMode: "Veuillez d'abord arrêter le mode prévisualisation.",
    impersonating: "Vous voyez la plateforme en tant que {username}. Cliquez ici pour arrêter",

    share: {
        title: 'Partager',
        copy: 'Copier',
        success: 'Le lien a été copié dans le Presse-papier',
    },

    string: {
        success: 'Copié dans le Presse-papier',
    },

    tooltips: {
        verifiedAccount: 'Compte Vérifié',
        like: "J'aime",
        share: 'Partager',
        deletePreviewLink: "Eliminar vista previa del enlace",
        disablePreviewLink: "Desactivar vista previa del enlace",
    },

    "leave": {
        "title": "Voulez-vous vraiment partir ? Vous avez des modifications non enregistrées !",
        "stay": "Rester",
        "leave": "Partir"
    },
    modules: {
        workouts: "Séances d'exercices ciblés et programmes d'entraînement sportif.",
        gallery: 'Partage privé de photos entre vos membres et vous.',
        recipes: 'Ensemble de recettes pour un entraînement complet.',
        polls: 'Proposez des sondages dans vos posts à vos membres.',
        'private-video-calls': "Proposez la vente d'appels video en direct à vos membres.",
        masterclass: 'Modules de leçons pour partager vos compétences et vos connaissances',
        podcasts: 'Séries audio/vidéo informatives couvrant divers sujets',
        'live-events': 'Évènements de live streaming à système de billetterie'
    },

    draft: {
        title: 'Brouillons',
        helper: 'vos brouillons sont uniquement disponibles sur cet appareil',
        noItems: "vous n'avez pas de brouillons enregistrés",
        noTitle: "Pas d'informations...",
        button: 'Liste des brouillons',
        continue: 'Continuer',
        deleteSuccess: 'Votre brouillon a été supprimé avec succès!',
        deleteError: "Votre brouillon n'a pas été supprimé, essayez à nouveau!",
    },

    search: {
        landingTitle: "Vous cherchez des {gold} ?{br}C'est par ici que ça se passe !",
        landingTitleGold: "créateurs Ikonstar",
        landingSubtitle: "Entrez leur pseudo et commencez à avoir accès{br}à leur contenu exclusif",
        input: {
            placeholder: "Trouver un créateur"
        },
        allResults: "Voir tous les résultats"
    },
    feed: {
        forYou: "Pour Vous",
        following: "Suivis"
    },

    signup: {
        title: "Créer un compte"
    }
}


export default globals