const workout = {
    global: {
        title: 'Workouts',
        likes: 'likes',

        kcalBurns: 'Kcal burns',
        workoutConsists: 'Workout consists',
        kcal: 'Kcal',
        avgHeartRate: 'Avg. heart rate',
        bpm: 'p.m.',
        fatBurns: 'Fat burns',
        level: 'Level',
        equipments: 'Equipments',
        none: 'None',
        recommendWorkoutTime: 'Recommend workout time',
        bodyParts: 'Body parts will be trained',
        goals: 'Goals',
        location: 'Location',
        tips: 'Tips',
        recommendedWorkoutTimePerWeek: {
            label: 'Recommend work out time',
            options: {
                1: '1 day a week',
                2: '2-3 days a week',
                3: '3-4 days a week',
                4: '5 days a week',
                5: 'Everyday a week',
            },
        },
        elementsNotFound: 'No elements found. Consider changing the search query.',
    },

    form: {
        list: {
            title: 'Workouts',
            subTitle: 'My workouts',
            empty: "Looks like you don't have any Workout yet",
            countSuffix: 'videos',
            minutes: 'minutes',

            button: {
                create: {
                    label: 'Create a new workout',
                },
                createFirst: {
                    label: 'Create my first workout',
                },
                delete: {
                    label: 'Delete',
                },
            },
        },
        delete: {
            button: {
                confirm: {
                    label: 'Delete It',
                },
                cancel: {
                    label: 'Cancel',
                },
            },

            message: {
                confirm: 'Are you sure you want to delete?',
                success: 'Your workout was deleted successfully!',
                error: 'Your workout was not deleted, try again!',
            },
        },
        create: {
            title: 'Create a new workout',
            subTitle: {
                information: 'Basic information',
                workoutDetail: 'Workout details',
                additional: 'Additional details',
            },

            // basic information
            _title: {
                label: 'Workout title',
            },
            description: {
                label: 'Short description of the workout',
            },
            cover: {
                label: 'Cover image',
                idleLabel: 'Click to select or Drop a file here...',
            },
            isScheduled: {
                label: 'Is scheduled',
            },
            publishedAt: {
                label: 'Published at',
            },
            isPinned: {
                label: 'Is pinned',
            },
            isPaid: {
                label: 'Is paid',
            },
            price: {
                label: 'Price',
            },
            // workout detail
            category: {
                label: 'Category',
            },
            level: {
                label: 'Level of session',
            },
            bodyPart: {
                label: 'Which body part will be trained?',
                placeholder: 'Choose body part',
            },
            caloriesSpendInKcals: {
                label: 'Enter average carb burns (kCal)',
            },
            averageHeartBeatPerMinute: {
                label: 'Enter average heart rate',
            },
            recommendedWorkoutTimePerWeek: {
                label: 'Recommend work out time',
                options: {
                    1: '1 day a week',
                    2: '2-3 days a week',
                    3: '3-4 days a week',
                    4: '5 days a week',
                    5: 'Everyday a week',
                },
            },
            tips: {
                helper: 'Tip #',
                label: 'Any tips for the users?',
                add: 'Add tips',
                explain: 'You can re-order your tips with drag and drop on the arrows',
            },
            locations: {
                label: 'Where can users do this workout?',
                placeholder: 'Choose workout locations',
            },
            goals: {
                label: 'What is the goal of this workout?',
                placeholder: 'Choose workout goals',
            },
            highlights: {
                label: 'Would you like to add specific tags to your workout?',
            },

            button: {
                submit: {
                    label: 'Create Workout',
                },
                save: 'Save',
                saveAndGo: 'Save and manage exercises',
            },

            message: {
                success: 'The series workout was created successfully!',
            },
        },
        edit: {
            title: 'Edit workout',

            button: {
                manageEpisodes: {
                    label: 'Manage exercises',
                },
                preview: {
                    label: 'Preview',
                },
                submit: {
                    label: 'Update Workout',
                },
            },

            message: {
                success: 'The series workout was updated successfully!',
            },
        },
    },

    coverImage: {
        form: {
            delete: {
                button: {
                    confirm: {
                        label: 'Delete It',
                    },
                    cancel: {
                        label: 'Cancel',
                    },
                },
                message: {
                    confirm: 'Are you sure you want to delete?',
                    success: 'The cover image was deleted successfully!',
                    error: 'The cover image was not deleted, try again!',
                },
            },
        },
    },

    coverVideo: {
        form: {
            delete: {
                button: {
                    confirm: {
                        label: 'Delete It',
                    },
                    cancel: {
                        label: 'Cancel',
                    },
                },
                message: {
                    confirm: 'Are you sure you want to delete?',
                    success: 'The video was deleted successfully!',
                    error: 'The video was not deleted, try again!',
                },
            },
        },
    },

    exercise: {
        form: {
            list: {
                title: 'Workout exercises',
                empty: "Looks like you don't have any exercise in this workout yet",

                exercise: {
                    label: 'Exercise',
                },
                exerciseNumber: {
                    label: 'Exercise #',
                },
                _title: {
                    label: 'Exercise title',
                },
                type: {
                    label: 'Content type',
                },
                videoStatus: {
                    label: 'Video encoding status',
                    status: {
                        none: {
                            color: 'text-bg-warning',
                            text: 'No Video Uploaded',
                        },
                        pending: {
                            color: 'text-bg-info text-white',
                            text: 'Waiting to encode',
                        },
                        processing: {
                            color: 'text-bg-warning',
                            text: 'Encoding',
                        },
                        failed: {
                            color: 'text-bg-danger',
                            text: 'Encoding failed',
                        },
                        done: {
                            color: 'text-bg-success',
                            text: 'Encoded & Available',
                        },
                    },
                },
                action: {
                    label: '',
                },

                button: {
                    create: {
                        label: 'Create A Workout Exercise',
                    },
                    createMultiple: {
                        label: 'Quick create multiple exercises',
                    },
                    viewTips: {
                        label: 'View Tips',
                    },
                },
            },
            delete: {
                button: {
                    confirm: {
                        label: 'Delete It',
                    },
                    cancel: {
                        label: 'Cancel',
                    },
                },
                message: {
                    confirm: 'Are you sure you want to delete?',
                    success: 'Your exercise was deleted successfully!',
                    error: 'Your exercise was not deleted, try again!',
                },
            },
            create: {
                title: 'Create a Workout Exercise',
                subTitle: {
                    information: 'Exercise information',
                    additional: 'Additional details',
                    content: 'Exercise content details',
                },
                visibility: {
                    label: "Who has access",
                    everyone: 'Available to everyone',
                    subscriber: 'Subscribers only',
                },
                // information
                _title: {
                    label: 'Exercise title',
                },
                description: {
                    label: 'Description',
                },
                cover: {
                    label: 'Cover image',
                    idleLabel: 'Click to select or Drop a file here...',
                    current: 'Current cover',
                },
                caloriesSpendInKcals: {
                    label: 'Calories spend in kcals',
                },
                // content
                repetition: {
                    label: 'Repetition',
                },
                set: {
                    label: 'Sets',
                },
                restingRepeatTime: {
                    label: 'Resting set time (seconds)',
                },
                requiredEquipments: {
                    label: 'Any equipment required for your Exercise?',
                    add: 'Add an equipment',
                },
                exerciseNumber: {
                    label: 'Exercise number',
                },
                type: {
                    label: 'Content type',
                },
                content: {
                    label: 'Exercise instructions',
                    htmlLabel: 'HTML Content',
                },
                file: {
                    label: 'File',
                    current: 'Current Video',
                },
                isPaid: {
                    label: 'Is paid',
                },
                isPinned: {
                    label: 'Is pinned',
                },
                daysBeforeRelease: {
                    label: 'Days before release',
                },
                rejectedReason: {
                    label: 'Rejected reason',
                },

                button: {
                    submit: {
                        label: 'Create Workout Exercise',
                        uploading: 'Uploading...',
                    },
                },

                message: {
                    success: 'The workout exercise was created successfully!',
                },
            },
            edit: {
                title: 'Edit workout exercise',

                button: {
                    submit: {
                        label: 'Update Workout Exercise',
                        uploading: 'Uploading...',
                    },
                },

                message: {
                    success: 'The workout exercise was updated successfully!',
                },
            },
            createMultiple: {
                title: 'Quick create multiple exercises',
                createMultipleEpisodes: 'Create multiple episodes',
                selectedMedias: {
                    label: 'Selected medias',
                    noMediaSelected: 'No media selected. You can select media from the media library',
                    dragToReorder: 'You can drag to reorder the exercises',
                },
                mediaLibrary: {
                    label: 'Media library',
                    noMediaFound: 'No media found',
                },
                search: {
                    placeholder: 'Search medias...',
                },

                button: {
                    backToExercises: {
                        label: 'Back to workout exercises',
                    },
                    createExercisesWithSelectedMedias: {
                        label: 'Create exercises with {n} selected media(s)',
                    },
                    uploadNewMedia: {
                        label: 'Upload new media',
                    },
                    confirm: {
                        label: 'Yes, create',
                    },
                    cancel: {
                        label: 'No, cancel',
                    },
                },

                message: {
                    confirmCreateExercisesWithNSelectedMedias:
                        'Are you sure you want to create exercise(s) with {n} selected media(s)?',
                    success: 'Exercises created successfully!',
                    error: 'Error when creating exercises',
                },
            },
            view: {
                repsIndication: 'Sets: {current} / {total}',
                nextEpisodeIn: 'Next episode in',
                nextRepIn: 'Next set in',
                takeABreak: 'Take a break',

                enterYourSet: {
                    label: 'Number of sets',
                },
                times: {
                    label: 'set',
                },
                repetitions: {
                    label: 'Repetitions',
                },
                requiredEquipments: {
                    label: 'Required equipments',
                },

                closeVideoReason: {
                    label: 'Please select a reason',
                },

                button: {
                    ready: {
                        label: 'Ready',
                    },
                    submitCloseReason: {
                        label: 'Submit',
                    },
                    cancelCloseReason: {
                        label: 'Carry on exercise',
                    },
                },

                message: {
                    confirmExit: 'Are you sure you want to close the exercise?',
                },
            },
        },
    },

    program: {
        global: {
            title: 'Programs',
            perPage: 'per page',
            copyPrevious: 'Copy previous',
            copyToAll: 'Copy to all',
            notFound: 'No Workouts found...',
        },
        form: {
            list: {
                title: 'My workout programs',
                empty: 'Looks like you don’t have any program yet',
                equipments: 'Equipments',
                none: 'None',
                minutes: 'minutes',
                bodyParts: 'Body parts will be trained',
                goals: 'Goals',
                
                search: {
                    placeholder: 'Search programs...',
                },

                week: {
                    label: 'week',
                    plural: 'weeks',
                },
                likes: {
                    label: 'likes',
                },

                button: {
                    create: {
                        label: 'Create a new program',
                    },
                },
            },

            create: {
                title: 'Create a new program',

                subTitle: {
                    information: 'Basic information',
                    workouts: 'Pick workouts',
                    otherDetails: {
                        title: 'Planning',
                        description: 'How would you suggest to organise the exercise?',
                    },
                    recommend: 'Recommend to',
                },

                bodyPart: {
                    label: 'Body parts',
                    placeholder: 'Choose body parts',
                },
                level: {
                    label: 'Level',
                    placeholder: 'Choose level',
                },
                category: {
                    label: 'Category',
                    placeholder: 'Select a category',
                },
                cover: {
                    label: 'Cover image',
                    idleLabel: 'Click to select or Drop a file here...',
                    current: 'Current cover',
                },
                _title: {
                    label: 'Program title',
                    placeholder: 'Enter the title of the program',
                },
                description: {
                    label: 'Short description of the workout program',
                    placeholder: 'Enter a short description of the workout program',
                },
                totalDay: {
                    label: 'Total days',
                    placeholder: 'Enter the total days',
                },
                totalWeek: {
                    label: 'Total weeks',
                    placeholder: 'Enter the total weeks',
                },
                features: {
                    label: 'Program features',
                    explain: 'You can re-order your tips with drag and drop on the arrows',
                    add: 'Add feature',
                    helper: 'Feature #',
                },
                bodyParts: {
                    label: 'Intense on body parts',
                },
                highlights: {
                    label: 'Highlights',
                    add: 'Add highlight',
                },
                file: {
                    label: 'Upload program video',
                    current: 'Current video',
                },
                // workouts
                workouts: {
                    label: 'Choose workouts to be include in a rotation',
                    chosen: 'Chosen {number} workouts',
                },
                // recommend
                goals: {
                    label: 'For people who want to...',
                },
                recipeCategory: {
                    label: 'Type of recipe to be recommended',
                    placeholder: 'Select a type of recipe',
                },

                daySelect: {
                    weekX: 'Week {number}',
                    dayXY: 'Day {from} - {to}',
                    breakDay: 'Break',
                    dayX: 'Day {number}',
                },

                message: {
                    noWorkoutSelected: 'Please select at least one workout',
                    noTotalWeekSpecified: 'Please specify the total weeks',
                    featureEmpty: 'Please fill in all the feature content or remove the empty ones',
                    success: 'The workout program was created successfully!',
                },

                button: {
                    submit: {
                        label: 'Create program',
                    },
                },
            },
            edit: {
                title: 'Edit workout program',

                button: {
                    submit: {
                        label: 'Update program',
                    },
                },

                message: {
                    success: 'The workout program was updated successfully!',
                },
            },
        },
    },
}

export default workout