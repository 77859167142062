const masterclass = {
    "global": {
        "title": 'Mis clases magistrales',
        "empty": "Parece que aún no tienes ninguna Clase Magistral",
        "masterclass": 'Clase Magistral',
        "card": {
            "countSuffix": 'episodio | episodios'
        },
        "likes": 'me gusta',
        "delete": 'Eliminar',
        "status": {
            "label": "Estado",
            "draft": "Modo borrador - no visible para usuarios",
            "draft-label": "Borrador",
            "active": "Publicado",
            "active-label": "Publicado",
        },
        "contentType": {
            "video": "Solo video",
            "html": "Solo texto",
            "mixed": "Video con texto"
        }
    },
    "user": {
        "title": 'Clases Magistrales',
        "search": {
            "placeholder": 'Encuentra una Clase Magistral'
        },
        "emptyFilter": 'No hay Clases Magistrales con estos filtros',
        "empty": 'no ha publicado ninguna Clase Magistral todavía'
    },
    "superAdmin": {},
    "creator": {
        "buttons": {
            "create": 'Crear una nueva Clase Magistral',
            "createFirst": 'Crear mi primera Clase Magistral',
            "submit": 'Crear Clase Magistral',
            "manageEpisode": 'Administrar episodios',
            "edit": 'Editar Clase Magistral',
            "createEpisode": 'Crear un nuevo episodio',
            "editEpisode": 'Editar episodio',
            "uploading": 'Cargando video...',
            save: 'Guardar'
        },
        "modal": {
            "confirmDelete": {
                "title": '¿Estás seguro de que quieres eliminar?',
                "confirm": 'Eliminarlo',
                "cancel": 'Cancelar'
            },
            "notification": {
                "delete": {
                    "success": '¡Tu episodio ha sido eliminado con éxito!',
                    "error": 'Tu episodio no fue eliminado, ¡inténtalo de nuevo!'
                }
            }
        },
        "notification": {
            "add": {
                "success": 'Tu Clase Magistral ha sido agregada con éxito!',
                "error": 'Tu Clase Magistral no fue agregada, ¡inténtalo de nuevo!'
            },
            "update": {
                "success": 'Tu Clase Magistral ha sido actualizada con éxito!',
                "error": 'Tu Clase Magistral no fue actualizada, ¡inténtalo de nuevo!'
            },
            "delete": {
                "success": 'Tu Clase Magistral ha sido eliminada con éxito!',
                "error": 'Tu Clase Magistral no fue eliminada, ¡inténtalo de nuevo!'
            }
        }
    },
    "guest": {},
    "form": {
        "list": {},
        "add": {
            "title": 'Crear nueva Clase Magistral',
            "steps": {
                "information": 'Información básica'
            },
            "fields": {
                "title": {
                    "label": 'Título de la Clase Magistral'
                },
                "description": {
                    "label": 'Descripción breve de la Clase Magistral'
                },
                "isScheduled": {
                    "label": 'Está programada'
                },
                "publishedAt": {
                    "label": 'Publicada el'
                },
                "isPaid": {
                    "label": 'Es de pago'
                },
                "price": {
                    "label": 'Precio'
                },
                "isPinned": {
                    "label": 'Está fijada'
                },
                "cover": {
                    "label": 'Imagen de portada',
                    "current": 'Portada actual',
                    "placeholder": 'Haz clic para seleccionar o arrastra un archivo aquí...'
                }
            }
        },
        "edit": {
            "title": 'Editar Clase Magistral',
            delete: {
                titleDelete: '¿Estás seguro de que quieres eliminar?',
                confirmDelete: 'Sí, eliminar',
                cancelDelete: 'Cancelar',
                success: '¡La portada se eliminó correctamente!',
                error: 'La portada no se eliminó, ¡inténtalo de nuevo!',
            }
        },
        "episodes": {
            "list": {
                "title": 'Episodios',
                "empty": 'Parece que aún no tienes ningún episodio.',
                "table": {
                    "number": 'Número de episodio',
                    "title": 'Título del episodio',
                    "type": 'Tipo de contenido',
                    "videoStatus": {
                        "title": 'Estado de Codificación de Video',
                        "status": {
                            "none": {
                                "color": 'text-bg-warning',
                                "text": 'No se ha subido ningún video'
                            },
                            "pending": {
                                "color": 'text-bg-info text-white',
                                "text": 'Esperando para codificar'
                            },
                            "processing": {
                                "color": 'text-bg-warning',
                                "text": 'Codificando'
                            },
                            "failed": {
                                "color": 'text-bg-danger',
                                "text": 'La codificación falló'
                            },
                            "done": {
                                "color": 'text-bg-success',
                                "text": 'Codificado y Disponible'
                            }
                        }
                    },
                    "actions": ''
                }
            },
            "add": {
                "title": 'Crear nuevo episodio',
                "steps": {
                    "information": 'Información del episodio',
                    "content": 'Detalles del contenido del episodio'
                },
                "fields": {
                    "title": {
                        "label": 'Título del episodio'
                    },
                    "description": {
                        "label": 'Descripción del video (uso interno)'
                    },
                    "content": {
                        "label": 'Contenido'
                    },
                    "htmlContent": {
                        "label": 'Contenido de tu Clase Magistral'
                    },
                    "episodeNumber": {
                        "label": 'Número de episodio'
                    },
                    "status": {
                        "label": 'Estado'
                    },
                    "type": {
                        "label": 'Tipo de contenido'
                    },
                    "videoStatus": {
                        "processing": 'El video se está procesando',
                        "pending": 'El video está pendiente',
                        "failed": 'El video ha fallado',
                    },
                    "cover": {
                        "label": 'Imagen de portada',
                        "placeholder": 'Haz clic para seleccionar o arrastra un archivo aquí...'
                    },
                    "file": {
                        "label": 'Archivo',
                        "current": 'Video actual',
                        "video": 'Video de tu Clase Magistral'
                    },
                    "isPinned": {
                        "label": 'Está fijado'
                    },
                    "isPaid": {
                        "label": 'Es de pago'
                    },
                    "daysBeforeRelease": {
                        "label": 'Días antes del lanzamiento'
                    },
                    "price": {
                        "label": 'Precio'
                    },
                    "rejectedReason": {
                        "label": 'Motivo de rechazo'
                    }
                }
            },
            "edit": {
                "title": 'Editar Episodio'
            }
        }
    }
}


export default masterclass