const admin = {
    menu: {
        dashboard: 'Dashboard',
        kols: 'Creators',
        recipes: 'Recipes',
        members: 'Members',
        settings: 'Settings',
        earlyAccesses: 'Early Accesses',
    },
    creators: {
        inviteLink: 'Creator invitation link'
    }
}

export default admin