export default {
    v2: {
        "title": "Connexion à Ikonstar",
        "navbar": {
            "creator": "Je suis un Créateur",
            "user": "Je suis un Fan",
            "creators": "Nos créateurs",
            "podcasters": "Podcasteurs",
            "video_creators": "Créateurs de vidéos",
            "musicians": "Musiciens",
            "artists": "Artistes",
            "game_developers": "Développeurs de jeux",
            "features": "Features",
            "academy": "Ikonstar academy",
            "login": "S'identifier",
            "start": "Démarrer",
            "search": "Trouver des créateurs ..."
        },
        "section1": {
            "logo_alt": "Ikonstar",
            "headline": "Connectez avec vos fans !",
            "subtitle": "Créer des moments de bonheur authentiques.",
            "description": "{0} vous permet de partager vos moments de joie et d'inspiration avec ceux qui vous soutiennent le plus. Engagez directement avec votre audience, proposez du contenu exclusif et créez des souvenirs inoubliables ensemble.",
            "descriptionStrong": "Ikonstar",
            "hero_banner": {
                "headline": "Offrez un contenu exclusif à votre audience",
                "button_text": "Rejoignez nous maintenant"
            }
        },
        "section2": {
            "headline": "Apportez votre positive Attitude.",
            "subtitle": "Partagez votre bonne humeur avec le monde.",
            "description": "Sur {0}, laissez transparaître votre énergie positive et votre passion. Partagez des moments authentiques et inspirants avec vos fans, et construisez une communauté qui vibre avec vous au quotidien.",
            "descriptionStrong": "Ikonstar",
            "hero_banner": {
                "headline": "Lives, vidéos, photos, messagerie{0}... Tout y est !",
                "button_text": "Diffusez maintenant"
            }
        },
        "section3": {
            "headline": "Soyez Inspiré.",
            "subtitle": "Atteignez de nouveaux sommets.",
            "description": "Laissez-vous inspirer par les témoignages et les succès des créateurs qui ont trouvé leur place sur {0}. Découvrez leurs parcours et comment ils utilisent notre plateforme pour réaliser leurs rêves et toucher des milliers de fans.",
            "descriptionStrong": "Ikonstar",
            "hero_banner": {
                "headline": "Masterclass, Entrainement, Podcasts, recettes... {0}À vous de jouer !",
                "button_text": "Créez votre légende"
            }
        },
        "section4": {
            "headline": "Monétisez Votre Talent.",
            "subtitle": "Transformez votre passion en revenu.",
            "description": "Grâce à {0}, vous pouvez monétiser votre contenu et recevoir le soutien financier de vos fans. Offrez des abonnements, des contenus exclusifs et bien plus, tout en gardant le contrôle sur vos créations.",
            "descriptionStrong": "Ikonstar",
            "hero_banner": {
                "headline": "Générer des revenus constants,{0}Encaisser vos gains automatiquement.",
                "button_text": "Commencez à gagner"
            }
        },
        "section5": {
            "headline": "Libérez Votre Esprit Créatif !",
            "subtitle": "Vivez l'aventure de la création.",
            "description": "{0} vous donne la liberté de créer et de partager vos aventures avec vos fans. Exploitez votre créativité sans limites et engagez votre audience avec des contenus uniques et captivants.",
            "descriptionStrong": "Ikonstar",
            "hero_banner": {
                "headline": "Découvrez une communauté de talents",
                "button_text": "Explorez maintenant",
                "button_text_2": "Parcourir les créateurs"
            }
        },
        "section6": {
            "headline": "Engagez votre Communauté",
            "subtitle": "Créez des liens forts et durables",
            "description": "Utilisez nos outils d'interactions {0} pour communiquer directement avec vos fans. Créez des posts, organisez des lives et faites participer votre communauté pour des échanges encore plus riches et personnels.",
            "descriptionStrong": "Ikonstar",
            "hero_banner": {
                "headline": "Devenez une Ikonstar{0}Dès Maintenant !",
                "button_text": "Rejoignez nous !"
            },
            "tools_button_text": "Découvrez les outils"
        },

    },
    user: {
        "title": "Connexion à Ikonstar",
        "navbar": {
            "creators": "Nos créateurs",
            "podcasters": "Podcasteurs",
            "video_creators": "Créateurs de vidéos",
            "musicians": "Musiciens",
            "artists": "Artistes",
            "game_developers": "Développeurs de jeux",
            "features": "Features",
            "academy": "Ikonstar academy",
            "login": "S'IDENTIFIER",
            "start": "DÉMARRER",
            "search": "Trouver des créateurs ..."
        },
        "section1": {
            "logo_alt": "Ikonstar",
            "headline": "Devenez un Fan Privilégié",
            "subtitle": "Soutenez vos créateurs de contenu favoris",
            "description": "{0} vous offre l'opportunité unique de vous rapprocher des créateurs qui vous inspirent. Profitez d'un accès exclusif à leur vie et à leur contenu inédit, ainsi qu'à des interactions personnalisées. Découvrez le contenu exclusif de vos influenceurs.",
            "descriptionStrong": "Ikonstar",
            "hero_banner": {
                "headline": "Découvrez le contenu{0}exclusif de vos influenceurs",
                "button_text": "Parcourir nos créateurs"
            }
        },
        "section2": {
            "headline": "Le backstage tous les jours, 7 jours sur 7",
            "subtitle": "Accédez aux contenus exclusifs de vos stars et vivez avec eux l'envers du décor.",
            "description": "Seuls les membres {0} peuvent affirmer faire partie du cercle restreint de leur personnalité préférée et vivre les plus belles expériences avec eux au quotidien. En tant que fan privilégié, vous avez accès aux stories, photos, vidéos de vos stars et bien plus encore… Accédez à des contenus inédits : Vidéo, Photo, Appels vidéos, et plus encore !",
            "descriptionStrong": "Ikonstar",
            "hero_banner": {
                "headline": "Accédez à des contenus inédits:{0}Vidéo, Photo, Appels vidéos, et plus encore !",
                "button_text": "Parcourir nos créateurs"
            }
        },
        "section3": {
            "headline": "Faites sauter toutes les barrières !",
            "subtitle": "Engagez directement avec les personnalités qui vous inspirent. Pas de barrières, pas de filtres, pas de restrictions, ils sont là pour vous et vous seul.",
            "description": "Profitez d'interactions directes et personnalisées avec les créateurs que vous aimez. Que ce soit par des messages privés, des live chats ou des événements LIVE exclusifs, {0} vous permet de nouer un lien fort et unique avec eux. Démarrez une relation unique dès aujourd'hui.",
            "descriptionStrong": "Ikonstar",
            "hero_banner": {
                "headline": "Démarrez une relation{0}unique dès aujourd'hui",
                "button_text": "Parcourir nos créateurs"
            }
        },
        "section4": {
            "headline": "Vivez une expérience sur-mesure",
            "subtitle": "Des vidéos ou des photos entièrement conçues selon votre souhait pour vous ou vos proches",
            "description": "Recevez des contenus spécialement conçus pour vous. Partagez des expériences mémorables avec les stars d'{0}. Demandez des vidéos personnalisées, des messages exclusifs ou des séances photos qui vous rapprochent de vos idoles. Échangez, partagez et personnalisez vos moments avec vos idoles.",
            "descriptionStrong": "Ikonstar",
            "hero_banner": {
                "headline": "Échangez, partagez et personnalisez{0}vos moments avec vos idoles.",
                "button_text": "Parcourir nos créateurs"
            }
        },
        "section5": {
            "headline": "Toutes vos passions réunies au même endroit",
            "subtitle": "Fitness, sport, musique, art, cuisine, lifestyle, podcasts ils sont tous là et vous attendent.",
            "description": "Que vous soyez passionné de vlogs, de fitness, de musique, de podcasts, ou de lifestyle, {0} rassemble une vaste gamme de talents. Explorez des contenus variés et connectez-vous avec des créateurs qui partagent vos intérêts.",
            "descriptionStrong": "Ikonstar",
            "hero_banner": {
                "headline": "Découvrez tous nos talents",
                "button_text_2": "Explorez maintenant",
                "button_text": "Parcourir nos créateurs"
            }
        },
        "section6": {
            "headline": "Là où les fans rencontrent leurs héros",
            "subtitle": "Abonnez vous à vos créateurs préférés",
            "description": "{0} est bien plus qu'une simple plateforme; c'est ici que les barrières entre vous et vos créateurs de contenu préférés disparaissent. Ici, vous pouvez suivre, interagir et vous rapprocher de vos idoles comme jamais auparavant. Que ce soit en découvrant des contenus exclusifs, en participant à des Lives ou en recevant des messages personnalisés, Ikonstar vous place au cœur de l'action.",
            "descriptionStrong": "Ikonstar",
            "hero_banner": {
                "headline": "Ikonstar vous place{0}Au cœur de l'action",
                "button_text": "Parcourir nos créateurs"
            },
            "tools_button_text": "JE REJOINS IKONSTAR"
        },

    },
    hero: {
        titles: {
            white: 'Monétisez{br}{gold}',
            gold: "vos Passions",
        },
        subtitles: {
            first: 'Le meilleur moyen de convertir votre audience en {bold}',
            bold: 'revenus récurrents !'
        },
        button: "Devenir un Ikonstar",
        login: "Connexion",
        welcome: "Bienvenue {username}",
        myProfile: "Mon compte",
    },
    works: {
        title: 'Comment ça marche ?',
        step: 'Étape {step}',
        titles: {
            1: 'Personnalisez votre page et votre abonnement',
            2: 'Informez vos followers que vous avez désormais un profil sur Ikonstar',
            3: 'Soyez actif et attentif envers vos abonnés'
        },
        contents: {
            1: {
                main: 'Réfléchissez à ce que vous pouvez offrir régulièrement et à ce qui rendra vos fans heureux.{0}{1}',
                bold: {
                    1: 'Sans tracas !'
                }
            },
            2: {
                main: '{1} pour informer vos abonnés. IKONSTAR est un lieu où une {2} - ceux qui veulent quelque chose de plus que de simplement vous suivre sur les réseaux sociaux.',
                bold: {
                    1: 'Publiez sur tous vos réseaux sociaux',
                    2: 'relation spéciale se crée entre vous et vos fans les plus actifs'
                }
            },
            3: {
                main: 'Publiez régulièrement sur votre espace Ikonstar et {1} !',
                bold: {
                    1: 'faites-en la promotion sur vos réseaux sociaux'
                }
            }
        }
    },
    features: {
        title: 'Fonctionnalités',
        tabs: {
            feed: {
                title: 'Fil privé',
                content: 'Plongez dans un flux de contenu exclusif, conçu spécifiquement pour les abonnés.{0}Accès privilégié à vos coulisses, à vos réflexions et à vos moments uniques.'
            },
            masterclass: {
                title: 'Masterclass et entraînements',
                content: 'Transmettez directement votre histoire et votre expertise. Sessions approfondies offrant des connaissances précieuses et des astuces pour améliorer votre influence.'
            },
            calls: {
                title: 'Appels vidéo privés',
                content: 'Connectez-vous directement. Une opportunité inégalée d\'interagir en face à face avec vos membres, de répondre aux questions et de transmettre des conseils personnalisés.'
            },
            messages: {
                title: 'Messages individuels',
                content: 'Soyez proche de votre communauté, accordez du temps à ceux qui sont abonnés payants'
            },
        }
    },
    money: {
        title: 'Montrez-moi l\'argent',
        currency: '€',
        subs: {
            short: 'ABOS',
            estimate: 'NOMBRE ESTIMÉ D\'ABONNÉS',
            price: 'PRIX DE L\'ABONNEMENT'
        },
        revenue: {
            title: "À UN COÛT D'ABONNEMENT DE {price}, VOUS GAGNEREZ",
            explain: "EUR/mois",
            disclaimer: "Avant TVA et frais de plateforme"
        }
    },
    footer: {
        white: 'Devenez un{br}{gold}',
        button: "Rejoignez maintenant",
        gold: 'Ikonstar{br}Dès maintenant!',
    },
    seo: {
        title: "IKONSTAR - Devenez un Ikonstar dès aujourd'hui et transformez votre passion en succès!",
        description: "Découvrez Ikonstar, la plateforme ultime où votre talent devient une source de revenus.",
        tags: "monétisation,créativité,partage,contenu exclusif,communauté,sport,fitness,musique,podcasts,lifestyle,abonnés,revenus,Ikonstar,passion,réussite"
    }
}