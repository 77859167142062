const calendar = {
    "global": {
        "title": "Mi planificación personal",
        "calendar": "Calendario",
        "today": "Hoy",
        "at": "a las",
        "buttons": {
            "schedule": {
                "privateVideoCall": "Programar una llamada",
                "workout": "Programar un entrenamiento",
                "recipe": "Agregar una receta"
            },
            "event": {
                "goTo": "Ir a",
                "close": "Cerrar",
                "delete": "Eliminar",
                "newWorkout": "Agregar un entrenamiento",
                "newRecipe": "Agregar una receta",
                "newCustom": "Agregar un evento personalizado",
                "newUser": "Agregar un evento de usuario",
                "live-streaming": 'Agregar una transmisión en vivo',
            },
            "back": "Volver al calendario",
            "add": "Agregar al calendario",
            "backType": {
                "calendar": "Volver al calendario",
                "custom": "Volver al calendario",
                "messenger": "Volver a mensajería",
                "workout": "Volver al calendario",
                "private-call": "Volver a mensajería",
                "recipe": "Volver al calendario",
                'creator-recipe': "Volver al calendario",
                "live-streaming": 'Volver al calendario',
            }
        },
        "modal": {
            "confirmDelete": {
                "title": "¿Estás seguro de que deseas eliminarlo?",
                "confirm": "Eliminarlo",
                "cancel": "Cancelar"
            }
        },
        "notification": {
            "add": {
                "success": "¡Tu evento se ha agregado con éxito!",
                "error": "Tu evento no se agregó, ¡inténtalo de nuevo!"
            },
            "update": {
                "success": "¡Tu evento se ha actualizado con éxito!",
                "error": "Tu evento no se actualizó, ¡inténtalo de nuevo!"
            },
            "delete": {
                "success": "¡Tu evento se ha eliminado con éxito!",
                "error": "Tu evento no se eliminó, ¡inténtalo de nuevo!"
            }
        },
        "filter": {
            "selectedDate": "Fecha seleccionada",
            "selectedTime": "Hora seleccionada",
            "search": {
                "placeholder": {
                    "recipe": "Buscar una receta específica"
                }
            }
        },
        "event": {
            "add": "Agregar un evento",
            "from": "De",
            "to": "a",
            "great": {
                "title": "Genial",
                "content": "El evento se ha agregado",
                "appointment": "Tu evento"
            },
            "label": {
                "creator-live-event": "Evento",
            },
            "type": {
                "episode": "episodio",
                "workout": "entrenamiento",
                "private-call": "llamada de video privada",
                "recipe": "receta",
                'creator-recipe': "receta",
                "custom": "personalizado",
                "user": "usuario",
                "live-streaming": "En vivo",
                "creator-live-event": "eventos",
            }
        }
    },
    "user": {
        "recipe": {
            "add": "Agregar receta"
        },
        "workout": {
            "add": "Agregar ejercicio",
            "question": "¿Preguntas?"
        },
        "custom": {
            "add": "Agregar evento personalizado"
        },
        "user": {
            "add": "Agregar evento de usuario"
        },
        "live-streaming": {
            "add": 'Agregar evento en vivo',
        },
        "payment": {
            "success": "¡El pago fue aceptado!"
        },
        "privateVideoCall": {
            "description": "El creador debe validar la franja horaria o discutir contigo para acordar otra fecha. En este momento, la llamada privada está pendiente de confirmación, encontrarás la información en el chat."
        }
    },
    "superAdmin": {},
    "creator": {},
    "guest": {},
    "form": {
        "list": {},
        "add": {
            "title": "Título",
            "content": "Contenido",
            "selectUser": "Seleccionar usuario",
            "button": {
                "save": "Guardar el evento"
            }
        },
        "edit": {}
    }
}


export default calendar