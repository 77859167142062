const gallery = {
    global: {
        title: 'My gallery',
        empty: 'There is no photo',
        search: {
            placeholder: 'Search a specific photo',
        },
        notification: {
            add: {
                success: 'Photo was uploaded successfully!',
                error: 'Photo was not uploaded, please try again!',
            },
            update: {
                success: 'Photo was updated successfully!',
                error: 'Photo was not updated, please try again!',
            },
            delete: {
                success: 'Photo was deleted successfully!',
                error: 'Photo was not deleted, please try again!',
            },
        },
        modal: {
            confirmDelete: {
                title: 'Are you sure you want to delete?',
                confirm: 'Delete It',
                cancel: 'Cancel',
            },
        },
    },
    user: {
        photos: 'photos',
        buttons: {
            add: 'Add new photo',
        },
        buttonCollapse: {
            more: '+ see more',
            less: '- see less',
        },
    },
    creator: {},
    guest: {},
    form: {
        list: {
            searchByDate: 'Search by date',
            searchByUser: 'Search by user',
        },
        add: {
            title: 'Upload a new photo',
            fields: {
                title: 'Title',
                date: 'Date',
                shareCreator: 'Share to creator',
                bodyParts: 'Body parts',
                photo: 'Add a photo',
                photoRequired: 'Photo is required',
            },
            buttons: {
                submit: 'Upload my photo',
                edit: 'Edit my photo',
            },
            error: 'An error occurred, please try again!',
        },
        edit: {},
    },
}

export default gallery