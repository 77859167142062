const recipe = {
    global: {
        likes: 'likes',
        filter: {
            category: 'Filter category',
            status: 'Filter status',
        },
        search: {
            placeholder: 'Search...',
        },
        notification: {
            add: {
                success: 'Recipe was added successfully!',
                error: 'Recipe was not added, please try again!',
            },
            update: {
                success: 'Recipe was updated successfully!',
                error: 'Recipe was not updated, please try again!',
            },
            delete: {
                success: 'Recipe was deleted successfully!',
                error: 'Recipe was not deleted, please try again!',
            },
            disable: {
                success: 'Recipe was disabled successfully!',
                error: 'Recipe was not disabled, please try again!',
            },
        },
        modal: {
            confirmDelete: {
                title: "Are you sure?<br>You won't be able to revert this!",
                confirm: 'Delete It',
                cancel: 'Cancel',
            },
            confirmDisable: {
                title: 'Are you sure you want to disable recipe?',
                confirm: 'Disable It',
                cancel: 'Cancel',
            },
        },
    },
    user: {
        recipe: 'Recipe',
        buttons: {
            addToPlanning: 'Add to planning',
            viewVideo: 'View video',
        },
    },
    superAdmin: {
        buttons: {
            add: 'Add recipe',
            resetFilter: 'Reset filters',
            back: 'Back',
        },
    },
    creator: {
        title: 'My recipes',
        subTitle: 'Our recipes',
        globalTitle: 'Ikonstar recipes',
        globalSubtitle: 'Our recipes',
        privateTitle: 'Your recipes',
        privateSubtitle: 'Your private recipes',
        empty: "Looks like you don't have any recipe activated",
        emptySearch: 'There is no recipe with these filters',
        setRecipe: 'Configure your recipes now',
        search: {
            placeholder: 'Search a specific recipe',
        },
        buttons: {
            add: 'Add a recipe',
            activateAll: 'Activate all recipes',
            setConfig: 'Configure my recipes',
            selectRecipe: 'Select recipe',
            removeSelectedRecipe: 'Remove selected',
            activateRecipe: 'Activate recipe',
            addToPlanning: 'Add to planning',
            disableRecipe: 'Disable recipe',
        },
        filter: {
            filter: 'Filters',
            category: 'Category',
            physicalGoal: 'Physical goal',
        },
        selectRecipe: {
            title: 'Our recipes',
            subtitle: 'Select the recipes you want to add',
            empty: 'There is no recipe',
            youSelected: 'You have selected',
            recipe: 'recipe',
            recipes: 'recipes',
        },
        viewRecipe: {
            title: 'Recipe',
            label: 'View recipe',
            minutes: 'minute | minutes',
            person: 'person | persons',
            ingredients: 'Ingredients',
            nutrition: 'Nutrition',
            allergens: 'Allergens',
            stepTitle: 'Follow the recipes step by step',
        },
        private: {
            title: 'Private recipes',
            globalSubtitle: 'Your recipes',
            addRecipe: 'Add a recipe',
            edit: 'Edit',
            currentFiles: 'Your current files',

            empty: "Looks like you don't have any recipe yet",
            createFirstButton: 'Create my first recipe',
            createButton: 'Create a recipe',
            foundStarted: {
                title: 'We found a started recipe, would you like to continue creating it?',
                yes: 'Yes continue',
                no: 'No, Start a new one',
            },
            delete: {
                title: 'Are you sure you want to delete this recipe? You cannot recover it afterward.',
                yes: 'Yes, delete',
                no: 'No, keep it',
                success: 'Your recipe was deleted successfully!',
                error: "Your recipe wasn't deleted! Try again",
            },
            'delete-video': {
                title: 'Are you sure you want to delete this video? You cannot recover it afterward.',
                yes: 'Yes, delete',
                no: 'No, keep it',
            },
            'delete-photo': {
                title: 'Are you sure you want to delete this image? You cannot recover it afterward.',
                yes: 'Yes, delete',
                no: 'No, keep it',
            },
            form: {
                chooseOne: 'You must choose one',
                addTitle: 'Create a recipe',
                editTitle: 'Edit a recipe',
                addSucces: 'Your recipe was created successfully!',
                updateSucces: 'Your recipe was updated successfully!',
                steps: {
                    1: {
                        title: 'Welcome to the recipe addition form. Follow the different steps, enter the necessary information for your recipe, and preview it online.',
                        subtitle: '',
                    },
                    2: {
                        title: 'Recipe informations',
                    },
                    3: {
                        title: 'Recipe content',
                    },
                    4: {
                        title: 'Preview recipe and submit',
                    },
                },
                information: 'Information',
                title: 'Recipe title',
                kcal: 'kCal (per servings)',
                description: 'Recipe short description',
                category: 'Choose a category',
                portions: 'Portions',
                portionsUnit: 'Persons, Pieces, Liters ...',
                portionsHelper: 'Example: for 4 persons, for 10 pieces, for 1 liter...',
                prepTime: 'Preparation time',
                cookingTime: 'Cooking time',
                restingTime: 'Resting time',
                cookingType: {
                    title: 'Choose cooking type',
                    oven: 'Oven',
                    hob: 'Hob',
                    'no-cooking': 'No Cooking',
                    other: 'Other',
                },
                difficulty: 'Choose difficulty',
                cost: {
                    title: 'Choose recipe cost',
                    cheap: 'Cheap',
                    average: 'Average',
                    expensive: 'Expensive',
                },
                files: 'Add photos or videos. {nbFile} files maximum',
                order: 'You can change the order of by dragging the boxes up and down',
                buttons: {
                    add: 'Add',
                    edit: 'Save',
                    cancel: 'Cancel',
                },
                ingredients: {
                    title: 'Ingredients',
                    add: 'Add an ingredient',
                    order: 'You can change the order of by dragging the boxes up and down',
                    quantity: 'Quantity',
                    unit: 'Unit',
                    unitHelper: "Example: 'g', 'tbsp', 'pinch'",
                    name: 'Name',
                    complement: 'Additional info',
                    complementHelper: "Example: 'at room temperature', 'pitted', 'to taste'",
                },
                recipeSteps: {
                    title: "Recipe's Steps",
                    add: 'Add a step',
                    step: 'Step',
                    order: 'You can change the order of the steps by dragging the boxes',
                    stepTitle: 'Title',
                    content: 'Content',
                },
                allergens: {
                    title: 'Known Allergens',
                    add: 'Add an allergen',
                    name: 'Name',
                },
                nutritions: {
                    title: 'Nutritional Info (per serving)',
                    add: 'Add a nutrition',
                    quantity: 'Value',
                    unit: 'Unit',
                    unitHelper: "Typically 'g'",
                    name: 'Name',
                },
            },
        },
    },
    guest: {},
    form: {
        list: {
            name: 'Recipe name',
            description: 'Description',
            status: 'Status',
            createdAt: 'Created at',
            edit: 'Edit',
            delete: 'Delete',
        },
        add: {
            title: 'Create new recipe',
            fields: {
                title: {
                    label: 'Recipe title',
                    placeholder: 'Enter recipe title',
                },
                description: {
                    label: 'Recipe description',
                    placeholder: 'Enter recipe description',
                },
                category: {
                    label: 'Category',
                    placeholder: 'Select category',
                },
                type: {
                    label: 'Type',
                    placeholder: 'Select type',
                },
                kcal: {
                    label: 'Kcal',
                    placeholder: 'Enter a number',
                },
                preparationTimeMinutes: {
                    label: 'Preparation time in minutes',
                    placeholder: 'Enter number in minutes',
                },
                totalPerson: {
                    label: 'Total person',
                    placeholder: 'Enter total person',
                },
                level: {
                    label: 'Level',
                    placeholder: 'Select level',
                },
                physicalGoal: {
                    label: 'Physical goal',
                    placeholder: 'Select physical goal',
                },
                photos: {
                    label: 'Photos',
                    cover: 'Cover image',
                    placeholder: 'Click to select or Drop a file here...',
                },
                video: {
                    label: 'Video',
                    current: 'Current video',
                },
                status: {
                    label: 'Status',
                    placeholder: 'Select a status',
                    draft: 'Draft',
                    active: 'Active',
                },
                allergens: {
                    label: 'Allergens',
                    placeholder: 'Enter allergen',
                },
                ingredients: {
                    label: 'Ingredients',
                    name: 'Ingredient name',
                    amount: 'Amount',
                    unit: 'Unit',
                },
                nutrition: {
                    label: 'Nutrition',
                    name: 'Nutrition name',
                    amount: 'Amount',
                    unit: 'Unit',
                },
                steps: {
                    label: 'Steps',
                    title: 'Step title',
                    content: 'Step content',
                },
                videoStatus: {
                    processing: 'Video is processing',
                    pending: 'Video is pending',
                    failed: 'Video is failed',
                    none: 'No Video Uploaded',
                },
            },
            buttons: {
                addAllergen: 'Add allergen',
                addIngredient: 'Add ingredient',
                addNutrition: 'Add nutrition',
                loading: 'Loading ...',
                next: 'Next',
                save: 'Save',
            },
        },
        edit: {
            title: 'Edit a recipe',
            buttons: {
                saveAllergens: 'Save allergens',
                saveIngredients: 'Save ingredients',
                saveNutrition: 'Save nutrition',
                saveSteps: 'Save steps',
            },
        },
    },
}

export default recipe