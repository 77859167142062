const progress = {
    global: {},
    user: {
        title: 'My progress',
        titleRange: {
            day: 'Today',
            week: 'Week',
            month: 'Month',
            year: 'Year',
            total: 'Total',
        },
        yourActivity: 'Your activity in',
        days: 'days',
        yourToday: 'Your activity',
        categories: {
            title: 'Your categories data',
        },
        bodyParts: {
            title: 'Your body parts data',
        },
        buttonCollapse: {
            more: '+ see more',
            less: '- see less',
        },
        progressBarChart: {
            title: "Today's exercise goal",
            timeSpent: 'Time spent',
            caloriesBurnt: 'Calories burnt',
            fatBurnt: 'Fat Burnt',
            noEvents: 'based on your calendar events',
        },
        barChart: {
            titleRange: {
                week: "Week's time",
                month: "Month's time",
                year: "Year's time",
                total: 'Total time',
            },
            timeSpent: 'Time spent',
            caloriesBurnt: 'Calories burnt',
            fatBurnt: 'Fat Burnt',
        },
    },
    creator: {},
    guest: {},
    form: {},
}

export default progress
