const guest = {
    "comingSoon": {
        "title": "ARRIVE BIENTÔT",
        "subtitle": "Restez à l'écoute pour Ikonstar"
    },
    registration: {
        user: {
            title: "S'inscrire",
            subtitle: "C’est rapide et facile.",
        },
        referral: {
            title: "{name} vous invite à vous inscrire sur Ikonstar !",
            existing: "Il semble que vous faites déjà partie de l'équipe de {name}",
            notExisting: "{name} vous invite à le rejoindre sur Ikonstar !",
            joined: "Merci d'avoir rejoint {name} sur Ikonstar !",
            notExistingButton: "Rejoindre {name}",
            description: "Vous êtes sur le point de rejoindre l'équipe de {name} en tant que créateur de contenu !{br}Si vous ne vous attendiez pas à rejoindre une équipe, utilisez {link} à la place.",
            normalLink: "ce formulaire"
        },
        form: {
            validations: {
                email: "n'est pas un email valide",
                required: "est obligatoire",
                min: "doit contenir au moins {min} caractères",
                max: "ne peut pas contenir plus de {max} caractères",
                recaptcha: "Veuillez vérifier que vous êtes un être humain",
            },
            fields: {
                email: {
                    label: "L'adresse email",
                    min: '@:guest.registration.form.fields.email.label @:guest.registration.form.validations.min',
                    max: '@:guest.registration.form.fields.email.label @:guest.registration.form.validations.max',
                    required: '@:guest.registration.form.fields.email.label @:guest.registration.form.validations.required',
                    valid: '@:guest.registration.form.fields.email.label @:guest.registration.form.validations.email',
                },
                fullname: {
                    label: "Le nom complet",
                    min: '@:guest.registration.form.fields.fullname.label @:guest.registration.form.validations.min',
                    max: '@:guest.registration.form.fields.fullname.label @:guest.registration.form.validations.max',
                    required: '@:guest.registration.form.fields.fullname.label @:guest.registration.form.validations.required',
                },
                username: {
                    label: "Le Pseudo",
                    min: '@:guest.registration.form.fields.username.label @:guest.registration.form.validations.min',
                    max: '@:guest.registration.form.fields.username.label @:guest.registration.form.validations.max',
                    required: '@:guest.registration.form.fields.username.label @:guest.registration.form.validations.required',
                    valid: '@:guest.registration.form.fields.username.label @:guest.registration.form.validations.email',
                },
                password: {
                    label: 'Le mot de passe',
                    min: '@:guest.registration.form.fields.password.label @:guest.registration.form.validations.min',
                    max: '@:guest.registration.form.fields.password.label @:guest.registration.form.validations.max',
                    required: '@:guest.registration.form.fields.password.label @:guest.registration.form.validations.required',
                    valid: '@:guest.registration.form.fields.password.label @:guest.registration.form.validations.email',
                },
                recaptcha: {
                    label: '',
                    min: '',
                    max: '',
                    required: 'Veuillez vérifier que vous êtes un Humain'
                },
            }
        },
        step1: {
            title: 'Qui es-tu ?',
            creator: 'Créateur',
            creatorHelper: 'Tu veux...Vivre de ta passion grâce à tes contenus ?',
            user: 'Utilisateur',
            userHelper: 'Tu veux...Apprendre, te divertir et intéragir avec les meilleurs Créateurs ?',
            button: 'Continuer'
        },
        step2: {
            titles: {
                0: {
                    title: "C'est le Moment !",
                    subtitle: "Transformez vos fans en une véritable communauté active.",
                },
                1: {
                    title: "Prêt à Briller ?",
                    subtitle: "Faites découvrir votre univers à vos fans les plus fidèles.",
                },
                2: {
                    title: "Lancez-vous !",
                    subtitle: "Partagez votre contenu et créez des moments inoubliables avec vos fans.",
                },
                3: {
                    title: "En Avant les Artistes !",
                    subtitle: "Offrez à vos fans un accès exclusif à votre univers.",
                },
                4: {
                    title: "Votre Scène Vous Attend !",
                    subtitle: "Rencontrez et interagissez avec vos fans en toute simplicité.",
                },
            },
            title: 'Hey you!',
            titleHelper: 'Tu vas recevoir un email avec un code pour confirmer ton email.',
            button: 'Créer mon compte',
            label: {
                email: 'Votre adresse courriel',
                fullname: 'Votre nom complet',
                username: "Votre nom d'utilisateur",
            },
            helper: {
                password: 'Le mot de passe doit contenir au moins 8 caractères',
            }
        },
        step3: {
            title: 'Confirmation de ton email',
            titleHelper: "Saisis le code que tu as reçu sur ton email.",
            button: 'Continuer'
        },
        step4: {
            title: 'Passons au mot de passe !',
            titleHelper: "Le mot de passe doit contenir au moins 8 caractères avec au moins 1 lettre majuscule, 1 lettre minuscule et 1 chiffre",
            button: 'Continuer'
        },
    },
    "contactUs": {
        "title": "Contactez-nous",
        "subtitle": "Utilisez le formulaire de contact ci-dessous pour nous envoyer un message.",
        "time": "Nous répondons généralement dans un délai de 6 heures. Veuillez ne faire qu'une seule demande de contact.",
        "email": "Adresse e-mail",
        "message": "Message",
        "button": "Envoyer le message",
        "terms": {
            "link": "Politique de confidentialité",
            "text": "En cliquant sur @:guest.contactUs.button{''}, j'accepte que mes données soient traitées dans le but de traiter ma demande. Pour plus d'informations, consultez notre {link}."
        },
        "emailTitle": "Envoyez-nous un e-mail",
        "emailButton": "Afficher l'adresse e-mail"
    }
}


export default guest