export default {
    "addTag": "Agregar etiqueta",
    "modal": {
        "title": "Agregar nueva etiqueta",
        "fields": {
            "tag": {
                "label": "Etiqueta",
                "name": "etiqueta"
            }
        },
        "submit": "Agregar"
    },
    "autocomplete": {
        "type": "Comienza a escribir para buscar"
    }
}