const earlyAccess = {
    "title": "DEVENEZ UN {lb} PARTENAIRE IKONSTAR",
    "subtitle": "Créez une relation unique avec votre communauté",

    "superAdmin": {
        "title": {
            "list": "Accès anticipés",
        },
        "form": {
            "list": {
                "searchUsername": "Rechercher par nom d'utilisateur",
                "searchEmail": "Rechercher par email",
                "searchVipCode": "Rechercher par code VIP",
                "columnUsername": "Nom d'utilisateur",
                "columnEmail": "Email",
                "columnPhone": "Téléphone",
                "columnVipCode": "Code VIP",
                "columnRole": "Rôle demandé",
                "columnCreationDate": "Créé",
                "createUser": "Créer un utilisateur",
                "createCreator": "Créer un créateur",
                "confirmCreateUserText": "Êtes-vous sûr de vouloir créer un utilisateur à partir de cet accès anticipé de l'utilisateur ?",
                "confirmCreateUserYesBtn": "Oui, créer un utilisateur",
                "confirmCreateUserNoBtn": "Non, annuler",
                "createUserError": "Erreur lors de la création de l'utilisateur",
                "createUserSuccess": "Utilisateur créé avec succès",
                "createCreatorSuccess": "Créateur créé avec succès",
                "createCreatorError": "Erreur lors de la création du créateur",
                "confirmCreateCreatorText": "Êtes-vous sûr de vouloir créer un créateur à partir de cet accès anticipé de l'utilisateur ?",
                "confirmCreateCreatorYesBtn": "Oui, créer un créateur",
                "confirmCreateCreatorNoBtn": "Non, annuler",
            }
        }
    },

    "form": {
        "types": {
            "manager": "Je suis un Manager",
            "agency": "Je suis une Agence",
            "creator": "Je suis un Créateur",
        },
        "username": "Nom d'utilisateur",
        "link": "Lien",
        "usernameHelper": "Votre nom d'utilisateur principal sur les réseaux sociaux",
        "social": "Réseau social",
        "addSocial": "Ajouter un réseau social",
        "fullname": "Votre nom complet",
        "email": "Votre email",
        "phone": "Votre numéro de téléphone",
        "vipCode": "Code VIP",
        "vipCodeHelper": "Avez-vous reçu un code VIP spécial ?",
    }
}


export default earlyAccess