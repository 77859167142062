const _dashboard = {
    "global": {
        "title": "Tablero"
    },
    "user": {
        "helperCalendar": "Organiza tu calendario",
        "latestPostsTitle": "Últimas Publicaciones",
        "followMe": "Sígueme",
        latestLiveEventTitle: 'Próximo Evento',
        "latestWorkoutTitle": "Último Entrenamiento",
        "latestMasterclassTitle": "Última Masterclass",
        "nothingPosted": "Aún no se ha publicado nada",
        "episodes": "Episodio | Episodios",
        "yourGoal": "Tu objetivo",
        "ofTheDay": "del día",
        unfollow: {
            title: '¿Estás seguro de que quieres dejar de seguir a {creador}?',
            submit: 'Dejar de seguir',
            cancel: 'Cancelar',
        },
        "shortcuts": {
            "buttons": {
                "call": "Reservar una llamada",
                "workout": "Entrenamientos",
                "masterclass": "Masterclasses",
                "recipe": "Recetas"
            }
        },
        "calendar": {
            "buttons": {
                "workout": "Programar un entrenamiento",
                "recipe": "Agregar una receta",
                "privateVideoCall": "Reservar una llamada",
                "privateVideoCallSecond": "Programar una llamada"
            },
            "events": {
                "see": "Ver",
                "nothing": "No se encontraron eventos",
                "privateVideoCall": "Llamada de video privada con "
            },
            "day": {
                "short": {
                    "monday": "Lun.",
                    "tuesday": "Mar.",
                    "wednesday": "Mié.",
                    "thursday": "Jue.",
                    "friday": "Vie.",
                    "saturday": "Sáb.",
                    "sunday": "Dom."
                },
                "long": {
                    "monday": "Lunes",
                    "tuesday": "Martes",
                    "wednesday": "Miércoles",
                    "thursday": "Jueves",
                    "friday": "Viernes",
                    "saturday": "Sábado",
                    "sunday": "Domingo"
                }
            }
        }
    },
    "superAdmin": {
        "statistic": {
            "creators": "Total de Creadores",
            "recipes": "Total de Recetas",
            "users": "Total de Usuarios",
            "lastAdded": "Último añadido:"
        },
        "creator": {
            "title": "Creación en progreso",
            "lastUpdate": "Última modificación:",
            "continueEditing": "Haz clic para continuar editando"
        },
        "button": {
            "new": "+ Crear nuevo",
            "addKol": "Agregar creador",
            "addMember": "Agregar miembro"
        },
        "topFiveKols": "Top 5 de la aplicación de Kol",
        "totalDownloaded": "Total descargado",
        "topFiveRecipes": "Top 5 de recetas populares",
        "thisMonth": "Este mes"
    },
    "creator": {
        "mandatory": "OBLIGATORIO",
        "lastTitle": "Tus datos más recientes",
        "detailReportButton": "Ver informe detallado",
        "totalWorkouts": "Total de Entrenamientos",
        "addWorkout": "Crear un entrenamiento",
        "topFiveWorkouts": "Top 5 de entrenamientos populares",
        "latestFiveWorkouts": "5 entrenamientos más recientes",
        "totalMasterclass": "Total de Masterclass",
        "addMasterclass": "Crear una Masterclass",
        "topFiveMasterclass": "Top 5 de Masterclass populares",
        "latestFiveMasterclass": "5 Masterclass más recientes",
        "totalPrograms": "Total de programas",
        "addProgram": "Agregar un programa",
        "totalTrained": "total entrenado",
        "topFivePrograms": "Top 5 de programas populares",
        "totalJoined": "Total de participantes",
        "totalPosts": "Total de publicaciones",
        "addPost": "Crear una publicación",
        "viewPost": "Ver publicación",
        "latestFivePosts": "5 últimas publicaciones",
        "totalDownloads": "Total descargas",
        "markedRecipes": "Recetas marcadas",
        "addRecipe": "Agregar colección de recetas",
        "thisMonth": "Este mes",
        "stripe": {
            "create": {
                "button": "Stripe requiere información adicional de tu parte"
            },
            "login": {
                "title": "Mis ganancias",
                "button": "Iniciar sesión en tu cuenta de Stripe",
                "error": "La conexión a Stripe falló, ¡inténtalo de nuevo!"
            }
        }
    },
    "guest": {},
    "form": {}
}


export default _dashboard