const masterclass = {
    global: {
        title: 'My masterclass',
        empty: "Looks like you don't have any Masterclass yet",
        masterclass: 'Masterclass',
        card: {
            countSuffix: 'episode | episodes',
        },
        likes: 'likes',
        delete: 'Delete',
        status: {
            label: 'Status',
            draft: 'Draft mode - not visible to users',
            'draft-label': 'Draft',
            active: 'Published',
            'active-label': 'Published',
        },
        contentType: {
            video: 'Video Only',
            html: 'Text Only',
            mixed: 'Video with Text',
        }
    },
    user: {
        title: 'Masterclasses',
        search: {
            placeholder: 'Find a masterclass',
        },
        emptyFilter: 'There is no masterclass with these filters',
        empty: 'did not post any masterclass yet',
    },
    superAdmin: {},
    creator: {
        buttons: {
            create: 'Create a new masterclass',
            createFirst: 'Create my first masterclass',
            submit: 'Create masterclass',
            manageEpisode: 'Manage episodes',
            edit: 'Edit masterclass',
            createEpisode: 'Create new episode',
            editEpisode: 'Edit episode',
            uploading: 'Video uploading...',
            save: 'Save',
        },
        modal: {
            confirmDelete: {
                title: 'Are you sure you want to delete?',
                confirm: 'Delete It',
                cancel: 'Cancel',
            },
            notification: {
                delete: {
                    success: 'Your episode was deleted successfully!',
                    error: 'Your episode was not deleted, try again!',
                },
            },
        },
        notification: {
            add: {
                success: 'Your masterclass was added successfully!',
                error: 'Your masterclass was not added, please try again!',
            },
            update: {
                success: 'Your masterclass was updated successfully!',
                error: 'Your masterclass was not updated, please try again!',
            },
            delete: {
                success: 'Your masterclass was deleted successfully!',
                error: 'Your masterclass was not deleted, please try again!',
            },
        },
    },
    guest: {},
    form: {
        list: {},
        add: {
            title: 'Create new masterclass',
            steps: {
                information: 'Basic information',
            },
            fields: {
                title: {
                    label: 'Masterclass title',
                },
                description: {
                    label: 'Short description of the masterclass',
                },
                isScheduled: {
                    label: 'Is Scheduled',
                },
                publishedAt: {
                    label: 'Published at',
                },
                isPaid: {
                    label: 'Is paid',
                },
                price: {
                    label: 'Price',
                },
                isPinned: {
                    label: 'Is pinned',
                },
                cover: {
                    label: 'Image cover',
                    current: 'Current cover',
                    placeholder: 'Click to select or Drop a file here...',
                },
            },
        },
        edit: {
            title: 'Edit masterclass',
            delete: {
                titleDelete: 'Are your sure you want to delete?',
                confirmDelete: 'Yes, delete it',
                cancelDelete: 'Cancel',
                success: 'The cover was deleted successfully!',
                error: 'The cover was not deleted, try again!',
            }
        },
        episodes: {
            list: {
                title: 'Episodes',
                empty: 'It looks like you do not have any episodes yet.',
                table: {
                    number: 'Episode number',
                    title: 'Episode title',
                    type: 'Content type',
                    videoStatus: {
                        title: 'Video Encoding Status',
                        status: {
                            none: {
                                color: 'text-bg-warning',
                                text: 'No Video Uploaded',
                            },
                            pending: {
                                color: 'text-bg-info text-white',
                                text: 'Waiting to encode',
                            },
                            processing: {
                                color: 'text-bg-warning',
                                text: 'Encoding',
                            },
                            failed: {
                                color: 'text-bg-danger',
                                text: 'Encoding failed',
                            },
                            done: {
                                color: 'text-bg-success',
                                text: 'Encoded & Available',
                            },
                        },
                    },
                    actions: '',
                },
            },
            add: {
                title: 'Create new episode',
                steps: {
                    information: 'Episode information',
                    content: 'Episode Content details',
                },
                fields: {
                    title: {
                        label: 'Episode title',
                    },
                    description: {
                        label: 'Video Description (internal use)',
                    },
                    content: {
                        label: 'Content',
                    },
                    htmlContent: {
                        label: 'Your masterclass content',
                    },
                    episodeNumber: {
                        label: 'Episode number',
                    },
                    status: {
                        label: 'Status',
                    },
                    type: {
                        label: 'Content type',
                    },
                    videoStatus: {
                        processing: 'Video is processing',
                        pending: 'Video is pending',
                        failed: 'Video is failed',
                    },
                    cover: {
                        label: 'Cover image',
                        placeholder: 'Click to select or Drop a file here...',
                    },
                    file: {
                        label: 'File',
                        current: 'Current Video',
                        video: 'Your masterclass video',
                    },
                    isPinned: {
                        label: 'Is pinned',
                    },
                    isPaid: {
                        label: 'Is paid',
                    },
                    daysBeforeRelease: {
                        label: 'Days before release',
                    },
                    price: {
                        label: 'Price',
                    },
                    rejectedReason: {
                        label: 'Rejected reason',
                    },
                },
            },
            edit: {
                title: 'Edit Episode',
            },
        },
    },
}

export default masterclass