const messenger = {
    global: {
        title: 'My messages',
        plan: 'Plan',
        another: 'Choose another conversation',
        sendSomething: 'Send something to {name}!',
        typeSomething: 'Write to {name}!',
        maxCharacterCount: "You've reached max {maxCharacterCount} characters per message.",
        notification: {
            add: {
                success: 'Your message was sent successfully!',
                error: 'Your message was not sent, please try again!',
            },
            confirm: {
                success: 'The private video call was confirmed successfully!',
                error: "The private video call can't confirmed, please try again!",
            },
        },
        modal: {
            confirmSendToAll: {
                title: 'Are you sure you want to send a message to all subscribers?',
                confirm: 'Yes, send it',
                cancel: 'Cancel',
            },
            confirmCall: {
                title: 'Are you sure you want to confirm?',
                confirm: 'Yes, confirm',
                cancel: 'Cancel',
            },
        },
    },
    user: {
        title: 'My messages with {name}',
        privateCall: {
            title: 'Private relation',
            bookTitle: 'Book a video call',
            directCall: 'Direct call',
            yourChoice: 'Select a time slot and a date',
            selectTimeSlot: 'Select a time slot',
            selectDay: 'Select a day',
            selectHour: 'Select a range',
            pickRange: 'Pick a range',
            selected: {
                title: 'Your selected time slot',
                callWith: 'Private video call with',
                "slot": "Call duration {number} minutes",
                "reminder": "Reminder {number} minutes before",
                minutes: 'minutes',
            },
            form: {
                timeSlot: 'Time slot',
                day: 'Day',
                hour: 'Range',
                from: 'From',
                to: 'to',
                editModal: {
                    title: 'Modify private video call',
                    submit: 'Save',
                    success: 'The private video call was updated successfully!',
                    error: "The private video call can't be updated, please try again!",
                },
            },
            payment: {
                breadcrumbs: 'Call payment',
                title: 'Your payment information',
                success: 'The payment was accepted!',
                error: "The private video call can't be purchased for the moment!",
                buttonBackSlot: 'Back to slot selection',
                validate: 'Validate my payment',
                secure: 'Payment 100% secure',
                endingWith: 'ending with',
            },
            message: {
                description:
                    'I booked a session of {time_slot} min. with you on the follow times, Does it work for you?',
                descriptionChanged: 'Hey, I updated the date of the private video call of {time_slot} min.',
                descriptionConfirmed: 'Yes, I am available. See you.',
                modify: 'Modify',
                available: 'I am available',
                yourAppointment: 'YOUR EVENT',
                confirmed: 'The private video call was confirmed successfully!',
                errorConfirmed: "The private video call can't confirmed, please try again!",
                confirmTitle: 'Are you sure you want to confirm?',
                confirmButton: 'Yes, confirm',
                cancelButton: 'Cancel',
            },
        },
        buttons: {
            bookCall: 'Book a call, from',
            back: 'Back to the messenger',
            payment: 'Credit & Debit Cards',
        },
    },
    creator: {
        fileAdd: 'Add an image',
        buttons: {
            sendToAll: 'Message to All',
        },
        search: {
            placeholder: 'Search by subscriber name',
        },
        form: {
            title: 'Send a message to your subscribers',
        },
        video: {
            title: 'Video call',
            selectSource: 'Select a source',
            audioSource: 'Audio source',
            videoSource: 'Video source',
            waiting: 'Please wait, the meeting host will let you in soon',
            errors: {
                camera: 'You need a camera to join a call',
                microphone: 'You need a microphone to join a call',
                speaker: 'You need a speaker to join a call',
            },
        },
    },
    guest: {
        unlockChat: 'Unlock live chat',
    },
    form: {
        list: {},
        add: {
            fields: {
                message: 'Message',
            },
            buttons: {
                submit: 'Send',
            },
        },
        edit: {},
    },
}

export default messenger