const earlyAccess = {
    title: `BECOME AN {lb} IKONSTAR PARTNER`,
    subtitle: `Create a unique relationship with your community`,
    
    superAdmin: {
        title: {
            list: 'Early Accesses',
        },
        form: {
            list: {
                searchUsername: 'Search by username',
                searchEmail: 'Search by email',
                searchVipCode: 'Search by VIP code',
                columnUsername: 'Username',
                columnEmail: 'Email',
                columnPhone: 'Phone',
                columnVipCode: 'VIP Code',
                columnRole: 'Role requested',
                columnCreationDate: 'Created',
                createUser: 'Create user',
                createCreator: 'Create creator',
                confirmCreateUserText: 'Are you sure you want to create user from this user early access?',
                confirmCreateUserYesBtn: 'Yes, create user',
                confirmCreateUserNoBtn: 'No, cancel',
                createUserError: 'Error while creating user',
                createUserSuccess: 'User created successfully',
                createCreatorSuccess: 'Creator created successfully',
                createCreatorError: 'Error while creating creator',
                confirmCreateCreatorText: 'Are you sure you want to create creator from this user early access?',
                confirmCreateCreatorYesBtn: 'Yes, create creator',
                confirmCreateCreatorNoBtn: 'No, cancel',
            },
        }
    },
    
    form: {
        types: {
            manager: `I'm a Manager`,
            agency: `I'm an Agency`,
            creator: `I'm a Creator`,
        },
        username: `Username`,
        link: `Link`,
        usernameHelper: `Your main Social Network Username`,
        social: `Social network`,
        addSocial: `Add a Social network`,
        fullname: `Your Full Name`,
        email: `Your Email`,
        phone: `Your Phone Number`,
        vipCode: `VIP Code`,
        vipCodeHelper: `Were you given a special VIP Code?`,
    },
}

export default earlyAccess