export default {
    addTag: 'Add tag',
    modal: {
        title: 'Add new tag',
        fields: {
            tag: {
                label: 'Tag',
                name: 'tag',
            },
        },
        submit: 'Add',
    },
    autocomplete: {
        type: 'Start typing to search',
    },
}
